<div [class.active]="wasMovement">
  <ng-container *ngIf="!isParentDragging && desktopView; else notDraggableList">
    <div
      kendoDropTarget
      class="drop-target"
      [class.cursor-not-allowed]="item.disabled || disabled"
      [title]="disabled ? disabledItemTooltip : ''"
      (onDrop)="handleDrop($event, index)"
      (onDragLeave)="handleDragLeave($event)"
      (onDragEnter)="handleDragEnter($event, index)"
      *ngFor="let item of items; let index = index; trackBy: trackByFn"
    >
      <div
        kendoDragTarget
        [restrictByAxis]="'vertical'"
        class="drag-target"
        [class.pe-none]="disabled"
        (onDragStart)="handleDragStart($event, index)"
        (onDragEnd)="handleDragEnd($event)"
        (onPress)="handlePress($event)"
        (onDrag)="onDrag($event)"
      >
        <f-draggable-list-item
          [hoverable]="hoverable"
          [actionBtnIcon]="actionBtnIcon"
          [rootClass]="listItemRootClass + ' ps-6'"
          [draggableIconClass]="draggableIconClass"
          [disabled]="disabled"
          [actionBtnClass]="item.btnClass"
          [disabledIcon]="item.disabled"
          [disabledIconTitle]="item.iconTitle"
        >
          <ng-template *ngTemplateOutlet="listItemTemplate; context: { $implicit: item, index: index }"></ng-template>
        </f-draggable-list-item>
      </div>
    </div>
  </ng-container>
  <ng-template #notDraggableList>
    <div *ngFor="let item of items; let index = index; trackBy: trackByFn" class="drop-target">
      <f-draggable-list-item
        [hoverable]="hoverable"
        [actionBtnIcon]="actionBtnIcon"
        [rootClass]="listItemRootClass + ' ps-6'"
        [draggableIconClass]="draggableIconClass"
        [actionBtnClass]="item.btnClass"
      >
        <ng-template *ngTemplateOutlet="listItemTemplate; context: { $implicit: item, index: index }"></ng-template>
      </f-draggable-list-item>
    </div>
  </ng-template>
</div>
