<svg:g>
  <ng-container *ngFor="let bar of _bars; let i = index; trackBy: trackBy">
    <ng-template #popoverTemplateOutlet>
      <ng-container
        *ngTemplateOutlet="$any(popoverTemplate); context: { $implicit: popoverContextData(i) }"
      ></ng-container>
    </ng-template>
    <svg:g
      #container
      class="f-bar-group"
      [class.selected]="selectedBarIndex === i"
      [class.interactive]="interactions.hover"
      [attr.data-automation-id]="'bar-container-' + i"
      (mouseenter)="onMouseEnter(popover, bar.x, i)"
      (mouseleave)="onMouseLeave(popover, bar.x)"
      (click)="onClick(container, bar.x, i)"
    >
      <rect
        class="f-bar-halo"
        *ngIf="interactions.hover"
        [attr.height]="dimensions.height"
        [attr.width]="bar.halo?.width"
        [attr.x]="bar.halo?.x"
        [attr.y]="0"
      ></rect>
      <defs>
        <clipPath id="clip-path-{{ i }}-{{ hash }}" class="f-bar-clipping">
          <rect
            [attr.x]="bar.x - strokeWidth"
            [attr.y]="animationState === 'initial' ? '100%' : dimensions.height - bar.stack.height - strokeWidth"
            [attr.height]="bar.stack.height + strokeWidth * 2"
            [attr.width]="bar.width + strokeWidth * 2"
            [ngStyle]="animationStyles"
          ></rect>
        </clipPath>
      </defs>
      <svg:g
        #popover="ngbPopover"
        [ngbPopover]="popoverTemplate && popoverTemplateOutlet"
        [popoverClass]="popoverClass"
        [placement]="popoverPlacement"
        [triggers]="'manual'"
        [container]="'body'"
      >
        <ng-container *ngFor="let data of $any(bar.data); let isFirst = first">
          <rect
            class="f-rect-bar"
            [attr.y]="data.y"
            [attr.height]="data.height || (!bar.stack.height && isFirst && minBarHeight) || 0"
            [attr.x]="selectedBarIndex === i ? bar.x + strokeWidth / 2 : bar.x"
            [attr.width]="selectedBarIndex === i ? bar.width - strokeWidth : bar.width"
            [attr.clip-path]="'url(#clip-path-' + i + '-' + hash + ')'"
            [ngClass]="data.barClass"
          ></rect>
        </ng-container>
        <ng-container *ngIf="showOptionalData && !isMobile">
          <ng-container *ngTemplateOutlet="bubbleTemplate; context: { index: i }"></ng-container>
        </ng-container>
      </svg:g>
      <rect
        [attr.y]="dimensions.height - bar.stack.height"
        [attr.x]="selectedBarIndex === i ? bar.x + strokeWidth / 2 : bar.x"
        [attr.width]="selectedBarIndex === i ? bar.width - strokeWidth : bar.width"
        [attr.height]="bar.stack.height"
        class="f-stacked-bar-wrapper"
      ></rect>
    </svg:g>
  </ng-container>
  <ng-container *ngIf="showOptionalData && isMobile">
    <ng-container *ngFor="let bar of _bars; let i = index; trackBy: trackBy">
      <svg:g #bubble (click)="onBubbleClick(bar.x, i)">
        <ng-container *ngTemplateOutlet="bubbleTemplate; context: { index: i }"></ng-container>
      </svg:g>
    </ng-container>
  </ng-container>
</svg:g>

<ng-template #bubbleTemplate let-i="index">
  <svg:g
    f-bubble
    [cx]="optionalData[i].cx"
    [cy]="optionalData[i].cy"
    [bubbleRadius]="bubbleRadius"
    [value]="optionalData[i].value"
    [bubbleShowZero]="barBubbleShowZero"
    [textClasses]="barBubbleTextClasses"
    [bubbleClasses]="optionalData[i].cssClass"
    [animationDuration]="bubblesAnimationDuration"
    [animationDelay]="animationDuration + 50"
    [animation]="animation"
  ></svg:g>
</ng-template>
