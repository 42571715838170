export enum ReportViewModelSection {
  Common_Footer = 'Common_Footer',
  Commentary = 'Commentary',

  InvestorProfile_InstitutionBaseInfo = 'InvestorProfile_InstitutionBaseInfo',
  InvestorProfile_ContactBaseInfo = 'InvestorProfile_ContactBaseInfo',
  InvestorProfile_ContactBiography = 'InvestorProfile_ContactBiography',
  InvestorProfile_InstitutionInvestorDetails = 'InvestorProfile_InstitutionInvestorDetails',
  InvestorProfile_PrimaryInstitutionDetails = 'InvestorProfile_PrimaryInstitutionDetails',
  InvestorProfile_InstitutionInvestmentApproach = 'InvestorProfile_InstitutionInvestmentApproach',
  InvestorProfile_PrimaryInstitutionInvestmentApproach = 'InvestorProfile_PrimaryInstitutionInvestmentApproach',
  InvestorProfile_PrimaryContactBiography = 'InvestorProfile_PrimaryContactBiography',
  InvestorProfile_FinancialHighlights = 'InvestorProfile_FinancialHighlights',
  InvestorProfile_EquityPortfolio = 'InvestorProfile_EquityPortfolio',
  InvestorProfile_HistoricalOwnership = 'InvestorProfile_HistoricalOwnership',
  InvestorProfile_ActivityHistory = 'InvestorProfile_ActivityHistory',
  InvestorProfile_SustainabilityFocus = 'InvestorProfile_SustainabilityFocus',
  InvestorProfile_InvestorTargetingInfo = 'InvestorProfile_InvestorTargetingInfo',
  InvestorProfile_CombinedOwnership = 'InvestorProfile_CombinedOwnership',
  InvestorProfile_PeerOwnership = 'InvestorProfile_PeerOwnership',
  InvestorProfile_InvestorSuitabilityFactors = 'InvestorProfile_InvestorSuitabilityFactors',
  InvestorProfile_CustomFields = 'InvestorProfile_CustomFields',
  InvestorProfile_FundSummary = 'InvestorProfile_FundSummary',
  InvestorProfile_PrimaryInstitutionHistoricalOwnership = 'InvestorProfile_PrimaryInstitutionHistoricalOwnership',
  InvestorProfile_SustainabilityFitMetric = 'InvestorProfile_SustainabilityFitMetric',
  InvestorProfile_ParticipantBiography = 'InvestorProfile_ParticipantBiography',

  CapitalInsight_SecurityBaseInfo = 'CapitalInsight_SecurityBaseInfo',
  CapitalInsight_TradingAnalysisAll = 'CapitalInsight_TradingAnalysisAll',
  CapitalInsight_TradingAnalysisBuys = 'CapitalInsight_TradingAnalysisBuys',
  CapitalInsight_TradingAnalysisSells = 'CapitalInsight_TradingAnalysisSells',
  CapitalInsight_PredictedMovements = 'CapitalInsight_PredictedMovements',

  Itinerary_EventBaseInfo = 'Itinerary_EventBaseInfo',
  Itinerary_EventDetails = 'Itinerary_EventDetails',

  HistoricalOwnership_SecurityBaseInfo = 'HistoricalOwnership_SecurityBaseInfo',
  HistoricalOwnership_AppliedFilters = 'HistoricalOwnership_AppliedFilters',
  HistoricalOwnership_StyleName = 'HistoricalOwnership_StyleName',
  HistoricalOwnership_HolderRegionName = 'HistoricalOwnership_HolderRegionName',
  HistoricalOwnership_HolderInvestmentCenterName = 'HistoricalOwnership_HolderInvestmentCenterName',
  HistoricalOwnership_HolderCountryTerritoryName = 'HistoricalOwnership_HolderCountryTerritoryName',
  HistoricalOwnership_Orientation = 'HistoricalOwnership_Orientation',
  HistoricalOwnership_Turnover = 'HistoricalOwnership_Turnover',

  OnePagerProfile_InstitutionBaseInfo = 'OnePagerProfile_InstitutionBaseInfo',
  OnePagerProfile_ContactBaseInfo = 'OnePagerProfile_ContactBaseInfo',
  OnePagerProfile_InstitutionInvestorDetails = 'OnePagerProfile_InstitutionInvestorDetails',
  OnePagerProfile_PrimaryInstitutionDetails = 'OnePagerProfile_PrimaryInstitutionDetails'
}
