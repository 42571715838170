import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SecurityEffects } from './+state/effects';
import { securitiesReducer } from './+state/reducer';
import { COMMON_SECURITY_FEATURE_KEY } from './+state/state';
import { SecurityNamePipe } from './pipes/security-name.pipe';
import { TickerSymbolPipe } from './pipes/ticker-symbol.pipe';
import { SecuritiesRepository } from './services/securities.repository';
import { SecurityPricingRepository } from './services/security-pricing-repository.service';
import { SecuritySelectorProvider } from './services/security-selector.provider';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(COMMON_SECURITY_FEATURE_KEY, securitiesReducer),
    EffectsModule.forFeature([SecurityEffects])
  ]
})
export class CommonSecurityRootModule {}

@NgModule({
  imports: [CommonModule, TickerSymbolPipe],
  declarations: [SecurityNamePipe],
  providers: [SecuritiesRepository, SecurityPricingRepository, SecuritySelectorProvider],
  exports: [SecurityNamePipe]
})
export class CommonSecurityModule {
  static forRoot(): ModuleWithProviders<CommonSecurityRootModule> {
    return {
      ngModule: CommonSecurityRootModule,
      providers: [SecuritiesRepository, SecurityPricingRepository, SecuritySelectorProvider]
    };
  }
}
