import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {
  HashMap,
  OrArray,
  TRANSLOCO_LANG,
  TRANSLOCO_SCOPE,
  TranslocoPipe,
  TranslocoScope,
  TranslocoService
} from '@jsverse/transloco';
import { LocalizationScopeDirective } from '../directive/localization-scope.directive';
import { LocalizedString } from '../models/localized-string.model';

/**
 * Can take the localization scope from LocalizationScopeDirective hosted on the parent element within one view.
 * Will ignore localization scope if key already contains scope.
 */
@Pipe({
  name: 'caLocalize',
  pure: false,
  standalone: true
})
export class LocalizePipe implements PipeTransform, OnDestroy {
  readonly #scope = inject(LocalizationScopeDirective, { optional: true, host: true });
  readonly #translocoPipe = new TranslocoPipe(
    inject(TranslocoService),
    inject<OrArray<TranslocoScope>>(TRANSLOCO_SCOPE, { optional: true }) || undefined,
    inject<string>(TRANSLOCO_LANG, { optional: true }) || undefined,
    inject(ChangeDetectorRef)
  );

  transform(key: LocalizedString | string | null, params?: HashMap | undefined): string {
    if (key instanceof LocalizedString) {
      return this.#translocoPipe.transform(key.fullKey, key.params);
    }
    return this.#translocoPipe.transform(this.resolveKey(key), params);
  }

  ngOnDestroy() {
    this.#translocoPipe.ngOnDestroy();
  }

  private resolveKey(key: string | null) {
    return !this.withScope(key) && this.#scope && this.#scope.caLocalizationScope
      ? `${this.#scope.caLocalizationScope}.${key}`
      : key;
  }

  private withScope(key: string | null): boolean {
    if (!key) {
      return false;
    }

    // Scope and localization key are separated by '.', i.e., 'user-settings.accountInfo'
    const { length } = key.split('.');

    // if length is more that 1, it means scope is present in the key.
    return length > 1;
  }
}
