import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { of } from 'rxjs';
import { ChipType } from '../../../chips/models/chip-type';
import { FireflyModalService } from '../../../modal';
import { Breakpoint } from '../../../utils';
import { NestedValuePipe } from '../../../utils/formatting/pipes/nested-value.pipe';
import { FireflyLocalizationService } from '../../../utils/localization/firefly-localization.service';
import { ComplexListCheckedData, ComplexListData } from '../../models/complex-list-data.model';
import { GroupedMultipleData } from '../../models/grouped-data.model';
import { GroupedMultipleItem } from '../../models/grouped-item.model';
import { SuggesterType } from '../../models/suggester-type.enum';
import { SuggesterManager } from '../../suggester-manager/suggester-manager.class';
import * as buttonsHandler from '../../utils/action-buttons/action-buttons.utils';
import { isDisabledItem } from '../../utils/list-items/list-items.utils';
import { FireflyBaseSuggesterComponent } from '../base-suggester/base-suggester.component';

@Component({
  selector: 'f-multiple-suggester',
  templateUrl: './multiple-suggester.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMultipleSuggesterComponent extends FireflyBaseSuggesterComponent implements OnDestroy, OnChanges {
  @Input() set selectedItems(value: ComplexListData[]) {
    this.selectedChips = value;
  }
  @Input() actionsForAllList: boolean = true;
  @Input() actionsForCategories: boolean = true;
  @Input() idField: string = 'id';
  @Input() linkPath: string = 'link';
  @Input() chipCssClass: string = '';
  @Input() itemCssClass: string = '';
  @Input() popoverContainer: string = '';
  @Input() popoverPlacement: string = 'bottom-left top-left';
  @Input() popoverAutoClose: boolean | string = 'outside';
  @Input() maxSelectedItemsCount!: number | null;
  @Input() mobileTriggerOutclickOnAccept = false;
  @Input() invalidItems = [];

  @Output() remove = new EventEmitter<ComplexListData>();
  @Output() selectMany = new EventEmitter<ComplexListData[]>();
  @Output() removeMany = new EventEmitter<ComplexListData[]>();
  @Output() itemClick = new EventEmitter<ComplexListData>();
  @Output() removeChip = new EventEmitter<ComplexListData>();
  @Output() removeAllChips = new EventEmitter<ComplexListData[]>();

  @ViewChild('anchor', { read: ElementRef }) dropdownAnchor!: ElementRef;
  @ViewChild('inputElement', { read: ElementRef }) inputElement!: ElementRef;

  chipType = ChipType;
  listOfCheckedItems: ComplexListCheckedData[] = [];
  private readonly focusedInputClass: string = 'input-focus';
  private selectionChanged = false;

  get listData(): GroupedMultipleData {
    return this.suggesterManager.listData as GroupedMultipleData;
  }

  get requiredAndInvalid() {
    return super.requiredAndInvalid && !this.selectedChips.length;
  }

  get selectedItemsCount$() {
    const count = this.selectedChips.length;
    return this.localizationService
      ? this.localizationService.localize('suggesterSelectedItemsCount', { count: this.selectedChips.length })
      : of(count === 1 ? `${count} item selected` : `${count} items selected`);
  }

  get maxSelectedItemsCount$() {
    const count = this.maxSelectedItemsCount;
    return this.localizationService
      ? this.localizationService.localize('suggesterMaxSelectedItems', { count: this.maxSelectedItemsCount })
      : of(`Maximum ${count} items can be selected`);
  }

  get selectedAndMaxSelectedItemsCount$() {
    const selectedItemsCount = this.selectedChips.length;
    return this.localizationService
      ? this.localizationService.localize(
          this.selectedChips.length === 1 ? 'suggesterItemsCountSingular' : 'suggesterItemsCountPlural',
          {
            selectedItemsCount: this.selectedChips.length,
            maxSelectedItemsCount: this.maxSelectedItemsCount
          }
        )
      : of(
          selectedItemsCount === 1
            ? `${selectedItemsCount} item selected (maximum ${this.maxSelectedItemsCount} items to select)`
            : `${selectedItemsCount} items selected (maximum ${this.maxSelectedItemsCount} items to select)`
        );
  }

  constructor(
    public host: ElementRef,
    private renderer: Renderer2,
    public nestedValuePipe: NestedValuePipe,
    public changeDetectorRef: ChangeDetectorRef,
    private modalService: FireflyModalService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {
    super(host, localizationService);
    this.suggesterManager = new SuggesterManager(this, SuggesterType.Multiple);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.lazyLoadPredefinedSet &&
      this.showPredefinedSet &&
      changes['items']?.firstChange &&
      !changes['items'].currentValue?.length
    ) {
      this.predefinedSetIsLoading = true;
    } else if (changes['items']?.currentValue || changes['selectedItems']?.currentValue) {
      this.setSelected();
      this.suggesterManager.groupData(this.listOfCheckedItems, this.categoryPath);
      if (this.lazyLoadPredefinedSet && this.showPredefinedSet) {
        this.predefinedSetIsLoading = false;
        if (this.suggesterIsInFocus) this.suggesterManager.toggleDropdown(true);
      }
    }
    this.suggesterManager.onPropertiesChanges(changes);
  }

  onRemoveFromChips($event: unknown) {
    const removedChip = $event as ComplexListData;
    this.onRemove(removedChip);
    this.removeChip.emit(removedChip as ComplexListData);
    const groupOfItemsName = this.categoryPath ? this.nestedValuePipe.transform(removedChip, this.categoryPath) : null;
    const foundInListGroup = groupOfItemsName
      ? this.listData.groupedItems.find(group => group.name === groupOfItemsName)
      : this.listData.groupedItems[0];
    if (foundInListGroup) {
      const removedItemFromCurrentList = foundInListGroup.value.find(
        groupedItem => groupedItem[this.idField] === removedChip[this.idField]
      );
      if (removedItemFromCurrentList) {
        removedItemFromCurrentList.isSelected = false;
        this.updateActionButtonsAfterSingleChange(foundInListGroup, false);
      } else {
        if (!this.customFilterMode) {
          this.deselectItemFromEntireList(removedChip);
        }
      }
    }
    if (!this.selectedChips.length) {
      this.suggesterManager.applyDefaultValidation();
    }
    this.updateItemsDisabledState(false);
  }

  getResultsBoxMinHeight(resultsAmount: number) {
    const actionButtonsHeight = this.actionButtons?.nativeElement.getBoundingClientRect().height ?? 0;
    return super.getResultsBoxMinHeight(resultsAmount) + actionButtonsHeight;
  }

  private deselectItemFromEntireList(removedChip: ComplexListData) {
    (
      this.listOfCheckedItems.find(item => item[this.idField] === removedChip[this.idField]) as ComplexListCheckedData
    ).isSelected = false;
  }

  private deselectItemsFromEntireList() {
    this.listOfCheckedItems.forEach(item => (item.isSelected = false));
    this.listData.groupedItems.forEach(group => group.value.forEach(item => (item.isSelected = false)));
  }

  onCheckboxChange(item: ComplexListCheckedData, group: GroupedMultipleItem) {
    if (!isDisabledItem(item, this.disabledPath, this.nestedValuePipe.transform)) {
      item.isSelected = !item.isSelected;
      const itemToProcess = this.getInitialItemStructure(item);
      item.isSelected ? this.onSelect(itemToProcess) : this.onRemove(itemToProcess);
      this.updateActionButtonsAfterSingleChange(group, item.isSelected);
      this.selectionChanged = true;
    }

    if ((this.maxSelectedItemsCount as number) > 0) {
      const selectionLimitReached = this.selectedChips.length === this.maxSelectedItemsCount;
      this.updateItemsDisabledState(selectionLimitReached);
    }
  }

  onEnter($event: Event, item: ComplexListCheckedData, group: GroupedMultipleItem) {
    $event.preventDefault();
    this.onCheckboxChange(item, group);
  }

  openMobileModal() {
    if (window.innerWidth >= Breakpoint.Sm || this.modal) return;

    this.modal = this.modalService.open({
      component: this.modalComponent,
      modalDialogClass: this.mobileSuggesterFromDrawer
        ? `${this.modalDialogClass} modal-from-drawer`
        : this.modalDialogClass,
      title: this.placeholder,
      context: this,
      mobile: true
    });

    const initialChips = [...this.selectedChips];
    const initialListData = JSON.stringify(this.suggesterManager.initialGroupedData);

    this.modal.result
      .then(
        () => this.handleStateUpdate(initialChips),
        () => this.resetState(initialChips, initialListData)
      )
      .finally(() => {
        setTimeout(() => {
          this.inputElement.nativeElement.removeAttribute('readonly');
          this.inputElement.nativeElement.blur();
        });
        this.suggesterManager.toggleDropdown(false);
        this.inputElement.nativeElement.setAttribute('readonly', true);
        this.modal = null;
      });
  }

  private updateActionButtonsAfterSingleChange(group: GroupedMultipleItem, itemIsSelected: boolean) {
    if (this.actionsForAllList) {
      buttonsHandler.updateClearAllButtonForList(this.listData, itemIsSelected ? true : !this.isAllListInState(false));
      buttonsHandler.updateSelectAllButtonForList(this.listData, itemIsSelected ? !this.isAllListInState(true) : true);
    } else {
      buttonsHandler.updateClearAllButtonForGroup(group, itemIsSelected ? true : !this.isGroupInState(group, false));
      buttonsHandler.updateSelectAllButtonForGroup(group, itemIsSelected ? !this.isGroupInState(group, true) : true);
    }
  }

  private isAllListInState(isSelected: boolean): boolean {
    return this.listData.groupedItems.every(group => this.isGroupInState(group, isSelected));
  }

  private isGroupInState(group: GroupedMultipleItem, newValueForIsSelectedField: boolean): boolean {
    return group.value.every(
      item =>
        item.isSelected === newValueForIsSelectedField ||
        isDisabledItem(item, this.disabledPath, this.nestedValuePipe.transform)
    );
  }

  onItemClick(item: unknown) {
    this.itemClick.emit(item as ComplexListData);
  }

  onSelect(newItem: ComplexListData) {
    this.selectedChips = [...this.selectedChips, newItem];
    if (!this.modal) this.select.emit(newItem);
  }

  onRemove(removedItem: ComplexListData) {
    this.selectedChips = this.selectedChips.filter(item => item[this.idField] !== removedItem[this.idField]);
    if (!this.modal) this.remove.emit(removedItem);
  }

  private updateItemsDisabledState(disabled: boolean) {
    this.listData.groupedItems.forEach(group =>
      group.value.forEach(item => {
        if (!item.isSelected) {
          item.isDisabled = disabled;
        }
      })
    );
  }

  private updateChipsForMultipleItems(selectAll: boolean, chips: ComplexListCheckedData[]) {
    const initialChips = chips.map(item => this.getInitialItemStructure(item));
    if (selectAll) {
      this.selectedChips = [...this.selectedChips, ...initialChips];
      if (!this.modal) this.selectMany.emit(initialChips);
    } else {
      this.selectedChips = this.selectedChips.filter(
        chip => !chips.find(item => chip[this.idField] === item[this.idField])
      );
      if (!this.modal) this.removeMany.emit(initialChips);
    }
  }

  private handleStateUpdate(initialChips: ComplexListData[]) {
    if (!initialChips.length && this.selectedChips.length) {
      this.selectMany.emit(this.selectedChips);
      if (this.mobileTriggerOutclickOnAccept) this.outclick.emit();
    } else if (initialChips.length && !this.selectedChips.length) {
      this.removeMany.emit(initialChips);
    } else {
      const removedDiff = differenceWith(initialChips, this.selectedChips, isEqual);
      const addedDiff = differenceWith(this.selectedChips, initialChips, isEqual);
      if (removedDiff.length) this.removeMany.emit(removedDiff);
      if (addedDiff.length) {
        // Required fix for mobile issues (CPD-1353)
        window.requestAnimationFrame(() => this.selectMany.emit(addedDiff));
      }
    }
  }

  protected resetState(chips: ComplexListData[], listData: string) {
    const data = JSON.parse(listData);

    if (data.totalLength) {
      this.suggesterManager.listData.groupedItems = data.groupedItems;
      this.suggesterManager.initialGroupedData = data;
    } else {
      this.deselectItemsFromEntireList();
    }

    buttonsHandler.updateActionButtonsOnListChanges(
      this.actionsForAllList,
      this.listData,
      this.disabledPath,
      this.nestedValuePipe.transform
    );
    this.selectedChips = chips;
  }

  onRemoveAll() {
    this.removeMany.emit(this.selectedChips);
    this.removeAllChips.emit(this.selectedChips);

    this.deselectItemsFromEntireList();
    this.selectedChips = [];

    this.updateActionButtonsOnRemoveAll();
    this.suggesterManager.applyDefaultValidation();

    this.updateItemsDisabledState(false);
  }

  updateListOnActionButtonClick(isSelected: boolean, forAllList: boolean, group?: GroupedMultipleItem) {
    if (forAllList) {
      this.listData.groupedItems.forEach(group => this.updateItemsSelection(group.value, isSelected));
      buttonsHandler.updateClearAllButtonForList(this.listData, isSelected);
      buttonsHandler.updateSelectAllButtonForList(this.listData, !isSelected);
    } else {
      this.updateItemsSelection((group as GroupedMultipleItem).value, isSelected);
      buttonsHandler.updateClearAllButtonForGroup(group as GroupedMultipleItem, isSelected);
      buttonsHandler.updateSelectAllButtonForGroup(group as GroupedMultipleItem, !isSelected);
    }
    this.selectionChanged = true;
  }

  updateActionButtonsOnRemoveAll() {
    const isSelected = false;
    if (this.actionsForAllList) {
      buttonsHandler.updateClearAllButtonForList(this.listData, isSelected);
      buttonsHandler.updateSelectAllButtonForList(this.listData, !isSelected);
    } else {
      this.listData.groupedItems.forEach(group => {
        buttonsHandler.updateClearAllButtonForGroup(group as GroupedMultipleItem, isSelected);
        buttonsHandler.updateSelectAllButtonForGroup(group as GroupedMultipleItem, !isSelected);
      });
    }
  }

  onOpenChange(isOpen: boolean) {
    this.clearQuery();
    this.suggesterManager.onOpenChange(isOpen);
  }

  onFocus() {
    this.clearQuery();
    this.renderer.addClass(this.dropdownAnchor.nativeElement, this.focusedInputClass);
    super.onFocus();
  }

  onFocusOut() {
    this.renderer.removeClass(this.dropdownAnchor.nativeElement, this.focusedInputClass);
  }

  override resetValue($event?: Event) {
    super.resetValue($event);
    this.selectionChanged = false;
  }

  private updateItemsSelection(itemsToCheck: ComplexListCheckedData[], newValueForIsSelectedField: boolean) {
    const chips: ComplexListCheckedData[] = [];
    itemsToCheck.forEach(item => {
      if (isDisabledItem(item, this.disabledPath, this.nestedValuePipe.transform)) {
        return;
      }
      if ((newValueForIsSelectedField && !item.isSelected) || (!newValueForIsSelectedField && item.isSelected)) {
        chips.push(item);
      }
      item.isSelected = newValueForIsSelectedField;
    });
    this.updateChipsForMultipleItems(newValueForIsSelectedField, chips);
  }

  private getInitialItemStructure(item: ComplexListCheckedData): ComplexListData {
    return omit(item, 'isSelected');
  }

  private setSelected() {
    const selectedItemsMap: Record<string, unknown> = {};
    this.selectedChips.forEach(item => {
      selectedItemsMap[item[this.idField] as string] = item;
    });
    this.listOfCheckedItems = (this.items as ComplexListData[]).map(item => ({
      ...item,
      isSelected: !!selectedItemsMap[item[this.idField] as string]
    }));
  }

  private clearQuery() {
    if (this.inputValue.dirty && this.inputValue.value?.trim().length && this.selectionChanged) {
      this.inputValue.patchValue('', { emitEvent: true });
      this.selectionChanged = false;
    }
  }

  trackByListFn(_: number, item: ComplexListCheckedData) {
    return item[this.idField];
  }

  trackByGroupFn(_: number, item: GroupedMultipleItem) {
    return item.name ?? item;
  }
}
