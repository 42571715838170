import { DestroyRef, Directive, ElementRef, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { Breakpoint, isIpad } from '../utils';

const SCROLL_DELAY_MS = 300;
const SCROLL_THRESHOLD = 5;

@Directive({
  selector: '[fAutoScroll]',
  standalone: true
})
export class FireflyAutoScrollDirective implements OnInit, OnDestroy {
  private el = inject(ElementRef);
  private store = inject(Store);
  private destroyRef = inject(DestroyRef);
  private isAutoscrollEnabled: boolean = false;
  private outerTimeout!: ReturnType<typeof setTimeout>;
  private innerTimeout!: ReturnType<typeof setTimeout>;

  ngOnInit() {
    if (this.checkIfIpad) {
      this.store
        .select(getFeatureToggle('autoscroll-ipads-ISD-6083'))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(enabled => {
          this.isAutoscrollEnabled = enabled;
        });
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    this.handleEvent(event);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.handleEvent(event);
  }

  get checkIfIpad(): boolean {
    return isIpad() && window.innerWidth >= Breakpoint.Sm && window.innerWidth <= Breakpoint.Xxl;
  }

  private isFieldValid(target: HTMLElement): boolean {
    return target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.getAttribute('role') === 'textbox';
  }

  private handleEvent(event: Event): void {
    if (this.isAutoscrollEnabled && this.isFieldValid(event.target as HTMLElement)) {
      this.scrollToTop(event.target as HTMLElement);
    }
  }

  private scrollToTop(target: HTMLElement): void {
    if (this.outerTimeout) {
      clearTimeout(this.outerTimeout);
    }
    if (this.innerTimeout) {
      clearTimeout(this.innerTimeout);
    }

    this.outerTimeout = setTimeout(() => {
      const parentDiv = this.el.nativeElement;
      const parentTop = parentDiv.getBoundingClientRect().top;
      const elementTop = target.getBoundingClientRect().top;
      parentDiv.scrollTo({ top: elementTop - Math.max(0, parentTop) + parentDiv.scrollTop, behavior: 'smooth' });
      this.innerTimeout = setTimeout(() => {
        const hasScrollReachedEnd =
          parentDiv.scrollTop + parentDiv.clientHeight >= parentDiv.scrollHeight - SCROLL_THRESHOLD;

        if (hasScrollReachedEnd) {
          window.scrollTo({ top: target.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' });
        }
      }, SCROLL_DELAY_MS);
    }, SCROLL_DELAY_MS);
  }

  ngOnDestroy(): void {
    if (this.outerTimeout) {
      clearTimeout(this.outerTimeout);
    }
    if (this.innerTimeout) {
      clearTimeout(this.innerTimeout);
    }
  }
}
