import { ReportType } from './models/report-type.enum';

export const LOCALIZATION_KEY = 'reporting-common';
export const REPORTING_API_BASE_URL = '/api/reporting/';
export const POLLING_INTERVAL = 1000;
export const MAX_SMALL_REPORT_ITEMS_COUNT = 30;
export const MAX_REPORT_PROFILES_COUNT = 50;
export const MAX_REPORT_PROFILES_COUNT_WITH_FT = 100;
export const DEFAULT_TRUNCATION_LENGTH = 5;
export const INCREASE_EXPORT_LIMIT_FT = 'increase-export-report-limit-to-100-isd-5807';
export const US_PAGE_SIZE_PX_HEIGHT = 1056;
export const A4_PAGE_SIZE_PX_HEIGHT = 1124;
export const US_PAGE_SIZE_PX_WIDTH = 852;
export const A4_PAGE_SIZE_PX_WIDTH = 830;
export const DEFAULT_PAGE_BREAK_SIZE = 8;
export const EXPORT_SELECTED_ITEMS_LOCAL_STORAGE_KEY = 'exportSelectedItems';

export const PAGE_SIZES = [
  {
    value: 'us-letter',
    label: 'usLetter',
    format: 'letter',
    width: US_PAGE_SIZE_PX_WIDTH,
    height: US_PAGE_SIZE_PX_HEIGHT
  },
  {
    value: 'a4',
    label: 'a4size',
    format: 'a4',
    width: A4_PAGE_SIZE_PX_WIDTH,
    height: A4_PAGE_SIZE_PX_HEIGHT
  }
] as const;
export const SAMPLE_DATA_PROFILE_ID = '-1|';

export const REPORT_TITLES: Partial<Record<ReportType, string>> = {
  [ReportType.Contact]: 'contactProfileReport',
  [ReportType.Institution]: 'investorProfileReport',
  [ReportType.HistoricalOwnership]: 'historicalOwnershipReport',
  [ReportType.Itinerary]: 'itineraryReport'
};
