import { BaseReportRequest } from '../../core/models/report-request.models';
import { ReportSection } from '../../core/models/report-sections';

export interface CreateItineraryReportRequest extends BaseReportRequest {
  eventId: string;
  sections?: ReportSection[];
}

export class CreateItineraryReportDto {
  eventId!: string;
  eventName!: string;

  static create(args: { eventId: string; eventName: string }): CreateItineraryReportDto {
    return { eventId: args.eventId, eventName: args.eventName };
  }
}
