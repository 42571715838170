import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { DrawerOutput, DrawerPosition, DrawerType, FireflyDrawerService } from '@capital-access/firefly/components';
import {
  createHistoricalOwnershipReportFinished,
  openHistoricalOwnershipReportDrawer
} from '@capital-access/reporting/common';
import { LOCALIZATION_KEY } from '../../constants';
import { getSavedSections } from '../../core/utils/section-settings.utils';
import { HistoricalOwnershipReportDrawerComponent } from '../components/historical-ownership-report-drawer/historical-ownership-report-drawer.component';
import { REPORT_FILTERS_FT, SETTINGS_KEY } from '../constants';
import { CreateHistoricalOwnershipDto } from '../models/historical-ownership-report.models';
import {
  DEFAULT_HISTORICAL_OWNERSHIP_SECTIONS,
  getConditionalReportSections
} from '../models/historical-ownership-report-sections';

@Injectable()
export class HistoricalOwnershipDrawerEffects {
  openDrawer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openHistoricalOwnershipReportDrawer),
      withLatestFrom(
        this.localization.getLocalization(LOCALIZATION_KEY),
        this.store.select(getFeatureToggle(REPORT_FILTERS_FT))
      ),
      switchMap(([{ reportedQuarters, filters, sortDirection }, localization, isIncludeFiltersAndTablesEnabled]) => {
        const conditionalSections = getConditionalReportSections(isIncludeFiltersAndTablesEnabled);
        const drawer = this.drawerService.openDrawer(
          {
            title: localization[`historicalOwnershipDrawerHeader`],
            position: DrawerPosition.Right,
            type: DrawerType.Form,
            dataLayoutId: 'modify-historical-ownership-report-drawer'
          },
          HistoricalOwnershipReportDrawerComponent,
          {
            request: CreateHistoricalOwnershipDto.create(
              reportedQuarters,
              isIncludeFiltersAndTablesEnabled ? filters : undefined,
              sortDirection
            ),
            filteredSections: getSavedSections(SETTINGS_KEY, DEFAULT_HISTORICAL_OWNERSHIP_SECTIONS, conditionalSections)
          }
        );

        return drawer.onClose().pipe(
          take(1),
          filter((output: DrawerOutput) => !output || !output['reportInProgress']),
          map(() => createHistoricalOwnershipReportFinished())
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localization: LocalizationService,
    private drawerService: FireflyDrawerService,
    private store: Store
  ) {}
}
