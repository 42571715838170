import { LoadStatus } from '@capital-access/common/utils';

export const TemplateExportInitialState = {
  exportData: [],
  loaderStatus: LoadStatus.Pristine,
  addListDrawerOpened: false,
  selectedList: [],
  commentSectionDrawerState: {
    isOpened: false,
    selectedListItem: null
  },
  filterActivitiesDrawerState: {
    isOpened: false,
    selectedListItem: null,
    companyContactLoadStatus: LoadStatus.Pristine,
    companyContacts: []
  },
  showExportWarning: true
};
