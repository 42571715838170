export const isIphone = () => /iphone/gi.test(navigator.platform) || /iphone/gi.test(navigator.userAgent);
export const isIpad = () =>
  /ipad/gi.test(navigator.platform) ||
  /ipad/gi.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
export const isWindows = () => /win32/gi.test(navigator.platform) || /windows/gi.test(navigator.userAgent);
export const isSafari = () => {
  return (
    /Safari/gi.test(navigator.userAgent) && !new RegExp('CriOS|FxiOS|Chrome|Firefox', 'gi').test(navigator.userAgent)
  );
};
export const isMac = () => /macintosh|mac/gi.test(navigator.platform) || /macintosh|mac/gi.test(navigator.userAgent);
export const isMobile = () => /mobile/gi.test(navigator.userAgent);
export const isTablet = () => /tablet/gi.test(navigator.userAgent);
