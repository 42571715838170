import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, switchMap, take } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import {
  INCREASE_EXPORT_LIMIT_FT,
  LOCALIZATION_KEY,
  MAX_REPORT_PROFILES_COUNT,
  MAX_REPORT_PROFILES_COUNT_WITH_FT
} from '../constants';

@Pipe({ name: 'caReportGenerationTooltip', pure: false })
export class ReportGenerationTooltipPipe extends BaseAsyncPipe<string, number | null> implements PipeTransform {
  constructor(
    private localizationService: LocalizationService,
    private store: Store,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  getTransformer(profilesCount: number | null): Observable<string> {
    return this.store.select(getFeatureToggle(INCREASE_EXPORT_LIMIT_FT)).pipe(
      take(1),
      switchMap(exportReportLimitFtEnabled => {
        const maxReportProfilesCount = exportReportLimitFtEnabled
          ? MAX_REPORT_PROFILES_COUNT_WITH_FT
          : MAX_REPORT_PROFILES_COUNT;

        return this.localizationService
          .localize(
            ['minimumItemsSelectedTooltip', 'maximumItemsSelectedTooltip'],
            { MAX_REPORT_PROFILES_COUNT: maxReportProfilesCount },
            { scope: LOCALIZATION_KEY }
          )
          .pipe(
            map(tooltips => {
              const [minimumItemsSelectedTooltip, maximumItemsSelectedTooltip] = tooltips;
              return profilesCount! <= 0
                ? minimumItemsSelectedTooltip
                : profilesCount! > maxReportProfilesCount
                ? maximumItemsSelectedTooltip
                : '';
            })
          );
      })
    );
  }
}
