import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject } from 'rxjs';
import { SpinnerSize } from '../../../loading-indicators';
import { ModalRef } from '../../../modal/models/base-modal-options';
import { Breakpoint, NestedValuePipe } from '../../../utils';
import { FireflyLocalizationService } from '../../../utils/localization/firefly-localization.service';
import { FireflySuggesterListDirective } from '../../directives/suggester-list.directive';
import { ComplexListData } from '../../models/complex-list-data.model';
import { ValidationType } from '../../models/validation-type.enum';
import { SuggesterManager } from '../../suggester-manager/suggester-manager.class';
import { FireflyListStatusItemsComponent } from '../list-status-items/list-status-items.component';
import { FireflySuggesterListItemComponent } from '../suggester-list-item/suggester-list-item.component';
import { FireflySuggesterMobileComponent } from '../suggester-mobile/suggester-mobile.component';
import { FireflyBaseSuggesterInputs } from './base-suggester-inputs';

@Component({ template: '' })
export abstract class FireflyBaseSuggesterComponent extends FireflyBaseSuggesterInputs implements OnInit, OnDestroy {
  protected suggesterManager!: SuggesterManager;
  protected modalComponent = FireflySuggesterMobileComponent;
  protected modalDialogClass = 'mobile-suggester-modal';
  protected modal!: ModalRef<unknown> | null;

  changeDetectorRef!: ChangeDetectorRef;
  nestedValuePipe!: NestedValuePipe;

  @ViewChild('dropdown') dropdown!: NgbDropdown;
  @ViewChild('resultsBox') resultsBox!: ElementRef;
  @ViewChild('resultsList', { read: ElementRef }) resultsList!: ElementRef;
  @ViewChild('inputElement', { read: ElementRef }) inputElement!: ElementRef;
  @ViewChild('resultsFooter', { read: ElementRef }) resultsFooter!: ElementRef;
  @ViewChild(FireflySuggesterListDirective, { read: ElementRef })
  fSuggesterList!: ElementRef;
  @ViewChild(FireflyListStatusItemsComponent, { read: ElementRef }) listStatusItemsRef!: ElementRef;
  @ViewChild('actionButtons', { read: ElementRef }) actionButtons!: ElementRef;
  @ViewChildren(FireflySuggesterListItemComponent, { read: ElementRef }) listItems!: QueryList<ElementRef>;

  @ContentChild('fSuggesterItemTemplate') suggesterItemTemplate?: TemplateRef<unknown>;
  @ContentChild('fNoResultsTemplate') noResultsTemplate?: TemplateRef<unknown>;
  @ContentChild('fLoaderTemplate') loaderTemplate?: TemplateRef<unknown>;
  @ContentChild('fErrorTemplate') errorTemplate?: TemplateRef<unknown>;
  @ContentChild('fNoDataTemplate') noDataTemplate?: TemplateRef<unknown>;
  @ContentChild('fViewMoreTemplate') viewMoreTemplate?: TemplateRef<unknown>;
  @ContentChild('fFooterTemplate') footerTemplate?: TemplateRef<unknown>;

  dropdownMaxHeight = 150;
  spinnerSizes = SpinnerSize;
  selectedChips: ComplexListData[] = [];
  clearTooltip$ = of('Clear');
  requiredErrorMessage$ = of('This field is required');
  predefinedSetIsLoading = false;
  destroyed$ = new Subject<void>();
  container!: HTMLElement;

  constructor(public baseHost: ElementRef, @Optional() private baseLocalizationService: FireflyLocalizationService) {
    super();
  }

  get inputClass() {
    return this.isOutlineInput ? 'form-control-outline' : '';
  }

  get requiredAndInvalid() {
    return !this.isRequired || this.validation
      ? undefined
      : this.inputValue.errors?.required && !this.inputValue.value && !this.dropdown?.isOpen();
  }

  get validationClass() {
    if (this.validation?.type === ValidationType.Valid) return 'is-valid';
    if (this.validation?.type === ValidationType.Warning) return 'is-warning';
    if (this.validation?.type === ValidationType.Invalid) return 'is-invalid';
    else return '';
  }

  get validationMessageClass() {
    let suffix = '-feedback';
    if (this.validation?.tooltip) suffix += '-tooltip';

    if (this.validation?.type === ValidationType.Valid) return 'valid' + suffix;
    if (this.validation?.type === ValidationType.Warning) return 'warning' + suffix;
    if (this.validation?.type === ValidationType.Invalid) return 'invalid' + suffix;
    else return '';
  }

  get suggesterIsInFocus() {
    return this.baseHost.nativeElement.contains(document.activeElement);
  }

  get modalIsOpen() {
    return this.modal;
  }

  resetValue($event?: Event) {
    this.suggesterManager.clearInput();
    $event?.stopPropagation();
  }

  toggleDropdown(state: boolean) {
    this.suggesterManager.toggleDropdown(state);
    if (!state) this.modal?.dismiss();
  }

  applyDefaultValidation() {
    this.suggesterManager.applyDefaultValidation();
  }

  scrollItemIntoView(itemName: string) {
    const searchQuery = this.inputValue.value?.trim();

    // Check if case-insensitive search query matches name of item to scroll.
    if (searchQuery) {
      const regEx = new RegExp(searchQuery, 'gi');
      if (!itemName.match(regEx)) return;
    }

    requestAnimationFrame(() => {
      const newItem = this.listItems.last?.nativeElement;

      if (!newItem) return;

      requestAnimationFrame(() => newItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' }));
      const newItemContainer = newItem.closest('.list-group-item');

      if (!newItemContainer) return;

      newItemContainer?.classList.add('f-new-suggester-item');
      requestAnimationFrame(() => newItemContainer?.classList.add('animated'));
    });
  }

  getResultsBoxMinHeight(resultsAmount: number) {
    const listStatusItemsHeight = this.listStatusItemsRef?.nativeElement.getBoundingClientRect().height ?? 0;
    const footerHeight = this.resultsFooter?.nativeElement.getBoundingClientRect().height ?? 0;
    const computedStyles = window.getComputedStyle(this.resultsList?.nativeElement);
    const pt = computedStyles?.paddingTop ?? '0';
    const pb = computedStyles?.paddingBottom ?? '0';
    const verticalPaddings = parseInt(pt) + parseInt(pb);
    const auxHeight = listStatusItemsHeight + verticalPaddings;

    return resultsAmount <= this.maxResultsCount ? auxHeight + footerHeight : auxHeight;
  }

  focusIn() {
    requestAnimationFrame(() => {
      window.innerWidth < Breakpoint.Sm
        ? this.inputElement.nativeElement.click()
        : this.inputElement.nativeElement.focus();
    });
  }

  onFocus() {
    if (this.predefinedSetIsLoading) {
      if (this.items.length) {
        this.suggesterManager.toggleDropdown(true);
      } else {
        this.suggesterManager.toggleLoader(true);
      }
    }
    if (this.suggesterManager.inputLengthValid) {
      this.suggesterManager.filterList();
    }
    if ((this.showPredefinedSet && !this.predefinedSetIsLoading) || this.showError) {
      this.suggesterManager.toggleDropdown(true);
    }
    if (!this.inputElement.nativeElement.hasAttribute('readonly')) {
      this.focus.emit();
    }
  }

  ngOnInit() {
    this.container = this.baseHost.nativeElement.parentElement;
    this.suggesterManager.subscribeToInputChanges();

    if (!this.baseLocalizationService) return;

    this.clearTooltip$ = this.baseLocalizationService.localize('clearTooltip', {});
    this.requiredErrorMessage$ = this.baseLocalizationService.localize('suggesterIsRequired', {});
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
