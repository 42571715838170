import { ChangeDetectionStrategy, Component, computed, forwardRef, inject, input, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { FileInfo } from '@progress/kendo-angular-upload';
import { noop, of } from 'rxjs';
import { FireflyHashMap } from '../../utils/localization/firefly-localization.model';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';
import { FireflyFileSelectComponent } from '../file-select/file-select.component';
import { FileWithTextToDisplayState } from './file-select-with-rename.internal.models';
import { FileWithTextToDisplay } from './file-select-with-rename.models';

@Component({
  selector: 'f-file-select-with-rename',
  templateUrl: './file-select-with-rename.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FireflyFileSelectWithRenameComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FireflyFileSelectWithRenameComponent),
      multi: true
    }
  ]
})
export class FireflyFileSelectWithRenameComponent implements ControlValueAccessor {
  multiple = input(true);
  maxTotalSize = input(0);
  maxFileSize = input(0);
  allowedExtensions = input<string[]>([]);
  invalidFiles = input<string[]>([]);
  caseInsensitiveValidation = input(false);

  readonly localization = toSignal(
    inject(FireflyLocalizationService, { optional: true })?.getLocalization() ?? of({} as FireflyHashMap),
    { initialValue: {} as FireflyHashMap }
  );

  @ViewChild(FireflyFileSelectComponent) fileselector!: FireflyFileSelectComponent;

  readonly nameIsRequired = computed(() => this.localization()['fieldIsRequired'] || 'This field is required');
  readonly fileNameLabel = computed(() => this.localization()['textToDisplayLabel'] || 'Text to Display');

  private filesState = new Map<string, FileWithTextToDisplayState>();
  private emitValueChange: (files: FileWithTextToDisplay[] | undefined) => void = noop;
  private markTouched: () => void = noop;

  get value(): FileWithTextToDisplay[] {
    return Array.from(this.filesState.values());
  }

  validate(): ValidationErrors | null {
    const valuesArray = Array.from(this.filesState.values());
    const hasError = this.fileselector?.validate() || valuesArray.some(x => !x.textToDisplayIsValid);
    return hasError ? { hasValidationError: true } : null;
  }

  writeValue: (files: FileWithTextToDisplay[] | undefined) => void = noop;

  registerOnChange(fn: (files: FileWithTextToDisplay[] | undefined) => void) {
    this.emitValueChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.markTouched = fn;
  }

  onFilesSelected(selectedFiles: FileInfo[]) {
    const clones = selectedFiles.map(file => ({
      ...file
    }));
    clones.forEach(f => {
      this.filesState.set(f.name, {
        file: f,
        textToDisplay: f.name,
        textToDisplayIsValid: true
      });
    });

    this.updateFormState();
  }

  onFilesRemoved(removedFiles: FileInfo[]) {
    const removedFileNames = removedFiles.map(f => f.name);
    removedFileNames.forEach(x => {
      if (x) this.filesState.delete(x);
    });

    this.updateFormState();
  }

  updateTextToDisplay($event: Event, fileName: string) {
    const textToDisplay = ($event.target as HTMLInputElement).value.trim();

    const file = this.filesState.get(fileName);
    if (file) {
      file.textToDisplay = textToDisplay;
      file.textToDisplayIsValid = !!textToDisplay;
    }

    this.updateFormState();
  }

  isTextToDisplayInvalid(fileName: string) {
    return !this.filesState.get(fileName)?.textToDisplayIsValid;
  }

  private updateFormState() {
    this.markTouched();
    this.emitValueChange(this.value);
  }
}
