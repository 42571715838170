<kendo-fileselect
  #fileselect
  [title]="title"
  [multiple]="multiple"
  (select)="select($event)"
  (success)="success()"
  (remove)="remove($event)"
  (valueChange)="valueChange($event)"
>
  <ng-template kendoFileSelectFileTemplate let-files>
    <div
      class="k-file-single flex-wrap"
      [ngClass]="{ 'f-file-invalid': isFileInvalid(file) }"
      *ngFor="let file of files"
    >
      <div class="f-file-description d-flex align-items-center w-100 py-2 ps-2 pe-1">
        <i class="f-i f-i-md f-file-icon" [ngClass]="getFileIconCssClass(file.extension)"></i>
        <span class="k-file-info ms-2">
          <span class="k-file-name" title="{{ file.name }}">{{ file.name }}</span>
          <span class="k-file-summary">{{ convertFileSize(file.size) }} </span>
        </span>
        <span class="k-upload-actions">
          <button
            class="k-button k-button-md k-fileselect-button-close"
            [title]="removeTitle()"
            (click)="removeItem(file)"
          >
            <i class="f-i f-i-close f-i-sm"></i>
          </button>
        </span>
      </div>
      @if(customTemplate){
      <div class="w-100 mt-1">
        <ng-container *ngTemplateOutlet="customTemplate.template; context: { $implicit: file }"></ng-container>
      </div>
      }
    </div>
  </ng-template>
  <kendo-upload-messages
    [dropFilesHere]="(uploaderDragDrop$ | async)!"
    [headerStatusUploaded]="(uploaderDragDrop$ | async)!"
    [headerStatusUploading]="(uploaderDragDrop$ | async)!"
    [select]="(uploaderBrowseFiles$ | async)!"
    [fileStatusFailed]="(uploaderStatusFailed$ | async)!"
  >
  </kendo-upload-messages>
</kendo-fileselect>
