import { createAction, props } from '@ngrx/store';
import { NewImport } from '@capital-access/data-import/common';
import { AiComponentData } from '../models/chat-event';
import { ChatMessage } from '../models/chat-message';

export const loadAiPanel = createAction('[AI Panel] Load AI Panel');
export const openDrawer = createAction('[AI Panel] Open Drawer');

export const openDisclaimer = createAction('[AI Panel] Open Disclaimer');

export const submitChat = createAction('[AI Panel] Submit Chat', props<{ content: string; timestamp: string }>());
export const submitChatFailure = createAction(
  '[AI Panel] Submit Chat Failure',
  props<{ content?: string; error?: unknown }>()
);

export const streamingResponseStart = createAction(
  '[AI Panel] Streaming Chat Response Start',
  props<{ messageId: string; timestamp: string }>()
);
export const streamingResponseToken = createAction(
  '[AI Panel] Streaming Chat Response Token',
  props<{ messageId: string; content: string; timestamp: string }>()
);
export const streamingResponseEnd = createAction(
  '[AI Panel] Streaming Chat Response End',
  props<{ messageId: string; timestamp: string }>()
);

export const streamingFunctionCallStart = createAction(
  '[AI Panel] Streaming Function Call Start',
  props<{ messageId: string; content: string }>()
);
export const streamingFunctionCallEnd = createAction(
  '[AI Panel] Streaming Function Call End',
  props<{ messageId: string; content: string; fnParams?: Record<string, object>; timestamp: string }>()
);

export const streamingTableComponent = createAction(
  '[AI Panel] Streaming Table Result',
  props<{ messageId: string; componentData: AiComponentData; timestamp: string }>()
);

export const streamingHeartbeat = createAction('[AI Panel] Streaming Heartbeat');
export const chatCompleted = createAction('[AI Panel] Chat Completed');

export const createNewChat = createAction('[AI Panel] Create New Chat');
export const newChatSuccess = createAction('[AI Panel] New Chat Success', props<{ id: string }>());
export const newChatFailure = createAction('[AI Panel] New Chat Failure', props<{ error: unknown }>());

export const openChatHistory = createAction('[AI Panel] Open Chat History');
export const openChatHistorySuccess = createAction(
  '[AI Panel] Open Chat History Success',
  props<{ conversations: ChatMessage[] }>()
);
export const openChatHistoryFailure = createAction('[AI Panel] Open Chat History Failure', props<{ error: unknown }>());

export const loadMoreConversations = createAction('[AI Panel] Load More Conversations');
export const loadMoreConversationsSuccess = createAction(
  '[AI Panel] Load More Conversations Success',
  props<{ conversations: ChatMessage[] }>()
);

export const openChat = createAction('[AI Panel] Open Chat', props<{ chatId: string; content: string }>());
export const openChatSuccess = createAction('[AI Panel] Open Chat Success', props<{ chatHistory: ChatMessage[] }>());
export const openChatFailure = createAction('[AI Panel] Open Chat Failure', props<{ error: unknown }>());

export const addToInstitutionList = createAction('[AI Panel] Add To Institution List', props<{ ids: any }>());

export const openImportDrawer = createAction('[AI Panel] Open Import Drawer');
export const dispatchFile = createAction('[AI Panel] Dispatch File', props<{ newImport: any }>());
export const dispatchFileSuccess = createAction(
  '[AI Panel] Dispatch File Success',
  props<{ componentData: AiComponentData }>()
);
export const dispatchFileFailure = createAction(
  '[AI Panel] Dispatch File Failure',
  props<{ content?: string; error?: unknown }>()
);

export const removeActivity = createAction('[AI Panel] Remove Activity', props<{ i: number }>());
export const clearActivities = createAction('[AI Panel] Clear Activities');

export const addNewActivity = createAction('[AI Panel] Add New Activity', props<{ activity: any }>());
export const addNewActivitySuccess = createAction('[AI Panel] Add New Activity Success', props<{ activity: any }>());
export const addNewActivityFailure = createAction('[AI Panel] Add New Activity Failure', props<{ error: unknown }>());

export const submitIsLiked = createAction(
  '[AI Panel] Submit IsLiked',
  props<{ messageId: string; isLiked: boolean }>()
);
export const submitIsLikedSuccess = createAction(
  '[AI Panel] Submit IsLiked Success',
  props<{ messageId: string; isLiked: boolean }>()
);
export const submitIsLikedFailure = createAction(
  '[AI Panel] Submit IsLiked Failure',
  props<{ messageId: string; error: unknown }>()
);

export const submitComments = createAction(
  '[AI Panel] Submit Comments',
  props<{ messageId: string; comments: string }>()
);
export const submitCommentsSuccess = createAction(
  '[AI Panel] Submit Comments Success',
  props<{ messageId: string; comments: string }>()
);
export const submitCommentsFailure = createAction('[AI Panel] Submit Comments Failure', props<{ error: unknown }>());

export const resetActiveCommentsMessageId = createAction('[AI Panel] Reset Active Comments Message Id');

export const openConfirmationModal = createAction(
  '[AI Panel] Open Confirmation Modal',
  props<{ chatId: string; content: string }>()
);
export const deleteChat = createAction('[AI Panel] Delete Chat', props<{ chatId: string; content: string }>());
export const deleteChatSuccess = createAction(
  '[AI Panel] Delete Chat Success',
  props<{ chatId: string; content: string }>()
);
export const deleteChatFailure = createAction(
  '[AI Panel] Delete Chat Failure',
  props<{ content: string; error: unknown }>()
);
