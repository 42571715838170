import { ChangeDetectionStrategy, Component, input, Optional } from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';
import { MobileInnerErrorOptions } from '../models/mobile-inner-error-options.enum';

@Component({
  selector: 'f-mobile-inner-error',
  templateUrl: './mobile-inner-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMobileInnerErrorComponent {
  errorOption = input.required<MobileInnerErrorOptions>();
  caption = input<string>('');
  info = input<string>('');

  MobileInnerErrorOptions = MobileInnerErrorOptions;

  constructor(@Optional() private localizationService: FireflyLocalizationService) {}

  mobileViewTitle$ = this.localizationService
    ? this.localizationService?.localize('mobileViewTitle', {})
    : of('Mobile view not supported');

  tabletViewTitle$ = this.localizationService
    ? this.localizationService?.localize('tabletViewTitle', {})
    : of('Mobile and Tablet view not supported');

  landscapeModeTitle$ = this.localizationService
    ? this.localizationService?.localize('landscapeModeTitle', {})
    : of('Landscape mode not supported');

  mobileViewInfo$ = this.localizationService
    ? this.localizationService?.localize('mobileViewInfo', {})
    : of('Please use a tablet device or desktop for a more comfortable experience using this module!');

  tabletViewInfo$ = this.localizationService
    ? this.localizationService?.localize('tabletViewInfo', {})
    : of('Please use a desktop for a more comfortable experience using this module!');

  landscapeModeInfo$ = this.localizationService
    ? this.localizationService?.localize('landscapeModeInfo', {})
    : of('Please return to portrait mode to continue working with the application');
}
