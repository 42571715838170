<div
  [froalaEditor]="editorOptions"
  [formControl]="editor()"
  [class.f-text-editor-validation]="requiredAndInvalid || isCustomError"
></div>
@if(requiredAndInvalid) {
<p class="k-editor-validation-message k-editor-validation-message-position">{{ requiredErrorMessage() }}</p>
} @if(validation()?.message) {
<p class="k-editor-validation-message k-editor-validation-message-position">{{ validation()?.message }}</p>
}
<div class="fr-contextual-menu" #contextualMenu>
  <ng-content></ng-content>
</div>
