import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import FroalaEditor, { FroalaEvents, FroalaOptions } from 'froala-editor';
import { of } from 'rxjs';
import { FireflyFroalaLicenseProvider, FireflyLocalizationService, isMac, isSafari } from '../utils';
import { FireflyHashMap } from '../utils/localization/firefly-localization.model';
import { TextEditorValidation } from './models/text-editor-validation.model';
import { ValidationError } from './models/text-editor-validation-error.enum';

@Component({
  selector: 'f-text-editor',
  templateUrl: './text-editor.component.html',
  standalone: true,
  imports: [FroalaEditorModule, FroalaViewModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyTextEditorComponent implements OnInit {
  @ViewChild('contextualMenu') contextualMenu!: ElementRef;

  validation = input<TextEditorValidation>();
  additionalButtons = input<string[][]>([]);
  editor = input<FormControl<string | null>>(new FormControl(''));
  placeholder = input<string>('');
  additionalEvents = input<Partial<FroalaEvents>>({});

  editorOptions!: Partial<FroalaOptions>;
  readonly localization = toSignal(
    inject(FireflyLocalizationService, { optional: true })?.getLocalization() ?? of({} as FireflyHashMap),
    { initialValue: {} as FireflyHashMap }
  );
  readonly requiredErrorMessage = computed(() => this.localization()['fieldIsRequired'] || 'This field is required');
  readonly urlPopupWebAddressMessage = computed(
    () => this.localization()['urlPopupWebAddressMessage'] || 'Web Address'
  );
  readonly urlPopupTextToDisplayMessage = computed(
    () => this.localization()['urlPopupTextToDisplayMessage'] || 'Text to Display'
  );
  readonly urlPopupWebAddressPlaceholderMessage = computed(
    () => this.localization()['urlPopupWebAddressPlaceholderMessage'] || 'Enter web address'
  );
  readonly urlPopupTextToDisplayPlaceholderMessage = computed(
    () => this.localization()['urlPopupTextToDisplayPlaceholderMessage'] || 'Enter text to display'
  );
  readonly colorPickerButtonMessage = computed(() => this.localization()['colorPickerButtonMessage'] || 'Apply');
  readonly colorPickerPlaceholderMessage = computed(
    () => this.localization()['colorPickerPlaceholderMessage'] || 'Enter HEX color'
  );

  private renderer = inject(Renderer2);
  private licenseService = inject(FireflyFroalaLicenseProvider, { optional: true });

  ngOnInit(): void {
    FroalaEditor.DefineIconTemplate('common', '<i class="f-i f-i-xs f-i-[NAME]"></i>');
    FroalaEditor.DefineIcon('undo', { NAME: 'undo', template: 'common' });
    FroalaEditor.DefineIcon('redo', { NAME: 'redo', template: 'common' });
    FroalaEditor.DefineIcon('bold', { NAME: 'bold', template: 'common' });
    FroalaEditor.DefineIcon('formatUL', { NAME: 'bulleted-list', template: 'common' });
    FroalaEditor.DefineIcon('clearFormatting', { NAME: 'clean', template: 'common' });
    FroalaEditor.DefineIcon('italic', { NAME: 'italic', template: 'common' });
    FroalaEditor.DefineIcon('formatOLSimple', { NAME: 'numbered-list', template: 'common' });
    FroalaEditor.DefineIcon('textColor', { NAME: 'text-color', template: 'common' });
    FroalaEditor.DefineIcon('underline', { NAME: 'underline', template: 'common' });
    FroalaEditor.DefineIcon('backgroundColor', { NAME: 'color-highlight', template: 'common' });
    FroalaEditor.DefineIcon('insertTable', { NAME: 'table', template: 'common' });
    FroalaEditor.DefineIcon('insertImage', { NAME: 'image', template: 'common' });
    FroalaEditor.DefineIcon('insertLink', { NAME: 'url', template: 'common' });
    FroalaEditor.DefineIcon('spellcheck', { NAME: 'spell-check', template: 'common' });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    this.editorOptions = {
      key: this.license,
      toolbarButtons: [
        ['undo', 'redo'],
        ['bold', 'italic', 'underline'],
        ['backgroundColor'],
        ['fontSize'],
        ['textColor'],
        ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
        ['formatOLSimple', 'formatUL'],
        ...(this.additionalButtons() || []),
        ['clearFormatting']
      ],
      events: {
        initialized: () => {
          const insertLinkButton = document.querySelector('[data-cmd="insertLink"]');
          component.renderer.listen(insertLinkButton, 'click', () => {
            component.updateLabelAndInput(
              '#fr-link-insert-layer-url-1',
              component.urlPopupWebAddressMessage(),
              component.urlPopupWebAddressPlaceholderMessage()
            );
            component.updateLabelAndInput(
              '#fr-link-insert-layer-text-1',
              component.urlPopupTextToDisplayMessage(),
              component.urlPopupTextToDisplayPlaceholderMessage()
            );
          });

          const fontSizeBtn = document.querySelector('[data-cmd="fontSize"]')!;
          fontSizeBtn.setAttribute('title', 'Size');
          fontSizeBtn.textContent = 'Size';

          component.setupColorPickerHandler('button[data-cmd="backgroundColor"]', '[data-cmd="applybackgroundColor"]');
          component.setupColorPickerHandler('button[data-cmd="textColor"]', '[data-cmd="applytextColor"]');

          component.replaceSvgWithIcon('button[data-cmd="alignLeft"]', ['f-i', 'f-i-alignment']);
          component.replaceSvgWithIcon('button[data-cmd="alignCenter"]', ['f-i', 'f-i-center-alignment']);
          component.replaceSvgWithIcon('button[data-cmd="alignRight"]', ['f-i', 'f-i-right-alignment']);
          component.replaceSvgWithIcon('button[data-cmd="alignJustify"]', ['f-i', 'f-i-width-alignment']);
        },
        blur: function () {
          component.editor().markAsTouched();

          if (!component.contextualMenu.nativeElement.children.length) return;
          const contextualMenu = component.contextualMenu?.nativeElement;
          const editor = this as unknown as FroalaEditor;
          if (contextualMenu.classList.contains('show')) component.hideContextualMenu(editor, contextualMenu);
        },
        focus: () => {
          setTimeout(() => this.editor().markAsUntouched(), 0);
        },
        mouseup: function (e: MouseEvent) {
          if (!component.contextualMenu.nativeElement.children.length) return;
          const contextualMenuElement = component.contextualMenu.nativeElement;
          const editor = this as unknown as FroalaEditor;
          const range = editor.selection.ranges(0) as Range;
          const newRange = document.createRange();
          const parentRect = editor.el.parentElement.parentElement.getBoundingClientRect();

          newRange.setStart(range.startContainer, range.startOffset);
          newRange.setEnd(range.endContainer, range.endOffset);

          const x = newRange.getBoundingClientRect().left - parentRect.left;
          const y = newRange.getBoundingClientRect().top - parentRect.top;

          // editor.el.oncontextmenu = () => false; doesn't work in Safari and MacOS, so we need to disable the browser context menu
          if (isSafari() || isMac()) editor.el.addEventListener('contextmenu', (e: MouseEvent) => e.preventDefault());

          if (component.isRightMouseButton(e) && editor.selection.text().length) {
            component.showContextualMenu(x, y, editor, contextualMenuElement);
          }

          const links = editor.el.querySelectorAll('a');
          links.forEach((link: HTMLElement) => {
            if (component.isRightMouseButton(e) && (e.target as HTMLElement).tagName === 'A') {
              const linkRect = link.getBoundingClientRect();
              const x = linkRect.left - parentRect.left;
              const y = linkRect.top - parentRect.top;
              component.showContextualMenu(x, y, editor, contextualMenuElement);
            }
          });

          requestAnimationFrame(() => {
            if (
              editor.selection.isCollapsed() &&
              (e.target! as HTMLElement).tagName !== 'A' &&
              contextualMenuElement.classList.contains('show')
            )
              component.hideContextualMenu(editor, contextualMenuElement);
          });
        },
        'image.beforeUpload': function (files: File[]) {
          if (files.length) {
            const reader = new FileReader();

            reader.onload = (event: ProgressEvent<FileReader>) => {
              const result = event.target?.result as string;
              this.image.insert(result, false, {}, this.image.get());
            };

            reader.readAsDataURL(files[0]);
          }

          this.popups.hideAll();
          return false; // Stop default upload
        },
        ...this.additionalEvents()
      },
      placeholderText: this.placeholder() || '',
      fontSize: ['8', '10', '12', '14', '18', '24', '36'],
      fontFamilySelection: true,
      imageOutputSize: true,
      fontSizeSelection: true,
      paragraphFormatSelection: true,
      attribution: false,
      linkEditButtons: []
    };
  }

  get requiredAndInvalid(): boolean | undefined {
    return !(this.validation()?.error === ValidationError.Required)
      ? undefined
      : this.editor().errors?.required && this.editor().touched;
  }

  get isCustomError(): boolean {
    return this.validation()?.error === ValidationError.Custom && this.editor().touched;
  }

  private get license(): string {
    return this.licenseService?.getLicense() || '';
  }

  private isRightMouseButton(e: MouseEvent): boolean {
    return e.button === 2;
  }

  private showContextualMenu(x: number, y: number, editor: FroalaEditor, contextualMenu: HTMLElement): void {
    const lineHeight = 16;
    editor.el.oncontextmenu = () => false;
    this.renderer.setStyle(contextualMenu, 'transform', `translate(${x}px, ${y + lineHeight}px)`);
    this.renderer.addClass(contextualMenu, 'show');
  }

  private hideContextualMenu(editor: FroalaEditor, contextualMenu: HTMLElement): void {
    editor.el.oncontextmenu = null;
    this.renderer.removeClass(contextualMenu, 'show');
  }

  private updateLabelAndInput(labelSelector: string, newLabelText: string, placeholderText: string): void {
    const label = document.querySelector(`${labelSelector} + label`)!;
    label.textContent = newLabelText;

    const star = this.renderer.createElement('span') as HTMLElement;
    this.renderer.addClass(star, 'text-danger');
    star.textContent = '*';
    this.renderer.appendChild(label, star);

    const input = document.querySelector(labelSelector)!;
    this.renderer.setAttribute(input, 'placeholder', placeholderText);
  }

  private setupColorPickerHandler(cmdSelector: string, clearButtonCmdSelector: string): void {
    const colorPicker = document.querySelector(cmdSelector);

    this.renderer.listen(colorPicker, 'click', () => {
      const colorPickerClearIcon = document.querySelector('.fr-popup.fr-active .fr-submit')!;
      const input = document.querySelector('.fr-popup.fr-active .fr-input-line > input')!;
      this.renderer.setProperty(colorPickerClearIcon, 'textContent', this.colorPickerButtonMessage());
      this.renderer.setAttribute(input, 'placeholder', this.colorPickerPlaceholderMessage());

      if (document.querySelector(`${clearButtonCmdSelector} .f-i-trash-bin`) === null) {
        const svgElement = document.querySelector(`${clearButtonCmdSelector} .fr-svg`)!;
        const parentElement = svgElement.parentElement;
        const newIcon = this.renderer.createElement('i');
        this.renderer.addClass(newIcon, 'f-i');
        this.renderer.addClass(newIcon, 'f-i-trash-bin');
        this.renderer.appendChild(parentElement, newIcon);
      }
    });
  }

  private replaceSvgWithIcon(cmdSelector: string, iconClassList: string[]): void {
    const svgElement = document.querySelector(`${cmdSelector} .fr-svg`)!;
    const parentElement = svgElement.parentElement;
    this.renderer.removeChild(parentElement, svgElement);
    const newIcon = this.renderer.createElement('i');
    iconClassList.forEach(cls => this.renderer.addClass(newIcon, cls));
    this.renderer.appendChild(parentElement, newIcon);
  }
}
