import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TEMPLATE_DESIGNER_FEATURE_KEY,
  TEMPLATE_LIBRARY_FEATURE_KEY
} from '../models/report-section-configuration.model';
import { TemplateDesignerState } from '../models/template-designer-state.model';
import { TemplateLibraryState } from '../models/template-library-state.model';

export const templateDesignerSelector = createFeatureSelector<TemplateDesignerState>(TEMPLATE_DESIGNER_FEATURE_KEY);
export const templateLibraryFeatureSelector = createFeatureSelector<TemplateLibraryState>(TEMPLATE_LIBRARY_FEATURE_KEY);

export const getSelectedListItemForTemplateExport = createSelector(
  templateDesignerSelector,
  state => state.export.selectedList.filter(item => item.isSelected)[0]
);

export const getSelectedListForTemplateExportInclusion = createSelector(
  templateDesignerSelector,
  state => state.export.selectedList
);

export const getCurrentReportTemplatePageSize = createSelector(
  templateDesignerSelector,
  (state: TemplateDesignerState) => {
    const settings = state.reportTemplate?.settings ? JSON.parse(state.reportTemplate.settings) : {};
    return settings.pageSize;
  }
);

export const getSelectedTemplatePageSize = createSelector(
  templateLibraryFeatureSelector,
  (state: TemplateLibraryState) => state.templatePageSize
);

export const getZoomValue = createSelector(
  templateDesignerSelector,
  (state: TemplateDesignerState) => state.zoomState.zoom
);
