<div class="mobile-inner-error-wrapper">
  <div class="mobile-inner-error-content">
    <div class="mobile-inner-error-img" data-automation-id="mobile-inner-error-img" [ngClass]="errorOption()"></div>
    <div>
      <div class="mobile-inner-error-caption" data-automation-id="mobile-inner-error-caption">
        @if (caption()) { {{ caption() }} } @else {
        <ng-container *ngIf="errorOption() === MobileInnerErrorOptions.MobileView">
          {{ mobileViewTitle$ | async }}
        </ng-container>
        <ng-container *ngIf="errorOption() === MobileInnerErrorOptions.TabletView">
          {{ tabletViewTitle$ | async }}
        </ng-container>
        <ng-container *ngIf="errorOption() === MobileInnerErrorOptions.LandscapeMode">
          {{ landscapeModeTitle$ | async }}
        </ng-container>
        }
      </div>
      <div class="mobile-inner-error-info" data-automation-id="mobile-inner-error-info">
        @if (info()) { {{ info() }} } @else {
        <ng-container *ngIf="errorOption() === MobileInnerErrorOptions.MobileView">
          {{ mobileViewInfo$ | async }}
        </ng-container>
        <ng-container *ngIf="errorOption() === MobileInnerErrorOptions.TabletView">
          {{ tabletViewInfo$ | async }}
        </ng-container>
        <ng-container *ngIf="errorOption() === MobileInnerErrorOptions.LandscapeMode">
          {{ landscapeModeInfo$ | async }}
        </ng-container>
        }
      </div>
    </div>
  </div>
</div>
