import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { v4 as guid } from 'uuid';
import { BadgeType, LinkNavItem, UserNavItem } from '@capital-access/common/navigation';
import { BetaBadgeSize } from '@capital-access/firefly/components';
import { NAVIGATION_LOCALIZATION_SCOPE } from '../../../navigation.constants';
import { NAV_TRANSITION_DURATION_FUNC } from '../../constants';

@Component({
  selector: 'ca-link-nav-item',
  templateUrl: './link-nav-item.component.html',
  styleUrls: ['../../side-nav-item.scss', '../../side-nav-item.theme.scss', './link-nav-item.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ height: 0, overflow: 'hidden' })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC)])
    ]),
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC)])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkNavItemComponent {
  @Input() active: boolean | null = false;
  @Input() mini!: boolean;
  @Input() navItem!: LinkNavItem | UserNavItem;
  @Input() set sharedNavItem(isSharedNavItem: boolean) {
    this.localizationScope = isSharedNavItem ? NAVIGATION_LOCALIZATION_SCOPE : '';
  }
  @Output() itemClick = new EventEmitter<string>();
  BetaBadgeSize = BetaBadgeSize.Sm;
  BadgeType = BadgeType;
  localizationScope = '';

  id = guid();
  expanded = false;

  activeFirstNavClass = 'ca-nav-item-active';
  activeSecondNavClass = 'ca-link-nav-subitem-active';
  selectableClass = 'ca-nav-item-selectable';

  get expandable(): boolean {
    return !this.mini && this.hasSubItems;
  }

  get hasSubItems(): boolean {
    return 'subItems' in this.navItem && !!this.navItem.subItems && this.navItem.subItems.length > 0;
  }

  get linkHighlightClass() {
    return this.active ? this.activeFirstNavClass : this.selectableClass;
  }

  get chevronTransitionClass() {
    return `ca-nav-item-${this.expanded ? 'expanded' : 'collapsed'}`;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  onClick(event: MouseEvent, tooltip: NgbTooltip): void {
    const willToggleExpand = !this.mini && this.hasSubItems;
    const willNavigateInMiniMode = this.mini && !this.hasSubItems;

    if (willToggleExpand || willNavigateInMiniMode) {
      event.stopPropagation();
    }

    if (tooltip.isOpen()) {
      tooltip.close();
    }

    this.itemClick.emit(this.id);
  }

  show() {
    this.expanded = true;
    this.changeDetectorRef.markForCheck();
  }

  hide() {
    this.expanded = false;
    this.changeDetectorRef.markForCheck();
  }
}
