import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ToggleNavItem } from './navigation-item';

export interface Orderable {
  order?: number;
}

export interface SubNavItemConfig extends Orderable {
  localizationKey: string;
  link: string;
}

export enum BadgeType {
  BadgeBeta = 'badge-beta'
}

export interface LinkNavItemConfig extends Orderable {
  localizationKey: string;
  icon?: string;
  link?: string;
  subItems?: SubNavItemConfig[];
  enabled?: boolean;
  badgeType?: BadgeType;
}

export interface EntityNavItemConfig extends Orderable {
  localizationKey: string;
  icon?: string;
  action?: Action;
}

export interface UserNavItemConfig extends Orderable {
  localizationKey: string;
  iconText?: string;
  link?: string;
  action?: Action;
  badgeType?: BadgeType;
}

export interface ActionNavItemConfig extends Orderable {
  localizationKey: string;
  icon: string;
  action?: Action;
  featureToggleKey?: string;
}

export type ToggleNavItemConfig = ToggleNavItem;

export interface NavConfig {
  top?: LinkNavItemConfig[];
  mobileToggle?: ToggleNavItemConfig[];
  mobileEntity?: EntityNavItemConfig[];
  mobileUser?: UserNavItemConfig[];
  bottom?: ActionNavItemConfig[];
  badgeType?: BadgeType;
}

export type NavConfigFn = () => Observable<NavConfig>;
