import { ColumnComponent } from '@progress/kendo-angular-grid';
import { BaseColumnSettings } from './column-chooser.models';

export function mapColumnsToMinimalColumnInfo(columns: ColumnComponent[]): BaseColumnSettings[] {
  const columnsSnapshot: BaseColumnSettings[] = [];
  columns.forEach((col, index) => {
    const snapshot = {
      field: col.field,
      hidden: col.hidden,
      orderIndex: index
    } as BaseColumnSettings;

    columnsSnapshot.push(snapshot);
  });

  return columnsSnapshot;
}
