<div #titleContainer class="overflow-hidden" [attr.data-automation-id]="dataAutomationId" [ngClass]="rootCssClass">
  <ng-container *ngTemplateOutlet="titleTemplateRef; context: { $implicit: truncatedTitle }"> </ng-container>

  <ng-content #titleObject></ng-content>

  <span
    *ngIf="expandable && (title !== truncatedTitle || showAll)"
    class="link link-primary"
    data-automation-id="show-link"
    (click)="onShowMoreToggle()"
  >
    {{ (showAll ? showLessText$ : showMoreText$) | async }}
  </span>
</div>
