import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ExcelColumn, KendoExcelColumn } from '@capital-access/common/utils';
import { FireflySharedGridComponent } from '@capital-access/firefly/components';
import { ExportableColumnDirective } from './exportable-column.directive';

@Component({
  template: '',
  standalone: true
})
export abstract class ExportableGridComponent extends FireflySharedGridComponent implements OnInit {
  @ViewChildren(ExportableColumnDirective) columns = new QueryList<ExportableColumnDirective>();

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // keeping empty method for backward compatibility
  }

  get exportableColumns(): ExcelColumn[] {
    return this.getColumns().map(column => column.toExcelColumn()) || [];
  }

  get exportableKendoColumns(): KendoExcelColumn[] {
    return this.getColumns().map(column => column.toKendoExcelColumn()) || [];
  }

  private getColumns() {
    return this.columns
      .toArray()
      .filter(col => !col.hidden)
      .sort((a, b) => a.orderIndex - b.orderIndex);
  }
}
