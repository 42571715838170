import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Renderer2,
  Signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, fromEvent, map, merge, Observable, startWith } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavigationItemsService, SideNavigationMap } from '@capital-access/common/navigation';
import {
  Breakpoint,
  FireflyDrawerDefaultContainerDirective,
  FireflyDrawerService,
  FireflyMobileScrollService
} from '@capital-access/firefly/components';
import { getBrandingSettings } from '@capital-access/theming';
import { AppNameLocation } from '../models/app-name-location';

@Component({
  selector: 'ca-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit {
  @ViewChild('scrollableElement') scrollableElement!: ElementRef;
  // should be removed together with mobile-scroll-behavior-CPD-2028
  @ViewChild('updatedScrollableElement') updatedScrollableElement!: ElementRef;
  @ViewChild('desktopDrawerContainer', { read: ViewContainerRef, static: true })
  desktopDrawerContainer!: ViewContainerRef;
  @ViewChild('mobileDrawerContainer', { read: ViewContainerRef, static: true })
  mobileDrawerContainer!: ViewContainerRef;
  changedScrollEnabled!: Signal<boolean | undefined>;

  flexibleMobileNav = true;

  sideNavigationMap$: Observable<SideNavigationMap>;
  appNameLocation$ = this.store
    .select(getBrandingSettings)
    .pipe(map(({ brand }) => (brand === 'bny' ? AppNameLocation.Bottom : AppNameLocation.Top)));

  navigationEnd$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(this.router.url)
  );

  contentScrolableElement?: HTMLElement;
  lastY = 0;
  schedulerPageSetupDefault = {
    schedulerPopoverCloseTimestamp: 0,
    currentScrollTop: 0
  };
  schedulerPageSetup = this.schedulerPageSetupDefault;

  readonly minTimeBetweenManualActions = 100;

  private destroyRef = inject(DestroyRef);

  constructor(
    navigationItemsService: NavigationItemsService,
    private store: Store,
    private router: Router,
    private renderer: Renderer2,
    private drawerService: FireflyDrawerService,
    private mobileScrollService: FireflyMobileScrollService,
    private ngbModal: NgbModal
  ) {
    this.sideNavigationMap$ = navigationItemsService.getSideNavigationMap();
    this.changedScrollEnabled = toSignal(this.store.select(getFeatureToggle('mobile-scroll-behavior-CPD-2028')));
  }

  get mobile() {
    return window.innerWidth < Breakpoint.Sm;
  }

  ngAfterViewInit() {
    this.navigationEnd$
      .pipe(
        map(
          () =>
            (this.changedScrollEnabled() && this.mobile ? this.updatedScrollableElement : this.scrollableElement)
              .nativeElement
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(scrollableElement => {
        this.contentScrolableElement = scrollableElement;
        this.resetSchedulerPageSetup();
      });

    merge(this.navigationEnd$, this.mobileScrollService.scrollTop$)
      .pipe(
        filter(() => !!this.contentScrolableElement?.scrollTop),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.scrollPageTop());

    this.scrollHandler();

    const container = this.mobile ? this.mobileDrawerContainer : this.desktopDrawerContainer;
    if (container) new FireflyDrawerDefaultContainerDirective(container, this.drawerService, this.renderer);
  }

  private scrollHandler() {
    if (!this.changedScrollEnabled() || !this.mobile) return;

    const targetEl = document.querySelector('.ca-content-wrapper') as HTMLElement;
    const richTextEditor = '.k-editor-content .ProseMirror';

    this.scrollHandlerForScheduler(targetEl);

    fromEvent(targetEl, 'touchstart')
      .pipe(
        filter(() => !document.querySelector(richTextEditor)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => (this.lastY = (event as TouchEvent).touches[0].clientY));

    fromEvent(targetEl, 'touchmove', {
      passive: false
    })
      .pipe(
        filter(() => !document.querySelector(richTextEditor)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => this.preventScrollOverflow(event as TouchEvent, targetEl));
  }

  // to catch unexpected uncancelable scroll event in the Kendo scheduler when closing the popover
  // and cancel it manually by setting `scrollTop` value to the same it was when closing the popover
  private scrollHandlerForScheduler(targetEl: HTMLElement) {
    const scheduler = '.k-scheduler';

    this.ngbModal.activeInstances
      .pipe(
        filter(() => !!document.querySelector(scheduler)),
        map(activeInstances => !activeInstances.length),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(isSchedulerPopoverClosed => {
        if (isSchedulerPopoverClosed) {
          this.schedulerPageSetup = {
            schedulerPopoverCloseTimestamp: Date.now(),
            currentScrollTop: this.updatedScrollableElement.nativeElement.scrollTop
          };
        } else {
          this.resetSchedulerPageSetup();
        }
      });

    fromEvent(targetEl, 'scroll')
      .pipe(
        filter(() => !!this.schedulerPageSetup.schedulerPopoverCloseTimestamp),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        const timeBetweenPopoverCloseAndScroll = Date.now() - this.schedulerPageSetup.schedulerPopoverCloseTimestamp;

        if (timeBetweenPopoverCloseAndScroll < this.minTimeBetweenManualActions) {
          this.scrollPageTop(this.schedulerPageSetup.currentScrollTop);
        }

        this.resetSchedulerPageSetup();
      });
  }

  private scrollPageTop(top = 0) {
    this.contentScrolableElement && this.renderer.setProperty(this.contentScrolableElement, 'scrollTop', top);
  }

  private preventScrollOverflow(event: TouchEvent, targetEl: HTMLElement) {
    const top = event.touches[0].clientY;
    const scrollTop = targetEl.scrollTop;
    const maxScrollTop = targetEl.scrollHeight - targetEl.offsetHeight;
    const direction = this.lastY - top < 0 ? 'up' : 'down';
    if (
      event.cancelable &&
      ((scrollTop <= 0 && direction === 'up') || (scrollTop >= maxScrollTop && direction === 'down'))
    )
      event.preventDefault();

    this.lastY = top;
  }

  private resetSchedulerPageSetup() {
    this.schedulerPageSetup = this.schedulerPageSetupDefault;
  }
}
