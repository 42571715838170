import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of, tap } from 'rxjs';
import { Security } from '@capital-access/common/security';
import { ListItem } from '@capital-access/lists/common';
import {
  ActivityHistoryFilterRequest,
  CommonFooterData,
  CommonFooterModel,
  ExportDataMap,
  ExportDataModel,
  FinancialHighlightsModel,
  ReportDataMap,
  ReportDataModel,
  ReportSection,
  ReportSectionConfigurationWithOrder,
  ReportType,
  ReportViewModelSection,
  SAMPLE_DATA_PROFILE_ID,
  TemplateExportListItemWithComments
} from '../..';

@Injectable()
export class ReportDataService {
  constructor(private http: HttpClient) {}

  private cachedSampleReportData: Partial<Record<ReportType, ReportDataMap>> = {};
  private cachedSampleExportData: Partial<Record<ReportType, ExportDataMap[]>> = {};

  private baseUrl = '/api/reporting';

  getReportData(
    reportSections: ReportSectionConfigurationWithOrder[],
    security: {
      id: number;
      surveillance: boolean;
    } | null,
    culture: string,
    language: string,
    currency: string | null,
    timeZone: string,
    defaultPeerListId: number,
    relatedSecurities?: Security[] | null,
    selectedInstitutionOrContact?: ListItem
  ) {
    if (!reportSections.length) {
      return of({} as ReportDataMap);
    }
    const securityId = security?.id;
    const dataSource = security?.surveillance ? 'Surveillance' : 'Public';
    const reportType = reportSections[0].config.appliedReportType;
    const combinedSecurityIds = relatedSecurities?.map(security => security?.id);

    const sections = reportSections
      .filter(
        item =>
          item.config.reportSection !== ReportSection.None &&
          item.isSelected &&
          reportType &&
          item.config.applicableReportTypes.includes(reportType)
      )
      .map(section => section.config.reportSection);
    if (selectedInstitutionOrContact) {
      const profileId = `${selectedInstitutionOrContact.prId ?? ''}|${selectedInstitutionOrContact.crmId ?? ''}`;

      const selectedListItem = selectedInstitutionOrContact as TemplateExportListItemWithComments;
      const activityHistoryFilters: ActivityHistoryFilterRequest[] = selectedListItem?.filterActivities
        ? [
            {
              profileId: profileId,
              companyContactIds: selectedListItem?.filterActivities?.companyContacts?.map(
                contact => contact?.id as string
              ),
              externalInstitutionIds: selectedListItem?.filterActivities?.externalParticipants
                ?.filter(item => item?.type == 'institutions')
                .map(institution => institution?.id as string),
              externalContactIds: selectedListItem?.filterActivities?.externalParticipants
                ?.filter(item => item?.type == 'contacts')
                .map(institution => institution?.id as string)
            }
          ]
        : [];

      return this.loadReportData(
        sections,
        reportType,
        profileId,
        false,
        securityId,
        dataSource,
        timeZone,
        currency,
        culture,
        language,
        defaultPeerListId,
        activityHistoryFilters,
        selectedInstitutionOrContact as TemplateExportListItemWithComments,
        combinedSecurityIds
      );
    } else {
      return this.loadReportData(
        sections,
        reportType,
        '',
        true,
        securityId,
        dataSource,
        timeZone,
        currency,
        culture,
        language,
        defaultPeerListId,
        [],
        undefined,
        []
      );
    }
  }

  getExportData(
    reportSections: ReportSectionConfigurationWithOrder[],
    security: {
      id: number;
      surveillance: boolean;
    } | null,
    culture: string,
    language: string,
    currency: string | null,
    timeZone: string,
    defaultPeerListId: number,
    relatedSecurities?: Security[] | null,
    selectedInstitutionsOrContacts?: ListItem[]
  ) {
    if (!reportSections.length) {
      return of({} as ReportDataMap);
    }
    const securityId = security?.id;
    const dataSource = security?.surveillance ? 'Surveillance' : 'Public';
    const reportType = reportSections[0].config.appliedReportType;
    const combinedSecurityIds = relatedSecurities?.map(security => security?.id);

    const sections = Array.from(
      new Set(
        reportSections
          .filter(
            item =>
              item.config.reportSection !== ReportSection.None &&
              item.isSelected &&
              reportType &&
              item.config.applicableReportTypes.includes(reportType)
          )
          .map(section => section.config.reportSection)
      )
    );

    if (selectedInstitutionsOrContacts && selectedInstitutionsOrContacts.length) {
      const profileIds = selectedInstitutionsOrContacts.map(
        institution => `${institution.prId ?? ''}|${institution.crmId ?? ''}`
      );

      const activityHistoryFilters: ActivityHistoryFilterRequest[] = selectedInstitutionsOrContacts
        .map(selectedInstitutionOrContact => {
          const selectedListItem = selectedInstitutionOrContact as TemplateExportListItemWithComments;
          return selectedListItem?.filterActivities
            ? {
                profileId: `${selectedInstitutionOrContact.prId ?? ''}|${selectedInstitutionOrContact.crmId ?? ''}`,
                companyContactIds: selectedListItem?.filterActivities?.companyContacts?.map(
                  contact => contact?.id as string
                ),
                externalInstitutionIds: selectedListItem?.filterActivities?.externalParticipants
                  ?.filter(item => item?.type == 'institutions')
                  .map(institution => institution?.id as string),
                externalContactIds: selectedListItem?.filterActivities?.externalParticipants
                  ?.filter(item => item?.type == 'contacts')
                  .map(institution => institution?.id as string)
              }
            : null;
        })
        .filter((filter): filter is ActivityHistoryFilterRequest => filter !== null);

      return this.loadExportData(
        sections,
        reportType,
        profileIds,
        false,
        securityId,
        dataSource,
        timeZone,
        currency,
        culture,
        language,
        defaultPeerListId,
        activityHistoryFilters,
        selectedInstitutionsOrContacts,
        combinedSecurityIds
      );
    } else {
      return this.loadExportData(
        sections,
        reportType,
        [''],
        true,
        securityId,
        dataSource,
        timeZone,
        currency,
        culture,
        language,
        defaultPeerListId,
        [],
        selectedInstitutionsOrContacts,
        []
      );
    }
  }

  private loadExportData(
    sectionsIn: ReportSection[],
    reportType: ReportType = ReportType.Institution,
    profileIdsIn: string[] = [''],
    pullSampleData = true,
    securityId: number = 115620,
    dataSource: string,
    timeZone: string,
    currency: string | null,
    culture: string,
    language: string,
    defaultPeerListId: number,
    activityHistoryFilters: ActivityHistoryFilterRequest[],
    selectedInstitutionsOrContacts?: ListItem[],
    combinedSecurityIds?: number[]
  ) {
    const profileIds = profileIdsIn.length ? profileIdsIn : [SAMPLE_DATA_PROFILE_ID];
    const sections = sectionsIn;
    const body = {
      profileIds,
      securityId,
      timeZone,
      reportType,
      dataSource,
      sections,
      currency,
      culture,
      language,
      sectionSettings: {
        EquityPortfolioTopBuysSellsGrid: '5',
        EquityPortfolioTopHoldingsGrid: '5'
      },
      pullSampleData,
      defaultPeerListId,
      activityHistoryFilters,
      combinedSecurityIds
    };

    if (profileIds.includes(SAMPLE_DATA_PROFILE_ID) && this.cachedSampleExportData[reportType]) {
      return of(this.cachedSampleExportData[reportType]);
    }

    return this.http.post<ExportDataModel[]>(`${this.baseUrl}/configurable-reporting/profiles/raw-data`, body).pipe(
      map(reportDataList => {
        const exportData: ExportDataMap[] = reportDataList.map(({ profileId, reportViewModels }) => {
          const reportData: ReportDataMap = reportViewModels.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
          }, {} as ReportDataMap);
          const selectItem = selectedInstitutionsOrContacts?.find(
            institution => `${institution.prId ?? ''}|${institution.crmId ?? ''}` === profileId
          ) as TemplateExportListItemWithComments;
          if (selectItem.commentTitle || selectItem.comment) {
            reportData[ReportViewModelSection.Commentary] = {
              title: selectItem.commentTitle,
              comment: selectItem.comment
            };
          }
          return { profileId, reportData$: of(reportData) };
        });
        return exportData;
      }),
      tap(exportDataArray => {
        if (profileIds.includes(SAMPLE_DATA_PROFILE_ID)) {
          this.cachedSampleExportData[reportType] = exportDataArray;
        }
      })
    );
  }

  private loadReportData(
    sectionsIn: ReportSection[],
    reportType: ReportType = ReportType.Institution,
    profileIdIn: string = '',
    pullSampleData = true,
    securityId: number = 115620,
    dataSource: string,
    timeZone: string,
    currency: string | null,
    culture: string,
    language: string,
    defaultPeerListId: number,
    activityHistoryFilters: ActivityHistoryFilterRequest[],
    selectedInstitutionOrContact?: TemplateExportListItemWithComments,
    combinedSecurityIds?: number[]
  ) {
    const profileId = profileIdIn !== '' ? profileIdIn : SAMPLE_DATA_PROFILE_ID;
    const sections = sectionsIn;
    const body = {
      profileId,
      securityId,
      timeZone,
      reportType,
      dataSource,
      sections,
      currency,
      culture,
      language,
      sectionSettings: {
        EquityPortfolioTopBuysSellsGrid: '3',
        EquityPortfolioTopHoldingsGrid: '8'
      },
      pullSampleData,
      activityHistoryFilters,
      defaultPeerListId,
      combinedSecurityIds
    };
    if (profileId === SAMPLE_DATA_PROFILE_ID && this.cachedSampleReportData[reportType]) {
      return of(this.cachedSampleReportData[reportType]);
    }

    return this.http.post<ReportDataModel[]>(`${this.baseUrl}/configurable-reporting/profile/raw-data`, body).pipe(
      map(reportDataList => {
        const reportData: ReportDataMap = reportDataList.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {} as ReportDataMap);
        if (!selectedInstitutionOrContact) {
          reportData[ReportViewModelSection.Commentary] = {
            title: 'Comments',
            comment: Array(20).fill('This is placeholder data. ').join('')
          };
        } else if (selectedInstitutionOrContact?.commentTitle || selectedInstitutionOrContact?.comment) {
          reportData[ReportViewModelSection.Commentary] = {
            title: selectedInstitutionOrContact.commentTitle,
            comment: selectedInstitutionOrContact.comment
          };
        }
        // check if activities data is present
        const footerData = reportData[ReportViewModelSection.Common_Footer] as CommonFooterModel;
        if (this.isActivityEmpty(reportData)) {
          footerData.calculatedEmptySectionLocalizationKeys = [
            ...(footerData.calculatedEmptySectionLocalizationKeys ?? []),
            'activities'
          ];
        }

        // check if ownership data is present
        if (this.isOwnershipEmpty(reportData)) {
          footerData.calculatedEmptySectionLocalizationKeys = [
            ...(footerData.calculatedEmptySectionLocalizationKeys ?? []),
            'ownership'
          ];
        }
        return reportData;
      }),
      tap(reportData => {
        if (profileId === SAMPLE_DATA_PROFILE_ID) {
          this.cachedSampleReportData[reportType] = reportData;
        }
      })
    );
  }

  private isActivityEmpty(reportData: Partial<Record<ReportViewModelSection, unknown>>): boolean {
    if (reportData.InvestorProfile_FinancialHighlights) {
      const activity = (reportData.InvestorProfile_FinancialHighlights as FinancialHighlightsModel)?.activity;
      if (
        activity == null ||
        (activity.lastActivityDate == null &&
          activity.primaryInstitutionActivitiesCount === 0 &&
          activity.primaryContactActivitiesCount === 0)
      ) {
        return true;
      }
    }
    return false;
  }

  private isOwnershipEmpty(reportData: Partial<Record<ReportViewModelSection, unknown>>): boolean {
    if (reportData.InvestorProfile_FinancialHighlights) {
      const ownership = (reportData.InvestorProfile_FinancialHighlights as FinancialHighlightsModel)?.ownership;
      if (ownership == null) {
        return true;
      }
    }
    return false;
  }
}
