import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';

@Component({
  selector: 'f-multiline-text-truncate',
  templateUrl: './multiline-text-truncate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultilineTextTruncateComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() title: string = '';
  @Input() rootCssClass = '';

  @Input() dataAutomationId: string = '';
  @Input() textLinesNumber: number = 2;

  @Input() expandable: boolean = false;

  showAll = false;
  truncatedTitle: string = '';
  localizationService = inject(FireflyLocalizationService, { optional: true });
  showMoreText$ = this.localizationService ? this.localizationService.localize('showMoreText', {}) : of('Show more');
  showLessText$ = this.localizationService ? this.localizationService.localize('showLessText', {}) : of('Show less');

  @ViewChild('titleContainer', { static: true })
  protected titleContainerRef!: ElementRef;

  @ContentChild('titleElement', { static: false })
  protected titleTemplateRef!: TemplateRef<unknown>;

  ngOnInit(): void {
    this.truncatedTitle = this.title ?? '';
  }

  ngAfterViewInit(): void {
    this.updateTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title && !changes.title.firstChange) {
      this.showAll = false;
      this.changeTitle(changes.title.currentValue);
    }
  }

  updateTitle(): void {
    this.zone.runOutsideAngular(() => {
      this.setVisibleHeight();
      if (this.isTitleOverflow) {
        this.truncateTitle();
      }
    });
  }

  changeTitle(text: string): void {
    this.title = text;
    this.setVisibleTitle(text);
    this.updateTitle();
  }

  onShowMoreToggle() {
    this.showAll = !this.showAll;
    this.changeTitle(this.title);
  }

  private setVisibleTitle(text: string): void {
    this.truncatedTitle = text;
    this.cd.detectChanges();
  }

  private setVisibleHeight(): void {
    const titleElements = this.titleContainerRef.nativeElement.children;
    if (this.showAll) {
      this.titleContainerRef.nativeElement.style.maxHeight = 'none';
    } else {
      if (!titleElements.length) return;
      const elementLineHeight = Array.from(titleElements).reduce<number>(
        (max, child) => Math.max(max, parseFloat(getComputedStyle(child as HTMLElement).lineHeight)),
        0
      );
      this.titleContainerRef.nativeElement.style.maxHeight = `${elementLineHeight * this.textLinesNumber}px`;
    }
  }

  private get isTitleOverflow(): boolean {
    return this.titleContainerRef.nativeElement?.clientHeight < this.titleContainerRef.nativeElement.scrollHeight;
  }

  private truncateTitle(): void {
    const ellipsis = '…';

    let maxChunk = '';
    let chunk: string;

    let low = 0;
    let mid: number;
    let high = this.title.length;

    // Binary Search
    while (low <= high) {
      mid = low + ((high - low) >> 1); // Integer division

      chunk = this.getTrimmedTitleByLength(mid + 1, ellipsis);
      this.setVisibleTitle(chunk);

      if (this.isTitleOverflow) {
        // too big, reduce the chunk
        high = mid - 1;
      } else {
        // chunk valid, try to get a bigger chunk
        low = mid + 1;
        maxChunk = maxChunk.length > chunk.length ? maxChunk : chunk;
      }
    }

    maxChunk = this.getTrimmedTitleByLength(maxChunk.length - ellipsis.length - 1, ellipsis);
    this.setVisibleTitle(maxChunk);
  }

  private getTrimmedTitleByLength(substringIndex: number, ellipsis: string): string {
    return this.trimRight(this.title.substring(0, substringIndex)) + ellipsis;
  }

  private trimRight(text: string): string {
    return text.replace(/\s*$/, '');
  }

  constructor(protected cd: ChangeDetectorRef, protected zone: NgZone) {}
}
