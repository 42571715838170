import { untracked } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { loadSecurities } from '../+state/actions';
import {
  getFailedSecurityIds,
  getLoadedOrLoadingSecurityIds,
  getSecuritiesByIds,
  getSecurityById
} from '../+state/selectors';
import { Security } from '../models/security';

interface FetchSecurityOptions<T> {
  onSuccess: (s: Security) => T;
  onFail: () => Observable<T>;
}

const fetchSecurityDefaultProcessOptions: FetchSecurityOptions<Security> = {
  onSuccess: s => s,
  onFail: () => EMPTY
};

export function fetchSecurity(store: Store, id: number): Observable<Security>;
export function fetchSecurity<T>(store: Store, id: number, processOptions: FetchSecurityOptions<T>): Observable<T>;
export function fetchSecurity<T>(store: Store, id: number, processOptions?: FetchSecurityOptions<T>) {
  const resolvedOptions = processOptions || fetchSecurityDefaultProcessOptions;
  return store.select(getLoadedOrLoadingSecurityIds).pipe(
    take(1),
    switchMap(availableIds => {
      if (!availableIds.includes(id)) {
        // NOTE: dispatch load in untracked context in case it's executed in template (pipe) or effect.
        // see https://github.com/ngrx/platform/issues/3892
        untracked(() => store.dispatch(loadSecurities({ ids: [id] })));
      }
      return combineLatest([
        store.select(getSecurityById(id)),
        store.select(getFailedSecurityIds).pipe(map(failedIds => failedIds.includes(id)))
      ]).pipe(
        filter(([security, failed]) => !!security || failed),
        switchMap(([security, failed]) => {
          if (failed) {
            return resolvedOptions.onFail();
          }
          return of(resolvedOptions.onSuccess(security!));
        }),
        catchError(_ => resolvedOptions.onFail())
      );
    })
  );
}

interface FetchSecuritiesOptions<T> {
  onSuccess: (s: Security[]) => T;
  onFail: (loadedSecurities: Security[], failedIds: number[]) => Observable<T>;
}

const fetchSecuritiesDefaultProcessOptions: FetchSecuritiesOptions<Security[]> = {
  onSuccess: s => s,
  onFail: () => EMPTY
};

export function fetchSecurities(store: Store, ids: number[]): Observable<Security[]>;
export function fetchSecurities<T>(
  store: Store,
  ids: number[],
  processOptions: FetchSecuritiesOptions<T>
): Observable<T>;
export function fetchSecurities<T>(store: Store, ids: number[], processOptions?: FetchSecuritiesOptions<T>) {
  const resolvedOptions = processOptions || fetchSecuritiesDefaultProcessOptions;
  return store.select(getLoadedOrLoadingSecurityIds).pipe(
    take(1),
    switchMap(availableIds => {
      const missingIds = ids.filter(id => !availableIds.includes(id));
      if (missingIds.length > 0) {
        // NOTE: dispatch load in untracked context in case it's executed in template (pipe) or effect.
        // see https://github.com/ngrx/platform/issues/3892
        untracked(() => store.dispatch(loadSecurities({ ids: missingIds })));
      }
      return combineLatest([
        store.select(getSecuritiesByIds(ids)).pipe(map(securities => securities.filter(x => !!x) as Security[])),
        store.select(getFailedSecurityIds).pipe(map(failedIds => failedIds.filter(id => ids.includes(id))))
      ]).pipe(
        filter(([securities, failedIds]) => {
          const finishedIds = securities.map(s => s.id).concat(failedIds);
          return ids.every(id => finishedIds.includes(id));
        }),
        switchMap(([securities, failedIds]) => {
          if (failedIds.length > 0) {
            return resolvedOptions.onFail(securities, failedIds);
          }
          return of(resolvedOptions.onSuccess(securities));
        }),
        catchError(_ => resolvedOptions.onFail([], ids))
      );
    })
  );
}
