import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';
import { BetaBadgeSize } from './model/beta-badge-size';
import { BetaBadgeType } from './model/beta-badge-type';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  selector: 'f-beta-badge',
  templateUrl: './beta-badge.component.html',
  standalone: true,
  imports: [NgClass, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyBetaBadgeComponent {
  @Input() size: BetaBadgeSize = BetaBadgeSize.Default;
  @Input() type: BetaBadgeType = BetaBadgeType.Default;
  private localizationService = inject(FireflyLocalizationService, {
    optional: true
  });
  title$ = this.localizationService ? this.localizationService.localize('beta', {}) : of('Beta');
}
