import { CommonModule } from '@angular/common';
import { ENVIRONMENT_INITIALIZER, Inject, inject, ModuleWithProviders, NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {
  provideTransloco,
  provideTranslocoScope,
  TRANSLOCO_SCOPE,
  TranslocoModule,
  TranslocoScope
} from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { fallbackLang } from '@capital-access/common/globalization';
import { provideChildRoutesGuard } from '@capital-access/common/router';
import { CommonLocalizationEffects } from './+store/localization.effects';
import { LocalizationScopeDirective } from './directive/localization-scope.directive';
import { LocalizePipe } from './pipes/localize.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { LocalizationLoadGuard } from './services/localization-load.guard';
import { LocalizationScopesLoadQueue } from './services/localization-scopes-load.queue';
import { TranslocoHttpLoader } from './transloco.loader';

export interface LocalizationConfig {
  enableProdMode: boolean;
}

@NgModule({
  imports: [TranslocoModule, EffectsModule.forFeature([CommonLocalizationEffects])]
})
export class CommonLocalizationRootModule {}

@NgModule({
  imports: [CommonModule, LocalizationScopeDirective, PluralizePipe, LocalizePipe],
  exports: [LocalizationScopeDirective, PluralizePipe, LocalizePipe]
})
export class CommonLocalizationModule {
  public static forRoot({ enableProdMode }: LocalizationConfig): ModuleWithProviders<CommonLocalizationRootModule> {
    return {
      ngModule: CommonLocalizationRootModule,
      providers: [
        provideTransloco({
          config: {
            defaultLang: fallbackLang, // explicitly specify default value instead of relying on default transloco config
            availableLangs: [fallbackLang], // Default lang should be available immediately in case if localization pipe will execute before actual language loaded
            reRenderOnLangChange: true,
            prodMode: enableProdMode,
            failedRetries: 0,
            scopes: {
              // do not convert scope names to camelCase
              keepCasing: true
            }
          },
          loader: TranslocoHttpLoader
        }),
        provideChildRoutesGuard(LocalizationLoadGuard)
      ]
    };
  }

  public static forFeature(scope: string): ModuleWithProviders<CommonLocalizationModule> {
    return {
      ngModule: CommonLocalizationModule,
      providers: [provideTranslocoScope(scope)]
    };
  }

  constructor(
    scopesLoadQueue: LocalizationScopesLoadQueue,
    @Inject(TRANSLOCO_SCOPE) scopes: TranslocoScope[] | TranslocoScope
  ) {
    // queue module localization scopes for loading with respect to lazy modules
    scopesLoadQueue.queue(scopes);
  }
}

// Provide localization scope for route
export function provideRouteLocalization(scope: string) {
  return [
    provideTranslocoScope(scope),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        const scopes = inject(TRANSLOCO_SCOPE, { optional: true });
        if (scopes) {
          const scopesLoadQueue = inject(LocalizationScopesLoadQueue);
          scopesLoadQueue.queue(scopes);
        }
      }
    }
  ];
}
