import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { FireflyCardRowService } from '../layouts/card-row/card-row.service';
import { Breakpoint } from '../utils';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';
import { DEBOUNCE_TIME, MIN_LENGTH } from './search.constants';

@Component({
  selector: 'f-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder: string = '';
  @Input() searchText: string = '';
  @Input() minLength: number = MIN_LENGTH;
  @Input() trimEnd = true;
  @Input() maxLength: number | null = null;
  @Output() criteriaChange = new EventEmitter<string>();

  search = new FormControl('');
  subscription = Subscription.EMPTY;
  clickSubscription: Subscription | null = null;
  clearTooltip$: Observable<unknown> = of('Clear');

  constructor(
    private host: ElementRef,
    private cdr: ChangeDetectorRef,
    private cardRowService: FireflyCardRowService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  @HostListener('click')
  onHostClick() {
    if (!this.clickSubscription && window.innerWidth < Breakpoint.Sm) this.handleOutClick();
  }

  handleOutClick() {
    this.clickSubscription = fromEvent(document, 'click')
      .pipe(debounceTime(100), withLatestFrom(this.cardRowService.searchOpenChange$))
      .subscribe(([$event, searchIsActive]) => {
        if (!searchIsActive) return;
        const contains = this.host.nativeElement.contains($event.target);
        if (!contains) {
          this.cardRowService.toggleSearchOpenState(false);
          this.clickSubscription?.unsubscribe();
          this.clickSubscription = null;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchText']) {
      this.search.setValue(this.searchText);
    }
  }

  ngOnInit() {
    const emptyLineRegex = /^ +$/;

    if (this.localizationService) {
      this.clearTooltip$ = this.localizationService.localize('clearTooltip', {});
    }

    this.subscription = this.search.valueChanges
      .pipe(
        startWith(''),
        pairwise(),
        debounceTime(DEBOUNCE_TIME),
        filter(([prev, curr]) => {
          return (
            (prev!.trim() && emptyLineRegex.test(curr!)) ||
            (!emptyLineRegex.test(curr!) && (!curr?.trim() || curr.trim().length >= this.minLength))
          );
        }),
        map(([, curr]) => (this.trimEnd ? curr!.trimEnd() : curr)),
        distinctUntilChanged((previous, current) => previous?.trimStart() === current?.trimStart())
      )
      .subscribe(value => {
        this.criteriaChange.emit(value!);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.clickSubscription?.unsubscribe();
  }

  clear() {
    this.search.setValue('');
  }

  onInputFocus(event: Event) {
    this.cardRowService.toggleSearchOpenState(true);
    requestAnimationFrame(() => this.moveCursorToEnd(event));
  }

  onInputFocusOut() {
    if (this.search.value) {
      const trimmedValue = this.trimEnd ? this.search.value!.trimEnd() : this.search.value;
      this.search.setValue(trimmedValue!.trim() ? trimmedValue : '', { emitEvent: false });
      this.cdr.detectChanges();
    }
  }

  moveCursorToEnd(event: Event) {
    const input = event.target as HTMLInputElement;
    input.selectionStart = input.selectionEnd = input.value.length;
  }
}
