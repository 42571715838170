import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { FireflyLocalizationService } from '../../utils';
import { ScaleChartLevel } from './scale-chart-level.enum';

@Component({
  selector: 'f-scale-chart',
  templateUrl: './scale-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyScaleChartComponent implements OnInit, OnDestroy {
  @Input() set pointerValue(val: number | ScaleChartLevel | null) {
    this._pointerValue = val ?? 0;
    this.isDataAvailable = val !== null;
  }
  @Input() showLearnMore = false;
  @Output() learnMore = new EventEmitter<void>();

  @ViewChild('barWrapper', { static: true }) barWrapper!: ElementRef;
  private _pointerValue: number | ScaleChartLevel = 0;
  isDataAvailable = false;

  readonly gapWidth = 4;
  learnMoreTitle = 'Learn more';
  noDataTitle = 'No Data';
  barTitleSet = ['Very Low', 'Low', 'Medium', 'High', 'Very High'];

  private localizationService = inject(FireflyLocalizationService, {
    optional: true
  });
  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    if (this.localizationService) {
      forkJoin([
        this.localizationService.localize('scaleChartNoData', {}),
        this.localizationService.localize('scaleChartVeryLow', {}),
        this.localizationService.localize('scaleChartLow', {}),
        this.localizationService.localize('scaleChartMedium', {}),
        this.localizationService.localize('scaleChartHigh', {}),
        this.localizationService.localize('scaleChartVeryHigh', {}),
        this.localizationService.localize('scaleChartLearnMore', {})
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(localizedTitles => {
          this.noDataTitle = localizedTitles[0];
          this.barTitleSet = localizedTitles.slice(1, -1);
          this.learnMoreTitle = localizedTitles[localizedTitles.length - 1];
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get barTitle(): string {
    return this.isDataAvailable ? this.barTitleSet[this.barIndex] : this.noDataTitle;
  }

  get barIndex(): number {
    if (typeof this._pointerValue === 'number') {
      return Math.trunc(((this._pointerValue - 1) * this.barTitleSet.length) / 100) || 0;
    }
    const scaleChartLevel = this.barTitleSet.indexOf(this._pointerValue as ScaleChartLevel);
    return scaleChartLevel === -1 ? 0 : scaleChartLevel;
  }

  get arrowPosition(): number {
    const parentDivWidth = this.barWrapper.nativeElement.offsetWidth;
    const barWidth = (parentDivWidth - this.gapWidth * (this.barTitleSet.length - 1)) / this.barTitleSet.length;

    return ((this.barIndex * barWidth + barWidth / 2 + this.barIndex * this.gapWidth) * 100) / parentDivWidth;
  }
}
