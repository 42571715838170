import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { combineLatest, map, Observable } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { ActionNavItem, EntityNavItem, LinkNavItem, SideNavigationMap, UserNavItem } from '../models/navigation-item';
import {
  ActionNavItemConfig,
  EntityNavItemConfig,
  LinkNavItemConfig,
  UserNavItemConfig
} from '../models/navigation-item-config';
import { NavigationConfigService } from './navigation-config.service';

@Injectable()
export class NavigationItemsService {
  constructor(private navigationConfigService: NavigationConfigService, private store: Store) {}

  getSideNavigationMap(): Observable<SideNavigationMap> {
    return combineLatest([
      this.navigationConfigService.sideMobileToggleNavigationConfig,
      this.navigationConfigService.sideTopNavigationConfig,
      this.navigationConfigService.sideMobileEntityNavigationConfig,
      this.navigationConfigService.sideMobileUserNavigationConfig,
      this.navigationConfigService.sideBottomNavigationConfig,
      this.store.select(getFeatureToggle('management-dashboard-lists-card-skyl-1963'))
    ]).pipe(
      map(([toggleNav, topNav, entityNav, userNav, bottomNav, isListManagementButtonMoved]) => {
        const filteredBottomNav = isListManagementButtonMoved
          ? bottomNav.filter(p => p.icon !== 'f-i-management')
          : bottomNav;
        const map = <SideNavigationMap>{
          mobileToggle: toggleNav,
          top: this.mapToLinkNav(topNav),
          mobileEntity: this.mapToEntityNav(entityNav),
          mobileUser: this.mapToUserNav(userNav),
          bottom: this.mapToActionNav(filteredBottomNav)
        };
        return map;
      })
    );
  }

  private mapToLinkNav(config: LinkNavItemConfig[]): LinkNavItem[] {
    return config.map(configItem => ({
      localizationKey: configItem.localizationKey,
      link: configItem.link,
      icon: configItem.icon || '',
      subItems: configItem.subItems,
      enabled: isNull(configItem.enabled) || isUndefined(configItem.enabled) ? true : configItem.enabled,
      badgeType: configItem.badgeType
    }));
  }

  private mapToEntityNav(config: EntityNavItemConfig[]): EntityNavItem[] {
    return config.map(configItem => ({
      localizationKey: configItem.localizationKey,
      icon: configItem.icon || '',
      action: configItem.action
    }));
  }

  private mapToUserNav(config: UserNavItemConfig[]): UserNavItem[] {
    return config.map(configItem => ({
      localizationKey: configItem.localizationKey,
      iconText: configItem.iconText || '',
      link: configItem.link,
      action: configItem.action
    }));
  }

  private mapToActionNav(config: ActionNavItemConfig[]): ActionNavItem[] {
    return config.map(configItem => ({
      localizationKey: configItem.localizationKey,
      action: configItem.action,
      icon: configItem.icon || '',
      featureToggleKey: configItem.featureToggleKey
    }));
  }
}
