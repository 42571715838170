import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import {
  FileInfo,
  FileSelectComponent,
  RemoveEvent,
  SelectEvent,
  UploadComponent
} from '@progress/kendo-angular-upload';
import { map, noop, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { NotificationService } from '../../alerts';
import { FireflyLocalizationService } from '../../utils';
import { convertFileSize, getFileIconCssClass } from '../../utils/file-uploader';

@Component({ template: '' })
export abstract class FireflyBaseUploaderComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() multiple = true;
  @Input() maxTotalSize = 0;
  @Input() maxFileSize = 0;
  @Input() allowedExtensions: string[] = [];
  @Input() caseInsensitiveValidation = false;

  abstract uploaderComponent(): UploadComponent | FileSelectComponent;

  title = '';
  uploaderDragDrop$ = of('Drag and drop to upload file or');
  uploaderBrowseFiles$ = of('Browse Files');
  uploaderStatusFailed$ = of('File failed to upload. Please try again');
  uploaderSingleFileError$ = of('Only one file can be uploaded for one import');

  protected el = inject(ElementRef);
  protected renderer = inject(Renderer2);
  protected isError = false;
  protected uploadedFiles = new Set<string>();
  private hasAlertNotified = false;
  private filesList?: HTMLElement;
  private sub = Subscription.EMPTY;
  private destroyed$ = new Subject<void>();
  private notificationService = inject(NotificationService);
  private localizationService = inject(FireflyLocalizationService, { optional: true });
  private cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.setUpLocalizedMessages();
  }

  ngAfterViewInit(): void {
    this.setTypeFiltrationForBrowsedFolder();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  select(event: SelectEvent): void {
    this.handleFileSelection(event);
    if (event.isDefaultPrevented()) return;
    this.hasAlertNotified = false;

    for (let i = event.files.length - 1; i >= 0; i--) {
      const file = event.files[i];

      if (this.fileHasInvalidExtension(file) || this.fileHasNoExtension(file)) {
        this.notifyInvalidExtension(file);
        event.files.splice(i, 1);
      }
      if (this.fileExceedsMaxSize(file)) {
        this.notifyFileTooLarge(file);
        event.files.splice(i, 1);
      }
    }

    this.markTouched();

    requestAnimationFrame(() => {
      this.filesList = this.el.nativeElement.querySelector('.k-upload-files');
      this.checkScrollable();
    });
  }

  success(): void {
    requestAnimationFrame(() => {
      this.checkScrollable();
    });
  }

  remove(event: RemoveEvent): void {
    this.removeFiles(event.files);
  }

  valueChange(files: File[] | FileInfo[]): void {
    this.emitValueChange(files);
  }

  writeValue: (files: File[] | FileInfo[] | undefined) => void = noop;

  registerOnChange(fn: (files: File[] | FileInfo[] | undefined) => void) {
    this.emitValueChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.markTouched = fn;
  }

  private emitValueChange: (files: File[] | FileInfo[] | undefined) => void = noop;
  private markTouched: () => void = noop;

  private setTypeFiltrationForBrowsedFolder(): void {
    const fileInput = this.uploaderComponent()?.wrapper.querySelector('input');
    fileInput?.setAttribute('accept', this.allowedExtensions.join(', '));
  }

  protected validationChecks(file: FileInfo): boolean {
    return (
      !this.fileHasInvalidExtension(file) &&
      !this.fileHasNoExtension(file) &&
      !this.fileExceedsMaxSize(file) &&
      !this.fileAlreadyUploaded(file)
    );
  }

  private fileHasInvalidExtension(file: FileInfo): boolean {
    return !!(file.extension && !(this.allowedExtensions.includes(file.extension) || !this.allowedExtensions.length));
  }

  private notifyInvalidExtension(file: FileInfo): void {
    this.setLocalizedStatusAndNotify(
      'uploaderStatusFormatNotSupported',
      { name: file.name, extension: file.extension },
      `${file.name} can't be uploaded because ${file.extension} format is not supported`,
      'warning'
    );
  }

  private fileHasNoExtension(file: FileInfo): boolean {
    return !file.extension;
  }

  private fileExceedsMaxSize(file: FileInfo): boolean {
    return !!(file.size && this.maxFileSize && file.size > this.maxFileSize);
  }

  private fileAlreadyUploaded(file: FileInfo): boolean {
    if (this.caseInsensitiveValidation && this.uploadedFiles) {
      const lowerCaseValue = file.name.toLowerCase();
      return Array.from(this.uploadedFiles).some(item => item.toLowerCase() === lowerCaseValue);
    }

    return this.uploadedFiles?.has(file.name);
  }

  private notifyFileTooLarge(file: FileInfo): void {
    this.setLocalizedStatusAndNotify(
      'uploaderStatusSizeExceeded',
      { name: file.name, size: this.convertFileSize(this.maxFileSize) },
      `${file.name} can't be uploaded because its size exceeds the maximum limit of ${this.convertFileSize(
        this.maxFileSize
      )}`,
      'warning'
    );
  }

  protected notifyTotalSizeTooLarge(): void {
    if (this.hasAlertNotified) return;

    this.setLocalizedStatusAndNotify(
      'uploaderStatusSizeExceeded',
      { size: this.convertFileSize(this.maxTotalSize) },
      `The total size of selected files exceeds maximum limit of ${this.convertFileSize(
        this.maxTotalSize
      )}. Please select fewer files.`,
      'warning'
    );
    this.hasAlertNotified = true;
  }

  protected removeFiles(files: FileInfo[]): void {
    requestAnimationFrame(() => this.checkScrollable());

    files.forEach(file => {
      if (this.uploadedFiles.has(file.name)) this.uploadedFiles.delete(file.name);
    });

    const uploaderComponent = this.uploaderComponent();

    if (!uploaderComponent.multiple) {
      uploaderComponent.wrapper.classList.remove('k-disabled');
      this.title = '';
    }
  }

  protected convertFileSize(size: number): string {
    return convertFileSize(size);
  }

  protected getFileIconCssClass(fileExtension: string): string {
    return getFileIconCssClass(fileExtension);
  }

  protected handleSingleFileUpload(): void {
    const uploaderComponent = this.uploaderComponent();
    if (uploaderComponent.fileList.filesFlat.length && !uploaderComponent.multiple) {
      uploaderComponent.wrapper.classList.add('k-disabled');
      this.sub.unsubscribe();
      this.sub = this.uploaderSingleFileError$
        .pipe(
          map(singleFileErrorTitle => {
            this.title = singleFileErrorTitle;
            this.cdr.detectChanges();
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
    }
  }

  private checkScrollable(): void {
    if (!this.filesList) return;

    if (this.filesList.scrollHeight > this.filesList.clientHeight) {
      this.renderer.addClass(this.filesList, 'pe-1');
    } else {
      this.renderer.removeClass(this.filesList, 'pe-1');
    }
  }

  private setUpLocalizedMessages(): void {
    if (this.localizationService) {
      this.uploaderDragDrop$ = this.localizationService.localize('uploaderDragDrop', {});
      this.uploaderBrowseFiles$ = this.localizationService.localize('uploaderBrowseFiles', {});
      this.uploaderStatusFailed$ = this.localizationService.localize('uploaderStatusFailed', {});
      this.uploaderSingleFileError$ = this.localizationService.localize('uploaderSingleFileError', {});
    }
  }

  private handleFileSelection(event: SelectEvent): void {
    const uploaderComponent = this.uploaderComponent();
    this.uploadedFiles.clear();
    uploaderComponent.fileList.filesFlat.forEach(uploadedFile => {
      this.uploadedFiles.add(uploadedFile.name);
    });

    event.files.forEach(file => {
      if (this.fileAlreadyUploaded(file)) {
        const key = this.isError ? 'uploaderStatusAlreadyUploadedAndFailed' : 'uploaderStatusAlreadyUploaded';
        const params = { name: file.name };
        const messages = {
          info: `${file.name} is already uploaded`,
          warning: `${file.name} is already uploaded and failed. Please click re-run or upload a new file.`
        };
        const messageType = this.isError ? 'warning' : 'info';

        this.setLocalizedStatusAndNotify(key, params, messages[messageType], messageType);
        event.preventDefault();
      }
    });
  }

  private setLocalizedStatusAndNotify(
    messageKey: string,
    messageVariables: { [key: string]: unknown },
    fallbackMessage: string,
    notificationType: 'info' | 'warning'
  ): void {
    let message$: Observable<string>;

    if (this.localizationService) {
      message$ = this.localizationService.localize(messageKey, messageVariables);
    } else {
      message$ = of(fallbackMessage);
    }

    this.sub.unsubscribe();
    this.sub = message$
      .pipe(
        tap(message => {
          this.notificationService.notify(message, { type: notificationType });
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }
}
