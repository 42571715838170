import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppSettingsService } from '@capital-access/common/settings';
import { Settings } from './oidc-settings.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  private redirectUrlKey = 'auth-redirect-url';
  private currentUriKey = 'current-uri';

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private ngZone: NgZone,
    private router: Router,
    private settingsService: AppSettingsService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Run authentication outside Angular to not trigger change detection for app by inner silent renew subscriptions.
    // Return pure observable as auth result
    const authResult = new ReplaySubject<boolean>(1);
    const settings = this.settingsService.getSettings<Settings>().oidc;
    if (!settings) {
      throw new Error(`Can not configure authentication. Oidc settings are not initialized.`);
    }

    const subscription = this.ngZone.runOutsideAngular(() => {
      return this.oidcSecurityService
        .checkAuthIncludingServer()
        .pipe(
          tap((response: LoginResponse) => {
            // This uri should be saved regardless of the authorization result as it is part of the routing for PR sites
            sessionStorage.setItem(this.currentUriKey, window.location.pathname);
            if (!response?.isAuthenticated) {
              // We should save current url to know where to navigate user after login or silent-sign-in.
              sessionStorage.setItem(this.redirectUrlKey, state.url);

              const customAuthParams: { [key: string]: string } = {};
              const customParamsArray: string[] = settings.customParams.split(' ');
              for (let i = 0; i < customParamsArray.length; i++) {
                // this implementation only supports query params
                const value = _route.queryParamMap.get(customParamsArray[i]);
                if (value) {
                  customAuthParams[customParamsArray[i]] = value;
                }
              }
              this.oidcSecurityService.authorize(undefined, { customParams: customAuthParams });
            }
          }),
          map((response: LoginResponse) => {
            const redirectUrl = sessionStorage.getItem(this.redirectUrlKey);

            // If redirectUrl is still present it means that we load app straight after login or silent-sign-in and should navigate user to where he requested.
            if (response?.isAuthenticated && redirectUrl) {
              sessionStorage.removeItem(this.redirectUrlKey);
              this.router.navigateByUrl(redirectUrl);
            }
            return response?.isAuthenticated || false;
          })
        )
        .subscribe(authResult);
    });

    return authResult.pipe(
      tap(() => {
        if (!subscription.closed) {
          subscription.unsubscribe();
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }
}
