import { SortDirection } from '@capital-access/common/utils';
import { HistoricalOwnershipReportFilters } from '@capital-access/reporting/common';
import { BaseReportRequest } from '../../core/models/report-request.models';
import { ReportSection } from '../../core/models/report-sections';

export interface CreateHistoricalOwnershipRequest extends BaseReportRequest {
  sections: ReportSection[];
  reportedQuarters: string[];
  filters?: HistoricalOwnershipReportFilters;
  includeTables: boolean;
  sortDirection?: SortDirection;
}

export class CreateHistoricalOwnershipDto {
  reportedQuarters: string[];
  filters?: HistoricalOwnershipReportFilters;
  sortDirection?: SortDirection;

  constructor(reportedQuarters: string[], filters?: HistoricalOwnershipReportFilters, sortDirection?: SortDirection) {
    this.reportedQuarters = reportedQuarters;
    this.filters = filters;
    this.sortDirection = sortDirection;
  }

  static create(
    reportedQuarters: string[],
    filters?: HistoricalOwnershipReportFilters,
    sortDirection?: SortDirection
  ): CreateHistoricalOwnershipDto {
    return new CreateHistoricalOwnershipDto(reportedQuarters, filters, sortDirection);
  }
}
