import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@capital-access/common/utils';
import { HistoricalOwnershipReportFilters } from '../models/historical-ownership-filters.model';
import { TemplateExportListItemWithComments } from '../models/template-designer-state.model';

export const openHistoricalOwnershipReportDrawer = createAction(
  scoped`Open Historical Ownership Report Drawer`,
  props<{
    reportedQuarters: string[];
    filters?: HistoricalOwnershipReportFilters;
    sortDirection: SortDirection;
  }>()
);

export const createHistoricalOwnershipReportFinished = createAction(
  scoped`Create Historical Ownership report Finished`
);

export const loadSelectedItemsFromStorage = createAction(
  scoped`Load Selected Items From Storage`,
  props<{ selectedItems: TemplateExportListItemWithComments[] }>()
);

export const setZoomValue = createAction(scoped`Set Zoom Value`, props<{ zoom: number; savePrevious?: boolean }>());

export const resetZoomValue = createAction(scoped`Reset Zoom Value`);

function scoped(templateString: TemplateStringsArray) {
  return '[Reporting Common] ' + templateString[0];
}
