export function convertFileSize(size: number): string {
  const megabyteInBytes = 1048576;
  const kilobyteInBytes = 1024;

  return size > megabyteInBytes
    ? (size / megabyteInBytes).toFixed(2) + ' MB'
    : (size / kilobyteInBytes).toFixed(2) + ' KB';
}

export function getFileIconCssClass(fileExtension: string): string {
  switch (fileExtension) {
    case '.ppt':
    case '.pptx':
      return 'f-i-powerpoint';
    case '.doc':
    case '.docx':
      return 'f-i-word';
    case '.xls':
    case '.xlsx':
      return 'f-i-excel';
    case '.pdf':
      return 'f-i-pdf';
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
    case '.svg':
      return 'f-i-file-image';
    default:
      return 'f-i-file-general';
  }
}
