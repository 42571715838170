import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { CommonFormattingModule } from '@capital-access/common/formatting';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import {
  FireflyAlertsModule,
  FireflyChipModule,
  FireflyDrawerModule,
  FireflyListModule
} from '@capital-access/firefly/components';
import { ModifyReportDrawerBodyComponent } from './components/report-drawer/modify-report-drawer-body.component';
import { ModifyReportDrawerFooterComponent } from './components/report-drawer/modify-report-drawer-footer.component';
import { SectionsSelectorComponent } from './components/sections-selector/sections-selector.component';
import { SetAsDefaultToggleComponent } from './components/set-as-default-toggle/set-as-default-toggle.component';
import { LOCALIZATION_KEY } from './constants';
import { CreateReportEffects } from './core/+state/report.effects';
import { CreateReportService } from './core/services/create-report.service';
import { FileNameService } from './core/services/file-name.service';
import { HistoricalOwnershipDrawerEffects } from './historical-ownership/+state/historical-ownership-drawer.effects';
import { HistoricalOwnershipReportEffects } from './historical-ownership/+state/historical-ownership-report.effects';
import { HistoricalOwnershipReportDrawerComponent } from './historical-ownership/components/historical-ownership-report-drawer/historical-ownership-report-drawer.component';
import { ItineraryDrawerEffects } from './itinerary/+state/itinerary-drawer.effects';
import { ItineraryReportEffects } from './itinerary/+state/itinerary-report.effects';
import { ItineraryReportDrawerComponent } from './itinerary/components/itinerary-report-drawer/itinerary-report-drawer.component';
import { ItineraryReportSectionsComponent } from './itinerary/components/itinerary-report-sections/itinerary-report-sections.component';
import { ProfileReportDrawerEffects } from './profile/+state/profile-drawer.effects';
import { ProfileReportEffects } from './profile/+state/profile-report.effects';
import { ProfileReportDrawerComponent } from './profile/components/profile-report-drawer/profile-report-drawer.component';
import { ProfileReportNamingService } from './profile/services/profile-report-naming.service';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule.forFeature(LOCALIZATION_KEY),
    CommonFormattingModule,
    EffectsModule.forFeature([
      ProfileReportEffects,
      ProfileReportDrawerEffects,
      ItineraryReportEffects,
      HistoricalOwnershipReportEffects,
      HistoricalOwnershipDrawerEffects,
      CreateReportEffects,
      ItineraryDrawerEffects
    ]),
    FireflyListModule,
    FireflyDrawerModule,
    ReactiveFormsModule,
    LetDirective,
    FireflyChipModule,
    FireflyAlertsModule
  ],
  providers: [CreateReportService, ProfileReportNamingService, FileNameService],
  declarations: [
    SectionsSelectorComponent,
    ModifyReportDrawerBodyComponent,
    HistoricalOwnershipReportDrawerComponent,
    ModifyReportDrawerFooterComponent,
    SetAsDefaultToggleComponent,
    ProfileReportDrawerComponent,
    ItineraryReportDrawerComponent,
    ItineraryReportSectionsComponent
  ]
})
export class CreateReportModule {}
