import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[fEnterToBlur]'
})
export class EnterToBlurDirective {
  private el = inject(ElementRef);

  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    event.preventDefault(); // Prevent default Enter key action (in this case - file remove)
    this.el.nativeElement.blur(); // Trigger blur event on the element
  }
}
