import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Alert, FireflyDrawerService } from '@capital-access/firefly/components';
import { BaseReportDrawer } from '../../../components/report-drawer/base-report-drawer';
import { ReportSection, SectionSelectorListItem } from '../../../core/models/report-sections';
import { getSelectedSectionsFromGroups } from '../../../core/utils/section-settings.utils';
import { createItineraryCustomReport } from '../../+state/itinerary-report.actions';
import { SETTINGS_KEY } from '../../constants';
import { GroupedSectionSelectorListItem } from '../../models/itinerary-groupped-section-item';
import { CreateItineraryReportDto } from '../../models/itinerary-report.models';
import {
  DEFAULT_DISABLED_TOOLTIP,
  getSectionCustomDisabledTooltip,
  SECTIONS_WEIGHT_LIMIT
} from '../../models/itinerary-report-sections';

@Component({
  templateUrl: './itinerary-report-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItineraryReportDrawerComponent extends BaseReportDrawer<
  CreateItineraryReportDto,
  GroupedSectionSelectorListItem[]
> {
  totalLimitReached: boolean = false;
  alertType = Alert.Info;

  ngOnInit() {
    const sections = this.form.controls.sections.value;
    const weightSum = this.calculateWeightSum(sections);
    this.setTotalLimitReached(weightSum);
  }

  override getSections(filteredSections: GroupedSectionSelectorListItem[]): SectionSelectorListItem[] {
    return filteredSections;
  }

  get settingsKey(): string {
    return SETTINGS_KEY;
  }

  get createReportAction() {
    const sections = getSelectedSectionsFromGroups(this.form.controls.sections.value);
    return createItineraryCustomReport({
      eventId: this.request!.eventId,
      eventName: this.request!.eventName,
      sections
    });
  }

  override onInjectInputs(inputs: {
    request?: CreateItineraryReportDto;
    filteredSections: GroupedSectionSelectorListItem[];
  }) {
    super.onInjectInputs(inputs);
  }

  selectionChange(sections: GroupedSectionSelectorListItem[]) {
    super.settingsChange(sections);
    const weightSum = this.calculateWeightSum(sections);
    this.setTotalLimitReached(weightSum);
    this.form.controls.sections.setValue(this.setToggleDisabled(sections, weightSum), { emitEvent: true });
  }

  private calculateWeightSum(sections: GroupedSectionSelectorListItem[]): number {
    const allSections = sections.flatMap((s: GroupedSectionSelectorListItem) => s.nestedItems || [s]);
    return allSections
      .filter((s: GroupedSectionSelectorListItem) => s.isSelected)
      .reduce((sum: number, s: GroupedSectionSelectorListItem) => sum + (s.weight || 0), 0);
  }

  private isTotalLimitReached(sumWeight: number): boolean {
    return sumWeight === SECTIONS_WEIGHT_LIMIT;
  }

  private setTotalLimitReached(weightSum: number) {
    this.totalLimitReached = this.isTotalLimitReached(weightSum);
  }

  private setToggleDisabled(
    sections: GroupedSectionSelectorListItem[],
    sumWeight: number
  ): GroupedSectionSelectorListItem[] {
    return sections.map((s: GroupedSectionSelectorListItem) => {
      const isToggleDisabled =
        !s.weight || s.isSelected ? s.isToggleDisabled : sumWeight + s.weight > SECTIONS_WEIGHT_LIMIT;

      return {
        ...s,
        isToggleDisabled,
        nestedItems: s.nestedItems ? this.setToggleDisabled(s.nestedItems, sumWeight) : undefined,
        disabledTooltip: this.setDisabledTooltip(s.id, this.totalLimitReached, !!isToggleDisabled)
      };
    });
  }

  private setDisabledTooltip(id: ReportSection, totalLimitReached: boolean, disabled: boolean) {
    if (!disabled) {
      return;
    }
    if (totalLimitReached) {
      return DEFAULT_DISABLED_TOOLTIP;
    }

    return getSectionCustomDisabledTooltip(id);
  }

  constructor(store: Store, drawerService: FireflyDrawerService) {
    super(store, drawerService);
  }
}
