import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FireflyFroalaLicenseProvider } from '@capital-access/firefly/components';
import { FroalaLicenseProvider } from './froala-license.provider';

@NgModule({
  imports: [CommonModule]
})
export class FroalaModule {
  static forRoot(): ModuleWithProviders<FroalaModule> {
    return {
      ngModule: FroalaModule,
      providers: [
        {
          provide: FireflyFroalaLicenseProvider,
          useClass: FroalaLicenseProvider
        },
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: (licenseService: FireflyFroalaLicenseProvider) => () => licenseService.validateLicense(),
          deps: [FireflyFroalaLicenseProvider]
        }
      ]
    };
  }
}
