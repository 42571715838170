import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getUserDataLoaded } from '@capital-access/common/auth';
import { Log } from '@capital-access/common/logging';
import { when } from '@capital-access/common/utils';
import { FireflyFroalaLicenseProvider } from '@capital-access/firefly/components';

@Injectable({ providedIn: 'root' })
export class FroalaLicenseProvider implements FireflyFroalaLicenseProvider {
  private url = '/api/email/editor-license';
  private http = inject(HttpClient, { optional: true });
  private store = inject(Store);
  private license = '';

  validateLicense(): Subscription | undefined {
    return this.http
      ?.get<string>(this.url)
      .pipe(when(this.store.select(getUserDataLoaded)))
      .subscribe({
        next: license => (this.license = license),
        error: () => Log.warn('Froala Editor License activation failed')
      });
  }

  getLicense(): string {
    return this.license;
  }
}
