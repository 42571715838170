import { SECTIONS_LAYOUT_KEY, SECTIONS_SETTINGS_KEY } from '../../constants';
import { GroupedSectionSelectorListItem } from '../../itinerary/models/itinerary-groupped-section-item';
import { ProfileReportLayout } from '../../profile/models/profile-report-sections';
import { ConditionalReportSections, ReportSection, SectionSelectorListItem } from '../models/report-sections';

export const getSavedSections = (
  key: string,
  defaultSections: SectionSelectorListItem[],
  conditionalSections?: ConditionalReportSections
) => {
  const sectionsJson = localStorage.getItem(`${key.toLocaleUpperCase()}_${SECTIONS_SETTINGS_KEY}`);

  if (sectionsJson) {
    const savedSections = JSON.parse(sectionsJson) as SectionSelectorListItem[];
    return getSectionListsFromSaved(savedSections, defaultSections, conditionalSections);
  }
  return getSectionListsFromDefault(defaultSections, conditionalSections);
};

export const getGroupedSavedSections = (
  key: string,
  defaultSections: GroupedSectionSelectorListItem[]
): GroupedSectionSelectorListItem[] => {
  const sectionsJson = localStorage.getItem(`${key.toLocaleUpperCase()}_${SECTIONS_SETTINGS_KEY}`);

  if (sectionsJson) {
    const savedSections = JSON.parse(sectionsJson) as GroupedSectionSelectorListItem[];
    return getGroupedSectionListFromSaved(savedSections, defaultSections);
  }
  return getSectionListsFromDefault(defaultSections);
};

export const getSelectedSectionsFromGroups = (selectedSections: GroupedSectionSelectorListItem[]): ReportSection[] => {
  return getSectionsIncludeNested(selectedSections)
    .filter(section => section.isSelected)
    .map(section => section.id);
};

export const getSavedSelectedSectionsFromGroups = (
  key: string,
  defaultSections: GroupedSectionSelectorListItem[]
): ReportSection[] => {
  const savedSections = getGroupedSavedSections(key, defaultSections);
  return getSelectedSectionsFromGroups(savedSections);
};

export const getSavedLayout = (key: string, defaultLayout: ProfileReportLayout): ProfileReportLayout | undefined => {
  const layout = localStorage.getItem(`${key.toLocaleUpperCase()}_${SECTIONS_LAYOUT_KEY}`);

  if (layout) {
    return layout as ProfileReportLayout;
  }

  return defaultLayout;
};

function getGroupedSectionListFromSaved(
  savedSections: GroupedSectionSelectorListItem[],
  defaultSections: GroupedSectionSelectorListItem[]
) {
  const defaultSectionsSet = getNestedSectionIds(defaultSections);
  const savedSectionsSet = getNestedSectionIds(savedSections);

  if (isSectionsEqual(defaultSectionsSet, savedSectionsSet)) {
    return savedSections;
  }

  return defaultSections.map(section => {
    const savedSection = savedSections.find(s => s.id === section.id);
    return {
      ...section,
      isSelected: savedSection?.isSelected ?? section.isSelected,
      nestedItems: section.nestedItems
        ? section.nestedItems.map(ns => {
            const savedNestedSection = savedSection?.nestedItems?.find(s => s.id === ns.id);
            return { ...ns, isSelected: savedNestedSection?.isSelected ?? ns.isSelected };
          })
        : section.nestedItems
    };
  });
}

function getNestedSectionIds(sections: GroupedSectionSelectorListItem[]) {
  return getSectionsIncludeNested(sections).map(s => s.id);
}

function isSectionsEqual(defaultSections: ReportSection[], savedSections: ReportSection[]): boolean {
  if (defaultSections.length !== savedSections.length) return false;

  return defaultSections.every(section => savedSections.includes(section));
}

function getSectionsIncludeNested(sections: GroupedSectionSelectorListItem[]) {
  return sections.flatMap(s => (!s.nestedItems ? [s] : s.nestedItems));
}

function getSectionListsFromSaved(
  savedSections: SectionSelectorListItem[],
  defaultSections: SectionSelectorListItem[],
  conditionalSections?: ConditionalReportSections
) {
  if (!conditionalSections) return savedSections;
  const fullSectionList = includeUnsavedNewSections(savedSections, defaultSections, conditionalSections);
  return filterHiddenSections(fullSectionList, conditionalSections);
}

function getSectionListsFromDefault<T extends SectionSelectorListItem>(
  defaultSections: T[],
  conditionalSections?: ConditionalReportSections
) {
  if (!conditionalSections) return defaultSections;
  return filterHiddenSections(defaultSections, conditionalSections);
}

function includeUnsavedNewSections(
  savedSections: SectionSelectorListItem[],
  defaultSections: SectionSelectorListItem[],
  conditionalSections: ConditionalReportSections
) {
  for (const sectionKey in conditionalSections) {
    const isSectionInList = savedSections.some(section => section.id === sectionKey);

    if (!isSectionInList && !!conditionalSections[sectionKey as ReportSection]) {
      const sectionToAdd = defaultSections.find(section => section.id === sectionKey);
      if (sectionToAdd) {
        savedSections.push({ ...sectionToAdd!, isSelected: !!sectionToAdd?.isCommonForAllSections });
      }
    }
  }

  return savedSections;
}

function filterHiddenSections<T extends SectionSelectorListItem>(
  savedSections: T[],
  conditionalSections: ConditionalReportSections
) {
  const sectionsToFilter: ReportSection[] = [];

  for (const sectionKey in conditionalSections) {
    if (!conditionalSections[sectionKey as ReportSection]) {
      sectionsToFilter.push(sectionKey as ReportSection);
    }
  }

  if (sectionsToFilter.length) {
    savedSections = savedSections.filter(section => !sectionsToFilter.includes(section.id));
  }

  return savedSections;
}
