import { createAction, props } from '@ngrx/store';
import { ReportSection } from '../../core/models/report-sections';

export const createItineraryCustomReport = createAction(
  '[Reporting] Create Custom Itinerary Report',
  props<{
    eventId: string;
    eventName: string;
    sections?: ReportSection[];
  }>()
);
