import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReportTemplate } from '../models/report-template.model';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateNotifierService {
  private eventSubject = new Subject<Partial<ReportTemplate>>();

  event$ = this.eventSubject.asObservable();

  notify(template: Partial<ReportTemplate>) {
    this.eventSubject.next(template);
  }
}
