import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  EventEmitter,
  forwardRef,
  inject,
  input,
  Output,
  ViewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { FileInfo, FileSelectComponent, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { of } from 'rxjs';
import { FireflyHashMap } from '../../utils/localization/firefly-localization.model';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';
import { FireflyBaseUploaderComponent } from '../base-uploader/base-uploader.component';
import { FireflyExtendedFileSelectTemplateDirective } from '../directives/extended-file-select-template.directive';

@Component({
  selector: 'f-file-select',
  templateUrl: './file-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FireflyFileSelectComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FireflyFileSelectComponent),
      multi: true
    }
  ]
})
export class FireflyFileSelectComponent extends FireflyBaseUploaderComponent {
  invalidFiles = input<string[]>([]);

  @ViewChild(FileSelectComponent) fileselect!: FileSelectComponent;
  @ContentChild(FireflyExtendedFileSelectTemplateDirective) customTemplate?: FireflyExtendedFileSelectTemplateDirective;

  @Output() filesSelected = new EventEmitter<FileInfo[]>();
  @Output() filesRemoved = new EventEmitter<FileInfo[]>();

  readonly localization = toSignal(
    inject(FireflyLocalizationService, { optional: true })?.getLocalization() ?? of({} as FireflyHashMap),
    { initialValue: {} as FireflyHashMap }
  );

  readonly removeTitle = computed(() => this.localization()['removeTitle'] || 'Remove');

  private totalSize = 0;

  validate(): ValidationErrors | null {
    const hasError = Array.from(this.uploadedFiles).some(item => this.isFileNameInvalid(item));

    return hasError ? { hasValidationError: true } : null;
  }

  isFileInvalid(file: FileInfo): boolean {
    return this.isFileNameInvalid(file.name);
  }

  uploaderComponent(): FileSelectComponent {
    return this.fileselect;
  }

  select(event: SelectEvent): void {
    if (!event.files) return;
    super.select(event);

    if (event.isDefaultPrevented()) {
      requestAnimationFrame(() => this.handleSingleFileUpload());
      this.filesSelected.emit([]);
      return;
    }

    for (let i = event.files.length - 1; i >= 0; i--) {
      const file = event.files[i];
      if (this.totalFileSizeTooLarge(file)) {
        this.notifyTotalSizeTooLarge();
        event.files.splice(i, 1);
      }
    }

    requestAnimationFrame(() => this.handleSingleFileUpload());
    this.filesSelected.emit(event.files);
  }

  remove(event: RemoveEvent): void {
    super.remove(event);
    event.files.forEach(file => {
      if (file.size) this.totalSize -= file.size;
    });
    this.filesRemoved.emit(event.files);
  }

  removeItem(fileToRemove: FileInfo): void {
    this.removeFiles([fileToRemove]);
    if (fileToRemove.size) this.totalSize -= fileToRemove.size;
    this.fileselect.removeFileByUid(fileToRemove.uid!);

    this.filesRemoved.emit([fileToRemove]);
  }

  private totalFileSizeTooLarge(file: FileInfo): boolean {
    let accumulatedFileSize = 0;
    if (file.size && this.maxTotalSize && this.validationChecks(file)) {
      this.totalSize += file.size;
      accumulatedFileSize += this.totalSize;
    }
    if (file.size && this.totalSize > this.maxTotalSize) this.totalSize -= file.size;
    return accumulatedFileSize > this.maxTotalSize;
  }

  private isFileNameInvalid(fileName: string): boolean {
    if (this.caseInsensitiveValidation) {
      const lowerCaseValue = fileName.toLowerCase();
      return Array.from(this.invalidFiles()).some(item => item.toLowerCase() === lowerCaseValue);
    }
    return this.invalidFiles().some(x => x === fileName);
  }
}
