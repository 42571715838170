<ng-container [ngSwitch]="flow$ | async">
  <ng-container *ngSwitchCase="'anonymous'">
    @if (isLandscapeOrientation) {
    <f-mobile-inner-error [errorOption]="mobileInnerErrorOptions.LandscapeMode"></f-mobile-inner-error>
    }
    <ca-navigation-anonymous [class.d-none]="isLandscapeOrientation">
      <router-outlet></router-outlet>
    </ca-navigation-anonymous>
  </ng-container>

  <ng-container *ngSwitchCase="'authorized'">
    @if (isLandscapeOrientation) {
    <f-mobile-inner-error [errorOption]="mobileInnerErrorOptions.LandscapeMode"></f-mobile-inner-error>
    }
    <ca-navigation [class.d-none]="isLandscapeOrientation">
      <ca-one-search class="f-drawer-outclick-element" caTopNavSearch></ca-one-search>
      <router-outlet></router-outlet>
      <ca-ai-chat-button></ca-ai-chat-button>
    </ca-navigation>
  </ng-container>
  <div *ngSwitchDefault class="d-flex justify-content-center align-items-center h-100">
    <f-spinner rootCssClass="spinner-center spinner-theme-agnostic"></f-spinner>
  </div>
</ng-container>
