import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { provideNavConfig } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { REPORTING_PATH } from './constants';
import { ReportingNavConfigProvider } from './reporting-nav-config.provider';
import { CanAccessReportingGuard } from './reporting-navigation-guard';

export const loadModule = () => import('@capital-access/reporting/client').then(m => m.ReportingClientModule);

export interface ReportingConfig {
  isCaTemplate: boolean;
  featureToggle: string;
}

// @dynamic
@NgModule({
  imports: [
    CommonModule,
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: REPORTING_PATH,
          canActivate: [CanAccessReportingGuard],
          canActivateChild: [CanAccessReportingGuard],
          loadChildren: loadModule
        }
      ]
    })
  ],
  providers: [provideNavConfig(ReportingNavConfigProvider), CanAccessReportingGuard]
})
export class ReportingClientLazyModule {
  static forRoot(config: ReportingConfig): ModuleWithProviders<ReportingClientLazyModule> {
    return {
      ngModule: ReportingClientLazyModule,
      providers: [
        { provide: 'IS_CA_TEMPLATE', useValue: config.isCaTemplate },
        {
          provide: 'IS_REPORTING_ENABLED',
          useValue: config.featureToggle
        }
      ]
    };
  }
}
