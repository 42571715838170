import { ReportSection } from '../../core/models/report-sections';
import { GroupedSectionSelectorListItem } from './itinerary-groupped-section-item';

const DEFAULT_ITINERARY_SECTIONS: GroupedSectionSelectorListItem[] = [
  {
    id: ReportSection.Institution,
    isToggleDisabled: true,
    isSelected: true,
    isFixed: true,
    disabled: true,
    isRequired: true,
    localizationKey: 'institution',
    weight: 2.5
  },
  {
    id: ReportSection.InstitutionType,
    isSelected: true,
    isFixed: true,
    disabled: true,
    localizationKey: 'institutionType'
  },
  {
    id: ReportSection.ExternalParticipants,
    isToggleDisabled: true,
    isSelected: true,
    isFixed: true,
    disabled: true,
    isRequired: true,
    localizationKey: 'externalParticipants',
    weight: 2.5
  },
  {
    id: ReportSection.ExternalParticipantJobRole,
    isSelected: true,
    isFixed: true,
    disabled: true,
    localizationKey: 'externalParticipantJobRole'
  },
  {
    id: ReportSection.Ownership,
    localizationKey: 'ownershipSection',
    isSelected: false,
    isFixed: false,
    nestedItems: [
      {
        id: ReportSection.SharesReportedDate,
        isSelected: true,
        localizationKey: 'sharesReportedDate',
        weight: 1
      },
      {
        id: ReportSection.SharesChange,
        isSelected: true,
        localizationKey: 'shareChange',
        weight: 1
      },
      {
        id: ReportSection.HolderRank,
        isSelected: false,
        localizationKey: 'holderRank',
        weight: 1
      },
      {
        id: ReportSection.OwnershipValue,
        isSelected: false,
        localizationKey: 'ownershipValue',
        hasCurrencySymbol: true,
        weight: 1
      },
      {
        id: ReportSection.ShareChangeValue,
        isSelected: false,
        localizationKey: 'shareChangeValue',
        hasCurrencySymbol: true,
        weight: 1
      },
      {
        id: ReportSection.SharesOutstandingPercentage,
        isSelected: false,
        localizationKey: 'sharesOutstandingPercentage',
        weight: 1
      },
      {
        id: ReportSection.PortfolioPercentage,
        isSelected: false,
        localizationKey: 'portfolioPercentage',
        weight: 1
      }
    ]
  },
  {
    id: ReportSection.InstitutionDetails,
    localizationKey: 'institutionDetailsSection',
    isSelected: true,
    nestedItems: [
      {
        id: ReportSection.Orientation,
        isSelected: false,
        localizationKey: 'orientation',
        weight: 1
      },
      {
        id: ReportSection.Style,
        isSelected: false,
        localizationKey: 'styleSection',
        weight: 1
      },
      {
        id: ReportSection.Turnover,
        isSelected: false,
        localizationKey: 'turnover',
        weight: 1
      },
      {
        id: ReportSection.EquityAssets,
        isSelected: true,
        localizationKey: 'equityAssets',
        hasCurrencySymbol: true,
        weight: 1
      },
      {
        id: ReportSection.City,
        isSelected: false,
        localizationKey: 'city',
        weight: 1
      },
      {
        id: ReportSection.Country,
        isSelected: false,
        localizationKey: 'country',
        weight: 1
      }
    ]
  },
  {
    id: ReportSection.Targeting,
    localizationKey: 'targeting',
    isSelected: false,
    isFixed: false,
    nestedItems: [
      {
        id: ReportSection.InstitutionRating,
        isSelected: true,
        localizationKey: 'institutionRating',
        weight: 1
      },
      {
        id: ReportSection.SuitabilityScore,
        isSelected: true,
        localizationKey: 'suitabilityScore',
        weight: 1
      },
      {
        id: ReportSection.PurchasingPower,
        isSelected: true,
        localizationKey: 'purchasingPower',
        weight: 1
      },
      {
        id: ReportSection.BestFund,
        isSelected: false,
        localizationKey: 'bestFund',
        weight: 2
      }
    ]
  },
  {
    id: ReportSection.InteractionWithInstitution,
    localizationKey: 'interactionWithInstitution',
    isSelected: false,
    isFixed: false,
    nestedItems: [
      {
        id: ReportSection.LastDateMet,
        isSelected: true,
        localizationKey: 'lastDateMet',
        weight: 1
      },
      {
        id: ReportSection.InstitutionInteractions,
        isSelected: true,
        localizationKey: 'institutionInteractions',
        weight: 1
      }
    ]
  },
  {
    id: ReportSection.ActivityCompanyContacts,
    isSelected: true,
    disabled: true,
    isFixed: true,
    localizationKey: 'companyContacts'
  },
  {
    id: ReportSection.ActivityNotes,
    isSelected: true,
    disabled: true,
    isFixed: true,
    localizationKey: 'activityNotes'
  },
  {
    id: ReportSection.ActivityCustomFields,
    isSelected: true,
    disabled: true,
    isFixed: true,
    localizationKey: 'activityCustomFields'
  }
];

export function getDefaultSections(): GroupedSectionSelectorListItem[] {
  return DEFAULT_ITINERARY_SECTIONS;
}

export const SECTIONS_WEIGHT_LIMIT = 16;
export const DEFAULT_DISABLED_TOOLTIP = 'dataPointSelectionDisabledTooltip';

export function getSectionCustomDisabledTooltip(id: ReportSection) {
  return id === ReportSection.BestFund ? 'bestFundSelectionDisabledTooltip' : '';
}
