import { Component, effect, inject, input } from '@angular/core';
import { ColumnComponent } from '@progress/kendo-angular-grid';
import { ExportableColumnDirective } from './exportable-column.directive';

@Component({
  standalone: true,
  selector: 'ca-exportable-column',
  template: '',
  hostDirectives: [
    {
      directive: ExportableColumnDirective,
      inputs: [
        'exportTitle: title',
        'exportField: field',
        'exportFormat: format',
        'exportSubFormat: subFormat',
        'exportOrderIndex: orderIndex',
        'exportAlignment: alignment',
        'exportHidden: hidden',
        'exportWidth: width'
      ]
    }
  ]
})
export class ExportableColumnComponent {
  readonly column = input<ColumnComponent>();

  constructor() {
    const exportableColumn = inject(ExportableColumnDirective);
    effect(() => {
      exportableColumn.bindToColumn(this.column());
    });
  }
}
