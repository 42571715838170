import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  Host,
  NgZone,
  OnDestroy,
  Optional,
  SkipSelf
} from '@angular/core';
import { ColumnBase, GridComponent } from '@progress/kendo-angular-grid';
import { fromEvent, merge, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Breakpoint } from '../../utils';
import { FireflyGridResizingService } from '../interactive';

@Directive({
  selector: '[fStickyColumns]'
})
export class FireflyStickyColumnsDirective implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private rightOffsets: number[] = [];
  private leftOffsets: number[] = [];

  get isMobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  get grid(): GridComponent {
    return this.parentGrid ?? this.hostGrid;
  }

  constructor(
    private ngZone: NgZone,
    @SkipSelf() private cdr: ChangeDetectorRef,
    @Host() private hostGrid: GridComponent,
    @Optional() @SkipSelf() private parentGrid: GridComponent,
    private gridResizingService: FireflyGridResizingService
  ) {}

  ngAfterViewInit() {
    if (!this.isMobile) {
      requestAnimationFrame(() => {
        this.updateStickyColumns();
        this.cdr.detectChanges();
      });
    }

    merge(
      fromEvent(window, 'resize'),
      this.gridResizingService.onGridColumnsChanges(this.grid),
      this.grid.columns.changes
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.isMobile) return;
        requestAnimationFrame(() => {
          this.updateStickyColumns();
          this.cdr.detectChanges();
        });
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private updateStickyColumns() {
    const stickyColumns = this.hostGrid.columns.filter(column => {
      const cssClass = column.cssClass as string | string[];
      return !!(cssClass && cssClass.includes('k-grid-content-sticky'));
    });

    const offsets = this.calculateLeftRightOffset(stickyColumns);
    this.rightOffsets = offsets.rightOffsets;
    this.leftOffsets = offsets.leftOffsets;

    stickyColumns.forEach((column: ColumnBase, index: number) => {
      column.headerStyle = this.getColumnOffset(index);
      column.style = this.getColumnOffset(index);
      column.headerClass = column.headerClass
        ? column.headerClass + ' ' + 'k-grid-header-sticky'
        : 'k-grid-header-sticky';
      column.footerStyle = this.getColumnOffset(index);
      column.footerClass = column.footerClass
        ? column.footerClass + ' ' + 'k-grid-content-sticky'
        : 'k-grid-content-sticky';
    });
  }

  private getColumnOffset(index: number) {
    return {
      left: this.leftOffsets[index] + 'px',
      right: this.rightOffsets[index] + 'px'
    };
  }

  private calculateLeftRightOffset(columns: ColumnBase[]) {
    const cols = this.grid.wrapper.nativeElement.querySelectorAll('colgroup col[style]');
    const clientWidth = this.grid.wrapper.nativeElement.clientWidth;
    let totalWidth = 0;

    columns.forEach(column => {
      totalWidth += this.getColumnWidth(column, cols);
    });

    if (totalWidth > clientWidth) {
      this.ngZone.onStable
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          this.grid.autoFitColumns();
        });
    }

    return columns.reduce(
      (acc, column) => {
        acc.leftOffsets.push(acc.left);
        acc.rightOffsets.push(totalWidth - acc.left - this.getColumnWidth(column, cols));
        acc.left += this.getColumnWidth(column, cols);
        return acc;
      },
      { leftOffsets: [] as number[], rightOffsets: [] as number[], left: 0 }
    );
  }

  private getColumnWidth(column: ColumnBase, cols: HTMLCollection) {
    return cols && cols[column.leafIndex] ? (cols[column.leafIndex] as HTMLElement).offsetWidth : 0;
  }
}
