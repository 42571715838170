<div class="input-group-side-icons" [ngClass]="{ 'search-notification': search.value }">
  <input
    type="text"
    class="form-control"
    data-automation-id="search-input"
    [placeholder]="placeholder"
    [formControl]="search"
    [attr.maxLength]="maxLength ? maxLength : null"
    (focus)="onInputFocus($event)"
    (focusout)="onInputFocusOut()"
  />
  <i class="f-i f-i-search"></i>
  <i
    class="f-i f-i-close"
    [title]="clearTooltip$ | async"
    [class.invisible]="!search.value"
    (click)="clear()"
    data-automation-id="clear-search"
  ></i>
</div>
