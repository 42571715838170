import { Directive, Input, Provider } from '@angular/core';

/**
 * Provides localization scope for LocalizePipe within the hosted element
 */
@Directive({
  selector: '[caLocalizationScope]',
  standalone: true
})
export class LocalizationScopeDirective {
  @Input() caLocalizationScope: string | undefined;
}

/**
 * provide localization scope value for caLocalize pipes in the current view
 *
 * !!! Must be added to `viewProviders` of the component
 */
export function provideViewLocalizationScope(scope: string): Provider {
  return { provide: LocalizationScopeDirective, useValue: { caLocalizationScope: scope } };
}
