import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { EMPTY, from, fromEvent, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, throttleTime } from 'rxjs/operators';
import { LoadingType } from '../loading-indicators';
import { ConfirmationModalOptions, ModalRef } from '../modal';
import { FireflyModalService } from '../modal/modal.service';
import { MultilineTextTruncateComponent } from '../multiline-text-truncate';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';
import {
  defaultModalLocalization,
  drawerOutClickExceptionClass,
  drawerWithFooterClasses,
  drawerWithoutFooterClasses,
  drawerZIndex
} from './constants';
import { DrawerConfig, DrawerType } from './models/drawer-config.model';
import { FireflyDrawerContent } from './models/drawer-content.model';

@Component({
  selector: 'f-drawer',
  templateUrl: './drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  title = '';
  titleIconTooltip = '';
  titleClass = '';
  titleIconClass = '';
  titleBadgeClass = '';
  titleBadgeText = '';
  controlButtonIconClass = '';
  controlButtonDisabled = false;
  showControlButton = false;
  controlButtonTitle = '';
  loadingType?: LoadingType;
  opened = false;
  rootCssClass = '';
  titleWrapperCssClass = '';
  type = DrawerType.Info;
  form?: FormGroup;
  initialFormReference?: Record<string, unknown>;
  position = 'right';
  dataLayoutId = '';
  zIndex = drawerZIndex;
  closeOnClickOutside = true;
  addBackButton = false;
  hideDrawer = false;
  showConfirmationModal = true;
  hasUnsavedChanges = false;
  footerTemplate!: TemplateRef<unknown>;
  controlButtonsTemplate!: TemplateRef<unknown>;
  actionButtonTemplate!: TemplateRef<unknown>;
  shouldHideFooter = false;
  openedChange$ = new Subject<boolean>();
  openPreviousDrawer$ = new Subject<void>();
  visibilityChange$ = new Subject<boolean>();
  controlButtonClick$ = new Subject<Event>();
  closeButtonClick$ = new Subject<void>();
  modalLeaveTriggered$ = new Subject<boolean>();
  destroyed$ = new Subject<void>();
  close$: Observable<string> = of('Close');
  options$: Observable<string> = of('Options');
  getLocalization$: Observable<Record<string, string>> = of(defaultModalLocalization);
  modalLocalizedInfo: Record<string, string> = {};

  LoadingType = LoadingType;
  formDrawerOutclickElements = Array.from(document.querySelectorAll('.f-drawer-outclick-element'));
  drawerWithoutFooterClasses = drawerWithoutFooterClasses;
  drawerWithFooterClasses = drawerWithFooterClasses;

  @ViewChild('drawer', { static: true })
  private drawerElementRef!: ElementRef;
  @ViewChild('content', { static: true, read: ViewContainerRef })
  private dynamicDrawerContent!: ViewContainerRef;
  @ViewChild('footer', { static: true, read: ViewContainerRef })
  private footerPlaceholder!: ViewContainerRef;
  @ViewChild('controlButtons', { static: true, read: ViewContainerRef })
  private drawerControlButtons!: ViewContainerRef;
  @ViewChild('actionButton', { static: true, read: ViewContainerRef })
  private drawerActionButton!: ViewContainerRef;
  @ViewChild(MultilineTextTruncateComponent, { static: true })
  private truncate!: MultilineTextTruncateComponent;

  @HostListener('window:beforeunload')
  onPageRefresh() {
    if (this.formDrawerHasChanges) return false;
    return;
  }

  get drawerClasses() {
    return `${this.rootCssClass} ${this.opened ? 'opened' : ''} position-${this.position} ${
      this.hideDrawer ? 'hidden' : ''
    }`;
  }

  get hasNoFooter() {
    return this.isInfoDrawer || !this.footerTemplate || this.shouldHideFooter;
  }

  get isFormDrawer() {
    return this.type === DrawerType.Form;
  }

  get isFiltersDrawer() {
    return this.type === DrawerType.Filters;
  }

  get formHasChanges(): boolean {
    return (
      (!!this.form?.dirty &&
        Object.keys(this.initialFormReference!).some(
          key => !isEqual(this.form!.value[key], this.initialFormReference![key])
        )) ||
      this.hasUnsavedChanges
    );
  }

  get formDrawerHasChanges() {
    return this.isFormDrawer && this.formHasChanges && this.showConfirmationModal;
  }

  get hasConfirmationModal() {
    return !!this.modalRef;
  }

  get confirmationModalClose() {
    return this.modalRef ? from(this.modalRef?.result) : of(EMPTY);
  }

  private get transitionEnd$(): Observable<unknown> {
    return fromEvent(this.drawerElementRef.nativeElement, 'transitionend').pipe(take(1));
  }

  private get documentClick$(): Observable<Event> {
    return fromEvent(document, 'mousedown').pipe(
      throttleTime(200),
      takeUntil(this.destroyed$),
      filter(() => (this.closeOnClickOutside && !this.hideDrawer) || this.isFormDrawer)
    );
  }

  private get isInfoDrawer() {
    return this.type === DrawerType.Info;
  }

  private modalRef!: ModalRef<ConfirmationModalOptions> | null;

  constructor(
    public cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    @Optional() private localizationService: FireflyLocalizationService,
    @Optional() private modalService: FireflyModalService
  ) {}

  ngOnInit() {
    if (!this.localizationService) return;
    this.close$ = this.localizationService.localize('close', {});
    this.options$ = this.localizationService.localize('options', {});
    this.getLocalization$ = this.localizationService.getLocalization().pipe(
      map(defaultInfo => ({
        ...defaultInfo,
        ...this.modalLocalizedInfo
      }))
    );
  }

  ngAfterViewInit() {
    this.documentClick$.subscribe(e => {
      const eventPath = e.composedPath();
      const outclickExceptionElements = Array.from(document.querySelectorAll(`.${drawerOutClickExceptionClass}`));
      for (let i = 0; i < eventPath.length - 1; i++) {
        const target = eventPath[i];
        if (this.elementRef.nativeElement.contains(target) || outclickExceptionElements.includes(target as Element))
          break;
        if (this.isFormDrawer && this.formDrawerOutclickElements.includes(target as Element)) {
          if (this.formHasChanges) e.preventDefault();
          this.confirmAndClose(false, e);
          break;
        }
        if (target === document && !this.isFormDrawer) {
          this.confirmAndClose();
          break;
        }
      }
    });

    this.truncate.updateTitle();
  }

  createDrawerContent<TComponent extends FireflyDrawerContent<TInput>, TInput>(
    component: Type<TComponent>,
    injector?: Injector
  ): ComponentRef<TComponent> {
    this.dynamicDrawerContent.clear();
    const contentComponent = this.dynamicDrawerContent.createComponent(component, { injector: injector });
    this.setFooterTemplate(contentComponent.instance.footerTemplateRef);
    this.setControlButtonsTemplate(contentComponent.instance.controlButtonsTemplateRef);
    this.setActionButtonTemplate(contentComponent.instance.actionButtonTemplateRef);
    return contentComponent;
  }

  setConfig(config: DrawerConfig) {
    this.type = config.type ?? this.type;
    this.title = config.title ?? this.title;
    this.titleIconTooltip = config.titleIconTooltip ?? this.titleIconTooltip;
    this.titleClass = config.titleClass ?? !this.isInfoDrawer ? 'mb-4' : this.titleClass;
    this.titleIconClass = config.titleIconClass ?? this.titleIconClass;
    this.titleBadgeClass = config.titleBadgeClass ?? this.titleBadgeClass;
    this.titleBadgeText = config.titleBadgeText ?? this.titleBadgeText;
    this.position = config.position ?? this.position;
    this.closeOnClickOutside = config.closeOnClickOutside ?? this.closeOnClickOutside;
    this.addBackButton = config.addBackButton ?? this.addBackButton;
    this.rootCssClass = config.rootCssClass ?? this.rootCssClass;
    this.titleWrapperCssClass = config.titleWrapperCssClass ?? this.titleWrapperCssClass;
    this.dataLayoutId = config.dataLayoutId ?? this.dataLayoutId;
    this.zIndex = config.zIndex ?? this.zIndex;
    this.modalLocalizedInfo = config.modalLocalizedInfo ?? this.modalLocalizedInfo;
    this.controlButtonIconClass = config.controlButtonIconClass ?? this.controlButtonIconClass;
    this.showControlButton = config.showControlButton ?? this.showControlButton;
    this.controlButtonTitle = config.controlButtonTitle ?? this.controlButtonTitle;
    if (config.modalLocalizedInfo) {
      this.getLocalization$ = of({
        ...defaultModalLocalization,
        ...this.modalLocalizedInfo
      });
    }

    // CPD-577: Fix drawer animation issue in FF
    setTimeout(() => {
      this.opened = true;
      this.cd.detectChanges();
    }, 10);
  }

  openLocalizedConfirmationModal(): Observable<boolean> {
    return this.getLocalization$.pipe(
      take(1),
      switchMap((localization: Record<string, string>) => {
        return this.openConfirmationModal(localization);
      })
    );
  }

  private openConfirmationModal(localization: Record<string, string>): Observable<boolean> {
    if (this.modalRef) return EMPTY;

    this.modalRef = this.modalService.openConfirmation({
      style: 'warning',
      title: localization['modalLeaveFormTitle'],
      body: localization['modalConfirmationMessage'],
      actionText: localization['modalConfirmTitle'],
      dismissText: localization['modalCancelTitle']
    });

    this.modalRef.result.finally(() => window.requestAnimationFrame(() => (this.modalRef = null)));

    return from(this.modalRef.result).pipe(
      take(1),
      map(() => {
        this.modalLeaveTriggered$.next(true);
        return true;
      }),
      catchError(() => of(false))
    );
  }

  setControlButtonsTemplate(controlButtonsTemplate: TemplateRef<unknown>) {
    this.drawerControlButtons.clear();

    if (controlButtonsTemplate) {
      this.controlButtonsTemplate = controlButtonsTemplate;
      this.drawerControlButtons.createEmbeddedView(controlButtonsTemplate);
    }
  }

  setActionButtonTemplate(actionButtonTemplate: TemplateRef<unknown>) {
    this.drawerActionButton.clear();

    if (actionButtonTemplate) {
      this.actionButtonTemplate = actionButtonTemplate;
      this.drawerActionButton.createEmbeddedView(actionButtonTemplate);
    }
  }

  setFooterTemplate(footerTemplate: TemplateRef<unknown>) {
    if (footerTemplate) {
      this.footerTemplate = footerTemplate;
      if (this.footerTemplate) this.footerPlaceholder.createEmbeddedView(footerTemplate);
    }
  }

  close(openPreviousDrawer?: boolean) {
    if (openPreviousDrawer) {
      this.openPreviousDrawer$.next();
      return;
    }
    this.opened = false;
    this.cd.markForCheck();
    document.body.classList.remove('position-fixed');
    document.body.classList.remove('h-100');
    this.transitionEnd$.subscribe(() => this.openedChange$.next(this.opened));
  }

  controlButtonAction(event: Event): void {
    this.controlButtonClick$.next(event);
  }

  setControlButtonDisabled(disabled: boolean) {
    this.controlButtonDisabled = disabled;
    this.cd.markForCheck();
  }

  confirmAndClose(openPreviousDrawer?: boolean, event?: Event): void {
    if (this.modalRef) return;
    if (this.formDrawerHasChanges) {
      this.openLocalizedConfirmationModal()
        .pipe(filter(Boolean))
        .subscribe(() => {
          this.close(openPreviousDrawer);
          event?.target?.dispatchEvent(new Event('click', { bubbles: true }));
        });
    } else {
      this.close(openPreviousDrawer);
    }
    if (event) this.closeButtonClick$.next();
  }

  changeDrawerTitle(title: string) {
    this.title = title;
    this.truncate.changeTitle(this.title);
  }

  changeDrawerTitleIconClass(titleIconClass: string) {
    this.titleIconClass = titleIconClass;
    this.cd.detectChanges();
    this.truncate.updateTitle();
  }

  changeDrawerTitleIconTooltip(title: string) {
    this.titleIconTooltip = title;
  }

  changeDrawerBadgeText(title: string) {
    this.titleBadgeText = title;
  }

  changeDrawerBadgeClass(badgeClass: string) {
    this.titleBadgeClass = badgeClass;
  }

  setDrawerLoadingState(type?: LoadingType) {
    this.loadingType = type;
    this.cd.detectChanges();
  }

  // TODO: Get rid of this after "Add new Institutions" form drawer is refactored
  setDrawerType(drawerType: DrawerType) {
    this.type = drawerType;
  }

  setConfirmationModalTrigger(showModal: boolean) {
    this.showConfirmationModal = showModal;
  }

  setUnsavedFlag(hasUnsavedChanges: boolean) {
    this.hasUnsavedChanges = hasUnsavedChanges;
  }

  resetZIndex(): void {
    this.zIndex = drawerZIndex;
    this.cd.detectChanges();
  }

  toggleHiddenState(value: boolean): void {
    window.requestAnimationFrame(() => {
      this.visibilityChange$.next(!value);
      this.hideDrawer = value;
      this.cd.detectChanges();
    });
  }

  createFormReference(form: FormGroup) {
    this.initialFormReference = Object.assign({}, form.value);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
