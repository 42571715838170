import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { NgbDropdownAnchor, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import ResizeObserver from 'resize-observer-polyfill';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FireflyModalService } from '../../modal';
import { ModalRef } from '../../modal/models/base-modal-options';
import { FIREFLY_SUGGESTER } from '../../suggesters/constants';
import { Breakpoint, FireflyLocalizationService, FireflyNumberFormattingService } from '../../utils';
import { ChipSize } from '../models/chip-size';
import { ChipType } from '../models/chip-type';
import { FireflyCollapsedPopoverComponent } from './collapsed-popover/collapsed-popover.component';
import * as utils from './editable-chip-group.utils';

@Component({
  selector: 'f-editable-chip-group',
  templateUrl: './editable-chip-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyEditableChipGroupComponent implements OnInit, OnChanges, OnDestroy {
  private destroyed$ = new Subject<void>();
  private modal!: ModalRef<unknown> | null;
  private removedItems: Record<string, unknown>[] = [];
  private initialItems: Record<string, unknown>[] = [];

  @Input() items: Record<string, unknown>[] | null = [];
  @Input() invalidItems: Record<string, unknown>[] | null = [];
  @Input() valuePath: string = 'text';
  @Input() linkPath: string = 'link';
  @Input() chipCssClass: string = '';
  @Input() itemCssClass: string = '';
  @Input() chipSize: ChipSize = ChipSize.Xs;
  @Input() collapsable = false;
  @Input() type: ChipType = ChipType.Editable;
  @Input() disabled: boolean = false;
  @Input() popoverContainer: string = 'body';
  @Input() popoverPlacement: string = 'bottom top';
  @Input() popoverAutoClose: boolean | string = 'outside';
  @Input() idField: string = 'id';

  @Output() itemClick = new EventEmitter<Record<string, unknown>>();
  @Output() remove = new EventEmitter<Record<string, unknown>>();
  @Output() removeAll = new EventEmitter();

  @ViewChild('collapsedChipContainer', { static: true, read: ElementRef }) collapsedChipContainer!: ElementRef;
  @ViewChild('popover') popover!: NgbPopover;

  @HostBinding('class.has-chips') hasChipsCssClass = false;

  get isPartOfSuggester() {
    return !!this.suggester;
  }

  constructor(
    private zone: NgZone,
    public el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: FireflyModalService,
    @Optional() @SkipSelf() private dropdownAnchor: NgbDropdownAnchor,
    @Optional() private localizationService: FireflyLocalizationService,
    @Optional() private numberFormattingService: FireflyNumberFormattingService,
    @Optional() @Inject(FIREFLY_SUGGESTER) private suggester: unknown
  ) {}

  offset = 4;
  maxCount = 50;
  collapsed = false;
  chipTypeEnum = ChipType;
  collapsedText$ = of('');
  visibleChips: Record<string, unknown>[] | null = [];
  collapsedChipGroupModalTitle = 'Edit selected items';

  private resizeObserver = new ResizeObserver(() => this.ngOnChanges());

  get linkClass() {
    return this.type === this.chipTypeEnum.Editable ? 'link-main' : 'link-base';
  }

  ngOnInit() {
    const el = this.dropdownAnchor?.nativeElement ?? this.el.nativeElement;
    this.zone.runOutsideAngular(() => this.resizeObserver.observe(el));
    if (this.localizationService) {
      this.localizationService
        .localize('collapsedChipGroupModalTitle', {})
        .pipe(takeUntil(this.destroyed$))
        .subscribe(title => {
          this.collapsedChipGroupModalTitle = title;
        });
    }
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges() {
    if (!this.items || !this.collapsedChipContainer) {
      this.hasChipsCssClass = !!this.items?.length;
      this.visibleChips = this.items;
      return;
    }
    // check if chips fit parent container
    if (!this.collapsable || !this.isCollapse(this.items)) {
      this.collapsed = false;
      this.visibleChips = this.items;
      this.hasChipsCssClass = !!this.items?.length;
      this.collapsedText$ = of('');
    } else {
      this.visibleChips = [];
      this.hasChipsCssClass = true;
      if (this.localizationService && this.numberFormattingService) {
        this.collapsedText$ = this.numberFormattingService
          .formatNumber(this.items.length)
          .pipe(switchMap(count => this.localizationService.localize('collapsedChipGroupItemsCount', { count })));
      } else {
        this.collapsedText$ = of(`${this.items.length.toLocaleString()} items`);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  onCollapsedChipClick($event: Event, popover: NgbPopover) {
    if (this.disabled && !this.isPartOfSuggester) return;

    if (window.innerWidth >= Breakpoint.Sm) {
      popover.toggle();
      return;
    }

    this.initialItems = this.items?.length ? [...this.items] : [];
    const modalDialogClass = `mobile-collapsed-popover ${
      this.disabled && this.isPartOfSuggester ? 'disabled-suggester-popover' : ''
    }`;

    this.modal = this.modalService.open({
      component: FireflyCollapsedPopoverComponent,
      title: this.collapsedChipGroupModalTitle,
      modalDialogClass,
      context: this,
      mobile: true
    });

    this.modal.result
      .then(
        () => this.handleStateUpdate(),
        () => this.resetState()
      )
      .finally(() => {
        this.modal = null;
      });
  }

  onItemClick(item: Record<string, unknown>) {
    this.itemClick.emit(item);
  }

  onRemove(item: Record<string, unknown>) {
    if (this.modal) {
      this.removedItems?.push(item);
      this.items = this.items ? this.items?.filter(i => !isEqual(i, item)) : [];
      return;
    }
    this.remove.emit(item);
  }

  onRemoveAll() {
    this.removeAll.emit();
  }

  isCollapse(items: Record<string, unknown>[]) {
    let sumWidth = 0;
    this.collapsed = true;

    if (items.length >= this.maxCount) {
      return true;
    }

    const maxWidth = utils.getMaxWidth(this.el.nativeElement);

    if (!maxWidth) {
      return false;
    }

    const chipsElement = this.collapsedChipContainer.nativeElement;

    for (const item of items) {
      this.collapsedText$ = of(`${get(item, this.valuePath, '')}`);
      this.changeDetectorRef.detectChanges();

      sumWidth += chipsElement.offsetWidth + this.offset;

      if (sumWidth > maxWidth) {
        return true;
      }
    }

    return false;
  }

  getChipType(item: Record<string, unknown>): ChipType {
    return this.invalidItems?.find(i => i[this.idField] === item[this.idField]) ? ChipType.EditableDanger : this.type;
  }

  private handleStateUpdate() {
    if (!this.items?.length && this.removedItems.length) {
      this.removeAll.emit();
    } else {
      this.removedItems.forEach(item => {
        window.requestAnimationFrame(() => this.remove.emit(item));
      });
    }
    this.removedItems = [];
  }

  private resetState() {
    this.items = [...this.initialItems];
    this.removedItems = [];
  }
}
