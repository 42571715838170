<div class="f-layout-width" [ngClass]="{ 'area-disabled opacity-50': disabled }">
  @for (column of columns; track column; let index = $index) {
  <div
    class="f-layout-width-item"
    [class.selected]="column.selected"
    [class.disabled]="column.disabled"
    (click)="onSelectColumnsCount(index)"
    [title]="
      column.disabled && index < minColumns
        ? (minDisabledColumnTitle$ | async)!
        : column.disabled && index >= maxColumns
        ? (maxDisabledColumnTitle$ | async)!
        : ''
    "
  ></div>
  }
</div>
