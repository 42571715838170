<ng-container [caLocalizationScope]="localizationScope">
  <div>
    <span
      class="body-70 link link-primary"
      data-automation-id="clear-all"
      (click)="clearAll()"
      (keyup.enter)="clearAll()"
    >
      {{ 'clearAll' | caLocalize }}
    </span>
  </div>
  <f-draggable-list
    [items]="sections"
    [listItemTemplate]="listItemTemplate"
    [preventDragFn]="preventDragFn"
    (drop)="onDrop($event)"
  ></f-draggable-list>

  <ng-template #listItemTemplate let-item>
    <f-list-item-checkbox
      *ngIf="!item.nestedItems; else groupItem"
      [disabled]="item.isToggleDisabled"
      [checked]="item.isSelected"
      [checkboxTitle]="item.disabledTooltip ? (item.disabledTooltip | caLocalize)! : ''"
      [title]="item.disabledTooltip ? (item.disabledTooltip | caLocalize)! : ''"
      (toggle)="toggle(item)"
      ><span>{{
        item.hasCurrencySymbol
          ? (item.localizationKey | caLocalize | caCurrencyMillionsSymbol)
          : (item.localizationKey | caLocalize)
      }}</span>
      <span *ngIf="item.isRequired" class="ps-1 text-muted">{{ 'required' | caLocalize }}</span>
    </f-list-item-checkbox>

    <ng-template #groupItem>{{ item.localizationKey | caLocalize }}</ng-template>
  </ng-template>
</ng-container>
