import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';
import { HashMap, LocalizationService } from '@capital-access/common/localization';
import { IconSize } from '@capital-access/common/utils';
import { FireflyPopoverModule } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';

@Component({
  selector: 'ca-equity-assets-popover',
  templateUrl: './equity-assets-popover.component.html',
  standalone: true,
  imports: [FireflyPopoverModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquityAssetsPopoverComponent {
  iconSize = input<IconSize>(IconSize.Sm);

  readonly localization = toSignal(
    inject(LocalizationService, { optional: true })?.getLocalization(COMMON_COMPONENTS_LOCALIZATION_SCOPE) ??
      of({} as HashMap),
    { initialValue: {} as HashMap }
  );

  readonly popoverTitle = computed(
    () =>
      this.localization()['equityAssetsPopoverInfo'] ||
      'Sum of latest disclosed equity holdings for an institution or fund.'
  );
}
