import { DraggableItem } from '@capital-access/firefly/components';

export enum ReportSection {
  InstitutionDetails = 'InstitutionDetails',
  InstitutionInvestmentApproach = 'InstitutionInvestmentApproach',
  ContactBiography = 'ContactBiography',
  FinancialHighlights = 'FinancialHighlights',
  Targeting = 'Targeting',
  EquityPortfolio = 'EquityPortfolio',
  HistoricalOwnership = 'HistoricalOwnership',
  ActivityHistory = 'ActivityHistory',
  SustainabilityFocus = 'SustainabilityFocus',
  SustainabilityFitMetric = 'SustainabilityFitMetric',
  SuitabilityFactors = 'SuitabilityFactors',
  PeerOwnership = 'PeerOwnership',
  CustomFields = 'CustomFields',
  Style = 'StyleName',
  HolderRegion = 'HolderRegionName',
  HolderInvestmentCenter = 'HolderInvestmentCenterName',
  HolderCountry = 'HolderCountryName',
  Orientation = 'Orientation',
  Turnover = 'Turnover',
  City = 'City',
  Country = 'Country',
  HistoricalOwnershipTableDetails = 'HistoricalOwnershipTableDetails',
  FundsSummary = 'FundSummary',
  PrimaryContactBiography = 'PrimaryContactBiography',
  ParticipantBiography = 'ParticipantBiography',
  CombinedOwnership = 'CombinedOwnership',
  Ownership = 'Ownership',
  TargetingEquityPortfolio = 'TargetingEquityPortfolio',
  HistoricalPeerOwnership = 'HistoricalPeerOwnership',

  Institution = 'Institution',
  InstitutionType = 'InstitutionType',
  ExternalParticipants = 'ExternalParticipants',
  ExternalParticipantJobRole = 'ExternalParticipantJobRole',
  SharesReportedDate = 'SharesReportedDate',
  SharesChange = 'SharesChange',
  EquityAssets = 'EquityAssets',
  InstitutionRating = 'InstitutionRating',
  SuitabilityScore = 'SuitabilityScore',
  PurchasingPower = 'PurchasingPower',
  BestFund = 'BestFund',
  LastDateMet = 'LastDateMet',
  InstitutionInteractions = 'InstitutionInteractions',
  ActivityCompanyContacts = 'ActivityCompanyContacts',
  ActivityNotes = 'ActivityNotes',
  HolderRank = 'HolderRank',
  OwnershipValue = 'OwnershipValue',
  ShareChangeValue = 'ShareChangeValue',
  SharesOutstandingPercentage = 'SharesOutstandingPercentage',
  PortfolioPercentage = 'PortfolioPercentage',
  ActivityCustomFields = 'ActivityCustomFields',

  InteractionWithInstitution = 'InteractionWithInstitution'
}

export type ConditionalReportSections = Partial<Record<ReportSection, boolean>>;

export interface SectionSelectorListItem extends DraggableItem {
  id: ReportSection;
  isSelected: boolean;
  localizationKey?: string;
  hasCurrencySymbol?: boolean;
  isCommonForAllSections?: boolean;
}
