import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { createItineraryReport, createItineraryReportFinished } from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import {
  getUserSettingsCulture,
  getUserSettingsCurrency,
  getUserSettingsLanguage,
  getUserSettingsTimezone
} from '@capital-access/common/globalization';
import { getSelectedSecurity, getSelectedSecuritySurveillancePermission } from '@capital-access/common/security';
import { generateReport } from '../../core/+state/report.actions';
import { OwnershipSource } from '../../core/models/ownership-source.enum';
import { ReportSection } from '../../core/models/report-sections';
import { FileNameService } from '../../core/services/file-name.service';
import { getSavedSelectedSectionsFromGroups } from '../../core/utils/section-settings.utils';
import { SETTINGS_KEY } from '../constants';
import { CreateItineraryReportRequest } from '../models/itinerary-report.models';
import { getDefaultSections } from '../models/itinerary-report-sections';
import { LocalizationKeys } from '../models/localization.model';
import { createItineraryCustomReport } from './itinerary-report.actions';

@Injectable()
export class ItineraryReportEffects {
  createItineraryReportRequest$ = createEffect(() =>
    this.actions$.pipe(ofType(createItineraryReport), this.createItineraryReport())
  );

  createItineraryCustomReportRequest$ = createEffect(() =>
    this.actions$.pipe(ofType(createItineraryCustomReport), this.createItineraryReport())
  );

  private createItineraryReport() {
    return (
      source: Observable<{
        eventId: string;
        eventName: string;
        sections?: ReportSection[];
      }>
    ) =>
      source.pipe(
        withLatestFrom(
          this.store.select(getSelectedSecurity),
          this.store.select(getUserSettingsTimezone),
          this.store.select(getSelectedSecuritySurveillancePermission),
          this.store.select(getUserSettingsCurrency),
          this.store.select(getFeatureToggle('skyl-2231-culture-specific-formatting')),
          this.store.select(getUserSettingsCulture),
          this.store.select(getUserSettingsLanguage)
        ),
        mergeMap(([source, security, timeZone, isSrvPermissioned, currency, cultureFtEnabled, culture, language]) => {
          const isSrv = security?.surveillance ?? false;
          return this.fileNameService.getDefaultFileName(isSrvPermissioned, isSrv, source.eventName, timeZone).pipe(
            map(fileName => {
              const requestedSections = source.sections;
              const sections = requestedSections
                ? requestedSections
                : getSavedSelectedSectionsFromGroups(SETTINGS_KEY, getDefaultSections());
              const createItineraryReportRequest: CreateItineraryReportRequest = {
                eventId: source.eventId,
                timeZone,
                securityId: security?.id,
                fileName,
                currency,
                culture: cultureFtEnabled ? culture : 'en-US',
                language: language,
                dataSource: isSrv ? OwnershipSource.Surveillance : OwnershipSource.Public,
                sections
              };

              return generateReport({
                request: createItineraryReportRequest,
                url: 'itinerary-reports/process/launch',
                successMessage: LocalizationKeys.ItineraryReportCreated,
                failureMessage: LocalizationKeys.ItineraryReportFailed,
                finishAction: createItineraryReportFinished({ eventId: source.eventId })
              });
            })
          );
        })
      );
  }

  constructor(private actions$: Actions, private store: Store, private fileNameService: FileNameService) {}
}
