import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LOCALIZATION_KEY } from '../../constants';

@Component({
  selector: 'ca-modify-report-drawer-body',
  templateUrl: './modify-report-drawer-body.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModifyReportDrawerBodyComponent {
  localizationScope = LOCALIZATION_KEY;
  @Input() sectionsTitle: string = 'sectionSettings';
  @Input() reportDescriptionTitle!: string;
}
