import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { createItineraryReportFinished, openItineraryReportDrawer } from '@capital-access/common/actions';
import { LocalizationService } from '@capital-access/common/localization';
import { DrawerOutput, DrawerPosition, DrawerType, FireflyDrawerService } from '@capital-access/firefly/components';
import { LOCALIZATION_KEY } from '../../constants';
import { getGroupedSavedSections } from '../../core/utils/section-settings.utils';
import { ItineraryReportDrawerComponent } from '../components/itinerary-report-drawer/itinerary-report-drawer.component';
import { SETTINGS_KEY } from '../constants';
import { CreateItineraryReportDto } from '../models/itinerary-report.models';
import { getDefaultSections } from '../models/itinerary-report-sections';

@Injectable()
export class ItineraryDrawerEffects {
  openDrawer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openItineraryReportDrawer),
      withLatestFrom(this.localization.getLocalization(LOCALIZATION_KEY)),
      switchMap(([{ eventId, eventName }, localization]) => {
        const itinerarySections = getGroupedSavedSections(SETTINGS_KEY, getDefaultSections());

        const drawer = this.drawerService.openDrawer(
          {
            title: localization[`modifyItineraryReport`],
            position: DrawerPosition.Right,
            type: DrawerType.Form,
            dataLayoutId: 'modify-itinerary-report-drawer'
          },
          ItineraryReportDrawerComponent,
          {
            request: CreateItineraryReportDto.create({ eventId, eventName }),
            filteredSections: itinerarySections
          }
        );

        return drawer.onClose().pipe(
          take(1),
          filter((output: DrawerOutput) => !output || !output['reportInProgress']),
          map(() => createItineraryReportFinished({ eventId }))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localization: LocalizationService,
    private drawerService: FireflyDrawerService
  ) {}
}
