import { Directive, TemplateRef, ViewChild } from '@angular/core';
import { noop } from 'rxjs';
import {
  FireflyDrawerActionButtonDirective,
  FireflyDrawerControlButtonsTemplateDirective,
  FireflyDrawerFooterTemplateDirective
} from '../directives/drawer-template.directives';
import { DrawerOutput } from './drawer-config.model';
import { DrawerRef } from './drawer-ref.model';

@Directive()
export abstract class FireflyDrawerContent<TInputs> {
  @ViewChild(FireflyDrawerFooterTemplateDirective, { static: true, read: TemplateRef })
  footerTemplateRef!: TemplateRef<unknown>;
  @ViewChild(FireflyDrawerControlButtonsTemplateDirective, { static: true, read: TemplateRef })
  controlButtonsTemplateRef!: TemplateRef<unknown>;
  @ViewChild(FireflyDrawerActionButtonDirective, { static: true, read: TemplateRef })
  actionButtonTemplateRef!: TemplateRef<unknown>;
  drawerInstanceRef!: DrawerRef;

  onInjectInputs(_: TInputs): void {
    noop();
  }

  close(output?: DrawerOutput): void {
    this.drawerInstanceRef.close(output);
  }

  back(): void {
    this.drawerInstanceRef.back();
  }

  hide(): void {
    this.drawerInstanceRef.hide();
  }

  show(): void {
    this.drawerInstanceRef.show();
  }

  dismiss(): void {
    this.drawerInstanceRef.dismiss();
  }

  changeDrawerTitle(title: string) {
    this.drawerInstanceRef.changeDrawerTitle(title);
  }

  changeDrawerTitleIconClass(iconClass: string) {
    this.drawerInstanceRef.changeDrawerTitleIconClass(iconClass);
  }

  changeDrawerBadgeText(badgeText: string) {
    this.drawerInstanceRef.changeDrawerBadgeText(badgeText);
  }

  changeDrawerBadgeClass(badgeClass: string) {
    this.drawerInstanceRef.changeDrawerBadgeClass(badgeClass);
  }

  changeDrawerTitleIconTooltip(title: string) {
    this.drawerInstanceRef.changeDrawerTitleIconTooltip(title);
  }
}
