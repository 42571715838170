import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, withLatestFrom } from 'rxjs/operators';
import { Breakpoint, FireflyTopNavService } from '@capital-access/firefly/components';
import { isSearchStarted, showResults } from '../../+state/search.selectors';

@Component({
  selector: 'ca-one-search',
  templateUrl: './one-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneSearchComponent implements OnDestroy {
  private subscription!: Subscription | undefined;

  constructor(private store: Store, private host: ElementRef, private topNavService: FireflyTopNavService) {}

  showResults$ = this.store.select(showResults);
  loading$ = this.store.select(isSearchStarted);

  @HostListener('click')
  onHostClick() {
    if (!this.subscription && window.innerWidth < Breakpoint.Sm) this.handleOutClick();
  }

  handleOutClick() {
    this.subscription = fromEvent(document, 'click')
      .pipe(debounceTime(100), withLatestFrom(this.showResults$, this.loading$, this.topNavService.searchOpenChange$))
      .subscribe(([$event, showResults, loading, searchIsActive]) => {
        if (showResults || loading || !searchIsActive) return;
        const contains = this.host.nativeElement.contains($event.target);
        if (!contains) {
          this.topNavService.toggleSearchOpenState(false);
          this.subscription?.unsubscribe();
          this.subscription = undefined;
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
