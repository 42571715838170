import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Log } from '@capital-access/common/logging';
import { getCurrentRouteAuthorized } from '@capital-access/common/router';
import { AppSettingsService } from '@capital-access/common/settings';
import { isIpad, isMobile, isTablet, MobileInnerErrorOptions } from '@capital-access/firefly/components';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  flow$?: Observable<'authorized' | 'anonymous'>;
  mobileInnerErrorOptions = MobileInnerErrorOptions;
  isLandscapeOrientation = false;

  constructor(private store: Store, private appSettings: AppSettingsService) {
    this.logAppInfo();
  }

  ngAfterViewInit() {
    this.flow$ = this.store.select(getCurrentRouteAuthorized).pipe(
      filter(x => x !== null),
      map(authorized => (authorized ? 'authorized' : 'anonymous'))
    );

    this.detectScreenOrientation();
  }

  private logAppInfo() {
    const { version } = this.appSettings.getSettings<{ version: string }>();

    Log.info(`Application info: ${version}`);
  }

  private detectScreenOrientation() {
    if (isMobile() && !isTablet() && !isIpad()) {
      const updateLandscapeStatus = () => (this.isLandscapeOrientation = screen.orientation.type.includes('landscape'));
      updateLandscapeStatus();
      screen.orientation.addEventListener('change', updateLandscapeStatus);
    }
  }
}
