import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { fetchSecurity } from '../services/fetch-securities.operators';

@Pipe({
  name: 'caTickerSymbol',
  standalone: true,
  pure: false
})
export class TickerSymbolPipe extends BaseAsyncPipe<string, string, [number | null]> implements PipeTransform {
  #store = inject(Store);

  override getTransformer(text: string, securityId: number | null): Observable<string> {
    if (securityId === null) {
      return of(text);
    }

    return fetchSecurity(this.#store, securityId, {
      onSuccess: security => `${text} ${security.ticker}`,
      onFail: () => of(text)
    });
  }
}
