import { DateTime } from 'luxon';
import { Breakpoint } from '@capital-access/firefly/components';
import { SelectedDateRange } from './models/selected-date-range.model';

export const EMAILS_API_BASE_URL = '/api/email/';
export const TAKE = 50;
export const MAX_STORAGE_LIMIT_GB = 15;

export const DEFAULT_SELECTED_RANGE = {
  start: DateTime.now().minus({ months: 12 }).startOf('day').toJSDate(),
  end: DateTime.now().endOf('day').toJSDate(),
  localizationKey: 'dateWithToday'
} as SelectedDateRange;

export const isTablet: () => boolean = (): boolean => {
  return window.innerWidth < Breakpoint.Lg;
};
