<i
  fMobilePopover
  [ngbPopover]="popoverTitle()"
  triggers="hover focus"
  container="body"
  placement="top"
  class="f-i f-i-info text-muted align-middle cursor-pointer"
  [ngClass]="[iconSize()]"
  data-automation-id="equity-assets-popover-icon"
>
</i>
