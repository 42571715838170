import { Injectable, Optional, QueryList } from '@angular/core';
import { ColumnBase, ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';
import { isColumnReorderable, isColumnSticky } from '../utils';
import { tableMedia } from '../utils/table-media';
import { BaseColumnSettings, ColumnChooserTableSettings } from './column-chooser.models';

@Injectable()
export abstract class FireflyColumnChooserPersistenceService {
  abstract getTableSettings(
    tableId: string,
    columns: string[],
    stickyColumnsFieldOrderIndex: Record<string, number>
  ): Observable<Array<ColumnChooserTableSettings | null> | null>;
  abstract updateTableSettings(
    tableId: string,
    settings: Array<ColumnChooserTableSettings | null>
  ): Observable<unknown>;
  abstract resetTableSettings(tableId: string): Observable<unknown>;
  abstract markUnusedSettings(tableId: string, columns: string[]): void;
}

const initialStatePrefix = 'initial';

@Injectable({ providedIn: 'root' })
export class FireflyColumnChooserService {
  private gridIdMap = new Map<string, GridComponent>();

  applyChangesSubj = new Subject<void>();
  applyChangesObs = this.applyChangesSubj.asObservable().pipe(share());

  constructor(@Optional() private columnChooserPersistenceService: FireflyColumnChooserPersistenceService) {}

  setInitialStateMinimalInfo(id: string, columns: BaseColumnSettings[]) {
    localStorage.setItem(this.getInitialStateId(id), JSON.stringify(columns));
  }

  getInitialStateMinimalInfo(id: string): BaseColumnSettings[] {
    const localConfig = localStorage.getItem(this.getInitialStateId(id));
    return localConfig ? (JSON.parse(localConfig) as BaseColumnSettings[]) : [];
  }

  removeInitialStateMinimalInfo(id: string) {
    localStorage.removeItem(this.getInitialStateId(id));
  }

  getSelectedFields(gridId: string) {
    const grid = this.gridIdMap.get(gridId);
    if (!grid) return [];
    return (grid.columns as QueryList<ColumnComponent>).filter(col => !col.hidden && !!col.field).map(col => col.field);
  }

  applyTableSettings(id: string, grid: GridComponent): void {
    this.gridIdMap.clear();
    this.gridIdMap.set(id, grid);
    const localSettings = this.getLocalSettings(id);

    // Sets initial `orderIndex` for each column, depending on its position in the grid template,
    // and set `orderIndex` for mobile column as the last one to avoid changes in `orderIndex` for other columns
    this.setInitialColumnOrderIndex(grid);

    const columns = grid.columns.map(col => (col as ColumnComponent).field).filter(Boolean);
    const stickyColumns = grid.columns.filter(col => isColumnSticky(col) || !isColumnReorderable(col));

    const stickyColumnsFieldOrderIndex: Record<string, number> = stickyColumns.reduce(
      (obj, col) => ({ ...obj, [(col as ColumnComponent).field]: col.orderIndex }),
      {}
    );

    const tableSettings$ =
      this.columnChooserPersistenceService?.getTableSettings(id, columns, stickyColumnsFieldOrderIndex) ?? of([]);

    tableSettings$.pipe(take(1)).subscribe(settings => {
      const crossBrowserSettings = JSON.stringify(settings) !== JSON.stringify(localSettings);
      settings =
        settings?.length !== 0 && crossBrowserSettings ? this.handleTableSettings(settings!, id) : localSettings;
      console.log(
        `Apply user preferences for ${id}: `,
        settings?.sort((a, b) => a!.orderIndex - b!.orderIndex)
      );

      // Filtering out possible null columns from user preferences
      const filteredSettings = settings.filter(v => v !== null) as ColumnChooserTableSettings[];
      filteredSettings.forEach(setting => {
        const column = grid.columns.find(col => (col as ColumnComponent).field === setting!.field);
        if (column) {
          column.hidden = setting!.hidden;
          if (!isColumnSticky(column) && isColumnReorderable(column)) {
            column.orderIndex = setting!.orderIndex;
          }
          if ((column as unknown as { optional: boolean }).optional) {
            column.includeInChooser = !!setting!.includeInChooser;
          }
        }
      });

      // Sets order to the columns that do not have saved settings
      this.handleUnsetColumnsOrder(filteredSettings, grid.columns);

      this.applyChangesSubj.next();

      // Checks if user settings contains outdated settings and mark it to delete on the next settings saving
      this.columnChooserPersistenceService?.markUnusedSettings(id, columns);
    });
  }

  updateTableSettings(id: string, columns: ColumnChooserTableSettings[], reset: boolean = false) {
    const settings = columns
      .filter(col => !!col.field)
      .map(col => {
        const setting: ColumnChooserTableSettings = {
          field: col.field,
          hidden: col.hidden,
          orderIndex: col.orderIndex
        };
        if (col.optional) {
          setting.optional = col.optional;
          setting.includeInChooser = col.includeInChooser;
        }
        return setting;
      });

    if (reset) console.log(`Delete user preferences for ${id}`);
    else if (settings.length) console.log(`Save user preferences for ${id}: `, settings);

    return of(reset ? this.getLocalSettings(id) : settings).pipe(
      tap(settings => {
        if (reset) {
          localStorage.removeItem(id);
          return;
        }
        const localSettings = this.getLocalSettings(id);
        settings.forEach(setting => {
          const matchIndex = localSettings.findIndex(s => setting.field === s.field);
          const match = localSettings[matchIndex];
          if (match) {
            if (match.optional && !setting.includeInChooser) {
              localSettings.splice(matchIndex, 1);
            } else {
              match.hidden = setting.hidden;
              match.orderIndex = setting.orderIndex;
              match.includeInChooser = setting.includeInChooser;
            }
          } else {
            localSettings.push(setting);
          }
        });
        localStorage.setItem(id, JSON.stringify(localSettings));
      }),
      switchMap(settings => {
        if (settings.length && this.columnChooserPersistenceService) {
          return reset
            ? this.columnChooserPersistenceService.resetTableSettings(id)
            : this.columnChooserPersistenceService.updateTableSettings(id, settings);
        } else {
          return of(true);
        }
      })
    );
  }

  private setInitialColumnOrderIndex(grid: GridComponent): void {
    let isMobileGrid = false;
    grid.columns.forEach((col, index, columns) => {
      if (!isMobileGrid && col.media === tableMedia.mediaMaxWidthForMobile) {
        isMobileGrid = true;
        col.orderIndex = columns.length - 1;
      } else {
        col.orderIndex = isMobileGrid ? index - 1 : index;
      }
    });
  }

  private handleUnsetColumnsOrder(settings: ColumnChooserTableSettings[], columns: QueryList<ColumnBase>): void {
    const { columnsWithSameOrderIndexArray, unsetOrderIndexes } =
      this.getColumnsWithSameOrderIndexesAndUnsetOrderIndexes(columns);

    if (!columnsWithSameOrderIndexArray.length) return;

    let arrayIndex = 0;
    columnsWithSameOrderIndexArray.forEach(columnsWithSameOrderIndex => {
      columnsWithSameOrderIndex.forEach(column => {
        if (
          settings.some(setting => (column as ColumnComponent).field === setting.field) ||
          isColumnSticky(column) ||
          !isColumnReorderable(column)
        )
          return;
        column.orderIndex = unsetOrderIndexes[arrayIndex];
        arrayIndex++;
      });
    });
  }

  private getColumnsWithSameOrderIndexesAndUnsetOrderIndexes(columns: QueryList<ColumnBase>): {
    columnsWithSameOrderIndexArray: ColumnBase[][];
    unsetOrderIndexes: number[];
  } {
    const { itemsByOrderIndex, orderIndexArray } = this.getItemsByOrderIndexAndOrderIndexArray(columns);
    const columnsWithSameOrderIndexArray = Object.values(itemsByOrderIndex).filter(columns => columns.length > 1);
    const orderIndexSet = new Set(orderIndexArray);
    const unsetOrderIndexes = Array.from({ length: columns.length }, (_, i) => i + 1).filter(
      i => !orderIndexSet.has(i)
    );

    return { columnsWithSameOrderIndexArray, unsetOrderIndexes };
  }

  private getItemsByOrderIndexAndOrderIndexArray(columns: QueryList<ColumnBase>): {
    itemsByOrderIndex: Record<number, ColumnBase[]>;
    orderIndexArray: number[];
  } {
    const itemsByOrderIndex: Record<number, ColumnBase[]> = {};
    const orderIndexArray: number[] = [];

    columns.forEach(column => {
      const { orderIndex } = column;
      orderIndexArray.push(orderIndex);

      if (itemsByOrderIndex[orderIndex]) {
        itemsByOrderIndex[orderIndex].push(column);
      } else {
        itemsByOrderIndex[orderIndex] = [column];
      }
    });

    return { itemsByOrderIndex, orderIndexArray };
  }

  private getInitialStateId(id: string) {
    return `${initialStatePrefix}-${id}`;
  }

  private getLocalSettings(id: string) {
    const localConfig = localStorage.getItem(id);
    return localConfig ? (JSON.parse(localConfig) as ColumnChooserTableSettings[]) : [];
  }

  private handleTableSettings(
    settings: Array<ColumnChooserTableSettings | null>,
    id: string
  ): Array<ColumnChooserTableSettings | null> {
    localStorage.removeItem(id);

    if (settings === null) {
      settings = [];
    } else if (settings.length) {
      localStorage.setItem(id, JSON.stringify(settings));
    }

    return settings;
  }
}
