import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { FireflyKendoIconsModule } from '../kendo-icons/kendo-icons.module';
import { EnterToBlurDirective } from './directives/enter-to-blur.directive';
import { FireflyExtendedFileSelectTemplateDirective } from './directives/extended-file-select-template.directive';
import { FireflyFileSelectComponent } from './file-select/file-select.component';
import { FireflyFileSelectWithRenameComponent } from './file-select-with-rename/file-select-with-rename.component';
import { FireflyFileUploaderComponent } from './file-uploader/file-uploader.component';

@NgModule({
  imports: [CommonModule, UploadsModule, FireflyKendoIconsModule.forRoot()],
  declarations: [
    FireflyFileSelectComponent,
    FireflyFileUploaderComponent,
    FireflyFileSelectWithRenameComponent,
    FireflyExtendedFileSelectTemplateDirective,
    EnterToBlurDirective
  ],
  exports: [FireflyFileSelectComponent, FireflyFileUploaderComponent, FireflyFileSelectWithRenameComponent]
})
export class FireflyUploadsModule {}
