import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from './models';

@Component({
  selector: 'f-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyModalComponent implements OnInit, AfterViewInit {
  context!: ModalOptions;

  @ViewChild('body', { static: true, read: ElementRef }) modalBody!: ElementRef;
  @ViewChild('dynamicContent', { static: true, read: ViewContainerRef })
  dynamicContent!: ViewContainerRef;

  constructor(
    public modal: NgbActiveModal,
    protected cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngAfterViewInit(): void {
    if (this.context.modalComponentClass) {
      this.renderer.addClass(this.el.nativeElement, this.context.modalComponentClass);
    }
  }

  confirmClick() {
    this.modal.close();
  }

  loadComponent() {
    const component = this.dynamicContent.createComponent(this.context.component);
    component.instance.context = this.context.context;
    component.instance.modalInstance = this.modal;
  }

  get contentClass() {
    return this.context.hideDefaultFooter ? 'custom-modal-body' : 'modal-body';
  }
}
