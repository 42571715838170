import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, OperatorFunction, pipe, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AssetType, Security } from '../models/security';
import { SECURITIES_BY_IDS_QUERY, SECURITY_SEARCH_QUERY } from './security-search.queries';

interface Response<T> {
  data?: T;
  errors?: unknown;
}

@Injectable()
export class SecuritiesRepository {
  private readonly searchUrl: string = '/api/onesearch/graphQl';

  constructor(private http: HttpClient) {}

  searchSecurities(request: { searchText: string; take: number; skip: number; assetTypes?: AssetType[] }) {
    return this.http
      .post<Response<{ securities: Security[] }>>(this.searchUrl, {
        query: SECURITY_SEARCH_QUERY,
        variables: request
      })
      .pipe(this.unwrapResponse(result => this.mapSecurityResponse(result.securities)));
  }

  getByIds(ids: number[]) {
    return this.http
      .post<Response<{ securitiesByIds: Security[] }>>(this.searchUrl, {
        query: SECURITIES_BY_IDS_QUERY,
        variables: { ids }
      })
      .pipe(this.unwrapResponse(result => this.mapSecurityResponse(result.securitiesByIds)));
  }

  private unwrapResponse<T, TOut>(mapFn: (result: T) => TOut): OperatorFunction<Response<T>, TOut> {
    return pipe(
      switchMap((response: Response<T>) => {
        if (response.data) {
          return of(mapFn(response.data));
        }
        return throwError(() => response.errors);
      })
    );
  }

  private mapSecurityResponse(securitiesResponse: Security[]): Security[] {
    return securitiesResponse.map(security => ({
      id: security.id,
      ticker: security.ticker || '',
      name: security.name || '',
      stockExchange: security.stockExchange || '',
      macroIndustryId: security.macroIndustryId,
      macroIndustryName: security.macroIndustryName,
      reportType: security.reportType,
      snlCompanyId: security.snlCompanyId,
      conversion: security.conversion,
      issuerId: security.issuerId,
      type: security.type
    }));
  }
}
