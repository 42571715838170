import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavConfig, NavConfigFn, NavigationConfigProvider } from '@capital-access/common/navigation';
import { BadgeType } from '@capital-access/common/navigation';
import { isTablet } from '@capital-access/emails/common';
import { EMAILS_FEATURE_TOGGLE, EMAILS_PATH } from './constants';

@Injectable()
export class EmailsNavConfigProvider implements NavigationConfigProvider {
  constructor(private store: Store) {}
  isTablet = isTablet();

  resolveNavConfig: NavConfigFn = () => {
    return this.store.select(getFeatureToggle(EMAILS_FEATURE_TOGGLE)).pipe(
      map(isFeatureToggleEnabled => {
        return <NavConfig>{
          top: [
            {
              localizationKey: 'mail',
              icon: 'f-i-email',
              order: 500,
              link: EMAILS_PATH,
              enabled: isFeatureToggleEnabled && !this.isTablet,
              badgeType: BadgeType.BadgeBeta
            }
          ]
        };
      })
    );
  };
}
