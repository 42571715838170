import { ComponentRef, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoadingType } from '../../loading-indicators';
import { FireflyDrawerComponent } from '../drawer.component';
import { DrawerOutput } from './drawer-config.model';
import { FireflyDrawerContent } from './drawer-content.model';

export class DrawerRef {
  private result$ = new Subject<DrawerOutput>();
  private output?: DrawerOutput;

  constructor(
    private drawerComponent: ComponentRef<FireflyDrawerComponent>,
    private drawerContent: ComponentRef<FireflyDrawerContent<unknown>>
  ) {
    this.drawerContent.instance.drawerInstanceRef = this;

    this.drawerComponent.onDestroy(() => {
      if (this.drawerComponent.instance.addBackButton) return;
      this.result$.next(this.output);
      this.result$.complete();
    });
  }

  close(output: DrawerOutput): void {
    this.output = output;
    this.drawerComponent.instance.confirmAndClose(this.drawerComponent.instance.addBackButton);
  }

  hide(): void {
    this.drawerComponent.instance.toggleHiddenState(true);
  }

  show(): void {
    this.drawerComponent.instance.toggleHiddenState(false);
  }

  back(): void {
    if (this.drawerComponent.instance.addBackButton) {
      this.drawerComponent.instance.confirmAndClose(true);
    }
  }

  dismiss(): void {
    this.drawerComponent.instance.confirmAndClose();
  }

  setDrawerLoadingState(type?: LoadingType): void {
    this.drawerComponent.instance.setDrawerLoadingState(type);
  }

  setConfirmationModalTrigger(showModal: boolean): void {
    this.drawerComponent.instance.setConfirmationModalTrigger(showModal);
  }

  openLocalizedConfirmationModal(): Observable<boolean> {
    return this.drawerComponent.instance.openLocalizedConfirmationModal();
  }

  setModified(hasUnsavedChanges: boolean): void {
    this.drawerComponent.instance.setUnsavedFlag(hasUnsavedChanges);
  }

  changeDrawerTitle(title: string): void {
    this.drawerComponent.instance.changeDrawerTitle(title);
  }

  changeDrawerTitleIconClass(icon: string): void {
    this.drawerComponent.instance.changeDrawerTitleIconClass(icon);
  }

  changeDrawerTitleIconTooltip(title: string): void {
    this.drawerComponent.instance.changeDrawerTitleIconTooltip(title);
  }

  changeDrawerBadgeText(title: string): void {
    this.drawerComponent.instance.changeDrawerBadgeText(title);
  }

  changeDrawerBadgeClass(badgeClass: string): void {
    this.drawerComponent.instance.changeDrawerBadgeClass(badgeClass);
  }

  onClose(): Observable<DrawerOutput> {
    return this.result$.asObservable();
  }

  onOpenPreviousDrawer(): Observable<void> {
    return this.drawerComponent.instance.openPreviousDrawer$.asObservable();
  }

  visibilityChange(): Observable<boolean> {
    return this.drawerComponent.instance.visibilityChange$.asObservable();
  }

  modalLeaveTriggered(): Observable<boolean> {
    return this.drawerComponent.instance.modalLeaveTriggered$.asObservable();
  }

  controlButtonClick(): Observable<Event> {
    return this.drawerComponent.instance.controlButtonClick$.asObservable();
  }

  closeButtonClick(): Observable<void> {
    return this.drawerComponent.instance.closeButtonClick$.asObservable();
  }

  setControlButtonDisabled(disabled: boolean) {
    this.drawerComponent.instance.setControlButtonDisabled(disabled);
  }

  setControlButtonsTemplate(controlButtonsTemplateRef: TemplateRef<unknown>) {
    this.drawerComponent.instance.setControlButtonsTemplate(controlButtonsTemplateRef);
  }

  setActionButtonTemplate(actionButtonTemplateRef: TemplateRef<unknown>) {
    this.drawerComponent.instance.setActionButtonTemplate(actionButtonTemplateRef);
  }
}
