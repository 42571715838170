<ng-container [caLocalizationScope]="localizationScope">
  <div class="heading-20 mb-2">{{ 'dataPoints' | caLocalize }}</div>
  <p class="mb-2">{{ 'itineraryReportDescription' | caLocalize }}</p>
  <form [formGroup]="form">
    <ca-itinerary-group-sections-selector
      formControlName="sections"
      (orderChange)="settingsChange($event)"
      (selectionChange)="selectionChange($event)"
    >
    </ca-itinerary-group-sections-selector>
    <ca-set-as-default-toggle
      [control]="setAsDefaultControl"
      [toggleLabel]="'itineraryToggleLabel'"
    ></ca-set-as-default-toggle>
  </form>
  <ng-template fDrawerFooterTemplate>
    <f-inline-alert *ngIf="totalLimitReached" [type]="alertType" rootCssClass="mt-1 mb-3 d-sm-none">
      <div>
        {{ 'dataPointSelectionDisabledTooltip' | caLocalize }}
      </div>
    </f-inline-alert>
    <ca-modify-report-drawer-footer
      [disableSaving]="disableSaving"
      [createReportTitle]="'createItineraryReport'"
      (closeDrawer)="close()"
      (createReport)="createReport()"
    >
    </ca-modify-report-drawer-footer>
  </ng-template>
</ng-container>
