const SECURITY_FRAGMENT = `
id
name
stockExchange
ticker
macroIndustryId
macroIndustryName
reportType
snlCompanyId
`;

export const SECURITY_SEARCH_QUERY = `
query($searchText: String, $skip: Int, $take: Int, $assetTypes: [AssetType]){
  securities(filter: $searchText, skip: $skip, take: $take, assetTypes: $assetTypes){
    ${SECURITY_FRAGMENT}
  }
}
`;

export const SECURITIES_BY_IDS_QUERY = `
query($ids: [Int]){
  securitiesByIds(ids: $ids){
    ${SECURITY_FRAGMENT}
    ${`
      type { code, name }
      conversion { underlyingSecurityId, rate }
      issuerId
    `}
  }
}
`;
