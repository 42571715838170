import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { ClickOutsideModule } from 'ng-click-outside';
import { CommonFeatureToggleModule } from '@capital-access/common/feature-toggle';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { FireflyBetaBadgeComponent, FireflyTooltipModule } from '@capital-access/firefly/components';
import { LogoModule } from '../logo/logo.module';
import { ActionNavItemComponent } from './components/action-nav-item/action-nav-item.component';
import { BottomNavItemComponent } from './components/bottom-nav-item/bottom-nav-item.component';
import { LinkNavItemComponent } from './components/link-nav-item/link-nav-item.component';
import { NavItemIconComponent } from './components/nav-item-icon/nav-item-icon.component';
import { ToggleNavItemComponent } from './components/toggle-nav-item/toggle-nav-item.component';
import { IsActiveRoutePipe } from './pipes/is-active-route.pipe';
import { SideNavItemHighlightService } from './services/side-nav-item-highlight.service';
import { SideNavComponent } from './side-nav.component';

@NgModule({
  imports: [
    CommonModule,
    CommonRouterModule,
    CommonNavigationModule,
    LetDirective,
    ClickOutsideModule,
    CommonLocalizationModule,
    FireflyTooltipModule,
    CommonFeatureToggleModule,
    LogoModule,
    FireflyBetaBadgeComponent
  ],
  declarations: [
    SideNavComponent,
    IsActiveRoutePipe,
    LinkNavItemComponent,
    ActionNavItemComponent,
    BottomNavItemComponent,
    NavItemIconComponent,
    ToggleNavItemComponent
  ],
  providers: [SideNavItemHighlightService],
  exports: [SideNavComponent]
})
export class SideNavModule {}
