import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import chunk from 'lodash/chunk';
import difference from 'lodash/difference';
import union from 'lodash/union';
import { combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getContentPermissions, getUserLoaded } from '@capital-access/common/user';
import { when } from '@capital-access/common/utils';
import { SECURITY_BATCH_SIZE } from '../constants';
import { SecuritiesRepository } from '../services/securities.repository';
import { loadSecurities, loadSecuritiesFail, loadSecuritiesSuccess } from './actions';
import { getLoadedSecurityIds } from './selectors';

@Injectable()
export class SecurityEffects {
  onContentPermissions$ = createEffect(() =>
    this.store.select(getContentPermissions).pipe(
      map(permissions => {
        const ids = permissions!.targeting.securityIds;
        return loadSecurities({ ids });
      }),
      when(combineLatest([this.store.select(getUserLoaded)]).pipe(map(([userLoaded]) => userLoaded)))
    )
  );

  loadSecurities$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadSecurities),
      withLatestFrom(this.store.select(getLoadedSecurityIds)),
      map(([{ ids }, loadedIds]) => {
        return { ids: ids.filter(id => !loadedIds.includes(id)) };
      }),
      filter(result => result.ids && result.ids.length > 0),
      mergeMap(result => {
        const batches = chunk(result.ids, SECURITY_BATCH_SIZE);

        return forkJoin(batches.map(batch => this.repository.getByIds(batch))).pipe(
          mergeMap(batchedSecurities => {
            const resultActions = [];
            const securities = union(...batchedSecurities);
            const missingIds = difference(
              result.ids,
              securities.map(s => s.id)
            );

            if (securities.length > 0) {
              //result is not empty
              resultActions.push(loadSecuritiesSuccess({ securities }));
            }

            if (missingIds.length > 0) {
              // some ids are missing in the result
              resultActions.push(
                loadSecuritiesFail({
                  failedIds: missingIds,
                  error: new Error(`ids not found: ${missingIds}`)
                })
              );
            }

            return resultActions;
          }),
          catchError(error => of(loadSecuritiesFail({ failedIds: result.ids, error })))
        );
      })
    )
  );

  constructor(private actions: Actions, private store: Store, private repository: SecuritiesRepository) {}
}
