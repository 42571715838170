import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Renderer2,
  SkipSelf
} from '@angular/core';
import { ColumnComponent, ColumnVisibilityChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, filter, startWith, take, takeUntil } from 'rxjs/operators';
import { DrawerPosition, DrawerRef, DrawerType, FireflyDrawerService } from '../drawer';
import { FireflyLocalizationService } from '../utils';
import { BaseColumnSettings, ColumnChooserTableSettings } from './column-chooser.models';
import { FireflyColumnChooserService } from './column-chooser.service';
import { mapColumnsToMinimalColumnInfo } from './column-chooser.utils';
import { FireflyColumnChooserDrawerComponent } from './column-chooser-drawer.component';

@Component({
  selector: 'f-column-chooser',
  templateUrl: './column-chooser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyColumnChooserComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() maxSelectedOptionalColumnsCount = 20;
  @Input() set gridId(id: string) {
    if (!id) return;

    this.currentGridId = id;
    this.interactedGridIds.push(id);
  }
  @Input() set grid(component: GridComponent | undefined) {
    if (!component) return;
    this.unsubscribeAll();
    this.gridComponent = component;

    if (this.gridComponent) {
      requestAnimationFrame(() => {
        this.initialGridColumns = this.getInitialState();

        this.gridDataChangeSub = this.gridComponent.columns.changes
          .pipe(startWith([this.gridComponent.columns]))
          .subscribe(() => {
            this.columnChooserService.applyTableSettings(this.currentGridId, this.gridComponent);
            this.cdr.detectChanges();
          });

        // `columnReorder` events are filtered to be sure that the event is not prevented by any of its subscribers
        // see `handleReordering` method in `FireflySharedGridDirective`
        this.columnReorderSub = this.gridComponent.columnReorder
          .pipe(filter(event => !event.isDefaultPrevented()))
          .subscribe(() => {
            const oldOrderCols = [
              ...this.gridComponent.columns.map(col => ({
                orderIndex: col.orderIndex,
                field: (col as ColumnComponent).field
              }))
            ];

            requestAnimationFrame(() => {
              const diff = this.getColumnsDiff(oldOrderCols);
              this.settingsUpdateSub = this.columnChooserService
                .updateTableSettings(this.currentGridId, diff)
                .pipe(
                  take(1),
                  catchError(() => EMPTY)
                )
                .subscribe();
            });
          });
      });

      this.contentScrollSub = this.gridComponent.contentScroll.subscribe(() => {
        this.resetTableSize();
      });
    }
  }

  currentGridId = '';
  interactedGridIds: string[] = [];
  gridComponent!: GridComponent;
  initialGridColumns: BaseColumnSettings[] = [];
  drawerRef!: DrawerRef | null;
  destroyed$ = new Subject<void>();
  columnsSettingsTitle = 'Columns Settings';
  modalDiscardChangesTitle = 'Discard Changes?';
  modalCancelColumnChooserTitle = 'Keep Selecting';
  private columnReorderSub = new Subscription();
  private settingsUpdateSub = new Subscription();
  private contentScrollSub = new Subscription();
  private gridDataChangeSub = new Subscription();

  constructor(
    private injector: Injector,
    private renderer: Renderer2,
    @SkipSelf() private cdr: ChangeDetectorRef,
    private drawerService: FireflyDrawerService,
    private columnChooserService: FireflyColumnChooserService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    this.cleanSavedInitialStates();
  }

  ngOnInit() {
    this.localizationService
      ?.localize(['columnsChooserTitle', 'modalDiscardChangesTitle', 'modalCancelColumnChooserTitle'], {})
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([columnChooserTitle, modalDiscardChangesTitle, modalCancelColumnChooserTitle]) => {
        this.columnsSettingsTitle = columnChooserTitle;
        this.modalDiscardChangesTitle = modalDiscardChangesTitle;
        this.modalCancelColumnChooserTitle = modalCancelColumnChooserTitle;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.cleanSavedInitialStates();
    this.unsubscribeAll();
  }

  openColumnChooser() {
    if (this.drawerRef) return;

    this.drawerRef = this.drawerService.openDrawer(
      {
        title: this.columnsSettingsTitle,
        position: DrawerPosition.Right,
        injector: this.injector,
        type: DrawerType.Form,
        modalLocalizedInfo: {
          modalLeaveFormTitle: this.modalDiscardChangesTitle,
          modalCancelTitle: this.modalCancelColumnChooserTitle
        }
      },
      FireflyColumnChooserDrawerComponent,
      {
        gridId: this.currentGridId,
        grid: this.gridComponent,
        initialGridColumns: this.initialGridColumns,
        maxSelectedOptionalColumnsCount: this.maxSelectedOptionalColumnsCount
      }
    );

    this.drawerRef
      .onClose()
      .pipe(takeUntil(this.destroyed$))
      .subscribe($event => {
        this.drawerRef = null;
        if (($event as { applyChanges: boolean })?.applyChanges) {
          this.columnChooserService.applyChangesSubj.next();
          this.gridComponent.columnVisibilityChange.next(
            new ColumnVisibilityChangeEvent(this.gridComponent.columns.toArray())
          );
          this.gridComponent.columnResize.next([]);
          this.cdr.detectChanges();
        }
      });
  }

  private resetTableSize() {
    if (!this.gridComponent.stickyColumns.length) return;
    const tableElements = this.gridComponent.wrapper.nativeElement.querySelectorAll('table');
    tableElements.forEach((table: HTMLTableElement) => {
      this.renderer.removeStyle(table, 'width');
    });
  }

  private cleanSavedInitialStates() {
    this.interactedGridIds.forEach(id => this.columnChooserService.removeInitialStateMinimalInfo(id));
  }

  private unsubscribeAll() {
    this.columnReorderSub.unsubscribe();
    this.settingsUpdateSub.unsubscribe();
    this.contentScrollSub.unsubscribe();
    this.gridDataChangeSub.unsubscribe();
  }

  //On same pages with tabs grid can be hidden instead of being destroyed.
  //To handle functionality `Reset to Default` correctly initial state is saving to local storage.
  private getInitialState(): BaseColumnSettings[] {
    const initialStateFromStorage = this.columnChooserService.getInitialStateMinimalInfo(this.currentGridId);
    if (initialStateFromStorage.length > 0) {
      return initialStateFromStorage;
    }

    const initialStateFromComponent = mapColumnsToMinimalColumnInfo(
      (this.gridComponent.columns as QueryList<ColumnComponent>).map(column => {
        const clone = new ColumnComponent(column.parent);
        return Object.assign(clone, column);
      })
    );
    this.columnChooserService.setInitialStateMinimalInfo(this.currentGridId, initialStateFromComponent);
    return initialStateFromComponent;
  }

  private getColumnsDiff(columns: { field: string; orderIndex: number }[]) {
    const diff: ColumnChooserTableSettings[] = [];
    columns.forEach(col => {
      const match = this.gridComponent.columns.find(
        c => (c as ColumnComponent).field === (col as ColumnComponent).field
      );
      if (match && match.orderIndex !== col.orderIndex) diff.push(match as unknown as ColumnChooserTableSettings);
    });
    return diff;
  }
}
