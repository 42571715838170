import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyAutoScrollDirective } from '../auto-scroll/autoscroll.directive';
import { FireflyDropdownModule } from '../dropdowns/dropdown.module';
import { FireflyListModule } from '../lists/list.module';
import { FireflySkeletonDrawerLoaderModule } from '../loading-indicators/skeleton-loaders/drawer-templates/skeleton-drawer-loader.module';
import { FireflySpinnerModule } from '../loading-indicators/spinner/spinner.module';
import { FireflyMultilineTextTruncateModule } from '../multiline-text-truncate';
import { FireflyTooltipModule } from '../tooltips';
import { FireflyDrawerDefaultContainerDirective } from './directives/drawer-default-container.directive';
import {
  FireflyDrawerActionButtonDirective,
  FireflyDrawerColumnDirective,
  FireflyDrawerControlButtonsTemplateDirective,
  FireflyDrawerFooterTemplateDirective,
  FireflyDrawerRowDirective,
  FireflyDrawerScrollableDirective
} from './directives/drawer-template.directives';
import { FireflyDrawerComponent } from './drawer.component';

const components = [
  FireflyDrawerComponent,
  FireflyDrawerDefaultContainerDirective,
  FireflyDrawerFooterTemplateDirective,
  FireflyDrawerControlButtonsTemplateDirective,
  FireflyDrawerActionButtonDirective,
  FireflyDrawerScrollableDirective,
  FireflyDrawerColumnDirective,
  FireflyDrawerRowDirective
];

@NgModule({
  imports: [
    CommonModule,
    FireflyDropdownModule,
    FireflyListModule,
    FireflySpinnerModule,
    FireflySkeletonDrawerLoaderModule,
    FireflyMultilineTextTruncateModule,
    FireflyTooltipModule,
    FireflyAutoScrollDirective
  ],
  declarations: [...components],
  exports: [...components]
})
export class FireflyDrawerModule {}
