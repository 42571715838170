<!-- TODO: add the possibility to choose the type of toolbar -->
<kendo-editor
  *ngIf="localizationIsReady"
  [pasteCleanupSettings]="pasteCleanupSettings"
  [iframe]="false"
  [placeholder]="placeholder || editorPlaceholder"
  [value]="initNotes"
  (valueChange)="onValueChange($event)"
  class="w-100 h-100"
  [class.k-editor-validation]="!isCharacterLimitValid || !valid"
>
  <kendo-toolbar
    [overflow]="toolbarOverflow"
    [style.width.%]="toolbarWidth"
    [popupSettings]="{ appendTo: 'component' }"
  >
    <kendo-toolbar-button
      *ngIf="enableResizing"
      [className]="'editor-resize-button'"
      [svgIcon]="resizeIcon"
      [title]="resizeTitle"
      (click)="onResizeNotes()"
    ></kendo-toolbar-button>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button [className]="'editor-bold-button'" kendoEditorBoldButton></kendo-toolbar-button>
      <kendo-toolbar-button [className]="'editor-italic-button'" kendoEditorItalicButton></kendo-toolbar-button>
      <kendo-toolbar-button [className]="'editor-underline-button'" kendoEditorUnderlineButton></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
    <kendo-toolbar-buttongroup>
      <kendo-toolbar-button
        [className]="'editor-insert-unordered-list'"
        kendoEditorInsertUnorderedListButton
      ></kendo-toolbar-button>
      <kendo-toolbar-button
        [className]="'editor-insert-ordered-list'"
        kendoEditorInsertOrderedListButton
      ></kendo-toolbar-button>
    </kendo-toolbar-buttongroup>
    <kendo-toolbar-button
      [className]="'editor-clean-formatting-button'"
      kendoEditorCleanFormattingButton
    ></kendo-toolbar-button>
  </kendo-toolbar>
  <kendo-editor-messages
    [fontSize]="editorFontSize"
    [bold]="editorBold"
    [italic]="editorItalic"
    [underline]="editorUnderline"
    [foreColor]="editorColor"
    [insertUnorderedList]="editorUnorderedList"
    [insertOrderedList]="editorOrderedList"
    [cleanFormatting]="editorCleanFormatting"
  ></kendo-editor-messages>
</kendo-editor>
<div class="body-50">
  <p *ngIf="!isCharacterLimitValid" class="k-editor-validation-message">
    {{ editorCharactersLimit }}
  </p>
  <div *ngIf="showAutoSaveSuccessMessage" data-automation-id="auto-save-success-message" class="text-muted pb-7">
    {{ draftSuccessMessage }}
  </div>
  <div
    *ngIf="showAutoSaveErrorMessage"
    data-automation-id="auto-save-failure-message"
    class="text-danger auto-save-error-message pb-7"
  >
    {{ draftFailureMessage }}
  </div>
</div>
