import { Directive, inject, Input } from '@angular/core';
import { CellOptions } from '@progress/kendo-angular-excel-export';
import { ColumnComponent } from '@progress/kendo-angular-grid';
import isArray from 'lodash/isArray';
import isSet from 'lodash/isSet';
import { ExcelAlign, ExcelColumn, isString, KendoExcelColumn } from '@capital-access/common/utils';

@Directive({
  selector: '[caExportableColumn]',
  standalone: true
})
export class ExportableColumnDirective {
  #column?: ColumnComponent | null;

  @Input() exportTitle?: string;
  @Input() exportField?: string;
  @Input() exportFormat?: string;
  @Input() exportSubFormat?: string;
  @Input() exportOrderIndex?: number;
  @Input() exportAlignment?: ExcelAlign;
  @Input() exportHidden?: boolean;
  @Input() exportWidth?: number;
  /**
   * @deprecated use ExportableColumnComponent instead
   */
  @Input() set bindingColumn(col: ColumnComponent | null | undefined) {
    this.bindToColumn(col);
  }

  constructor() {
    this.bindToColumn(inject(ColumnComponent, { optional: true, host: true }));
  }

  get hidden(): boolean {
    return this.exportHidden ?? this.#column?.hidden ?? false;
  }

  get title(): string {
    return this.exportTitle || (this.#column?.title ?? '');
  }

  get field(): string {
    return this.exportField || (this.#column?.field ?? '');
  }

  get orderIndex(): number {
    return this.exportOrderIndex ?? this.#column?.orderIndex ?? Number.MAX_VALUE;
  }

  get format(): string | undefined {
    const itemFormat = this.exportFormat || this.#column?.format;
    const subFormat = this.exportSubFormat || '';
    return subFormat.length > 0 ? `${itemFormat} ${subFormat}` : itemFormat;
  }

  get align(): ExcelAlign | undefined {
    return this.exportAlignment || this.#getColumnAlignment();
  }

  get kendoAlign() {
    switch (this.align) {
      case ExcelAlign.Left:
        return 'left';
      case ExcelAlign.Right:
        return 'right';
      case ExcelAlign.Center:
        return 'center';
      default:
        return 'left';
    }
  }
  get width() {
    return this.exportWidth || this.#column?.width || 0;
  }

  toExcelColumn(): ExcelColumn {
    return {
      title: this.title,
      field: this.field,
      align: this.align,
      format: this.format
    };
  }

  toKendoExcelColumn(): KendoExcelColumn {
    return {
      title: this.title,
      field: this.field,
      width: this.width,
      cellOptions: { format: this.exportFormat, textAlign: this.kendoAlign } as CellOptions
    };
  }

  bindToColumn(column: ColumnComponent | null | undefined) {
    this.#column = column;
  }

  #defaultAlignment = ExcelAlign.Left;

  #getColumnAlignment(): ExcelAlign {
    const cssClass = this.#column?.cssClass;
    if (!cssClass) {
      return this.#defaultAlignment;
    }
    if (isArray(cssClass)) {
      return this.#mapAlignFromClass(cls => cssClass.includes(cls));
    }
    if (isSet(cssClass)) {
      return this.#mapAlignFromClass(cls => cssClass.has(cls));
    }
    return this.#mapAlignFromClass(isString(cssClass) ? cls => cssClass.includes(cls) : cls => cssClass[cls]);
  }

  #mapAlignFromClass(testFn: (cssClass: string) => boolean): ExcelAlign {
    if (testFn('text-right')) {
      return ExcelAlign.Right;
    }
    if (testFn('text-center')) {
      return ExcelAlign.Center;
    }
    return this.#defaultAlignment;
  }
}
