import { createAction, props } from '@ngrx/store';

export const addContact = createAction(
  '[Common Crm] Add Contact',
  (payload?: {
    email?: string;
    institution?: { name: string; crmId?: number | null; prospectingId?: number | null };
    addBackButton?: boolean;
  }) => payload || {}
);

export const editContact = createAction(
  '[Common Crm] Edit Contact',
  props<{ id: { prId?: number | null; crmId?: number | null }; addBackButton?: boolean }>()
);

export const addInstitution = createAction('[Common Crm] Add Institution');

export const editInstitution = createAction(
  '[Common Crm] Edit Institution',
  props<{ id: { prId?: number | null; crmId?: number | null } }>()
);

export const addContactSuccess = createAction(
  '[Common Crm] Add Contact Success',
  props<{ id: { prId?: number | null; crmId?: number | null } }>()
);

export const editContactSuccess = createAction(
  '[Common Crm] Edit Contact Success',
  props<{ id: { prId?: number | null; crmId?: number | null } }>()
);

export const editInstitutionSuccess = createAction(
  '[Common Crm] Edit Institution Success',
  props<{ id: { prId?: number | null; crmId?: number | null } }>()
);

export const addInstitutionSuccess = createAction(
  '[Common Crm] Add Institution Success',
  props<{ id: { prId?: number | null; crmId?: number | null } }>()
);

export const deleteContactSuccess = createAction(
  '[Common Crm] Delete Contact Success',
  props<{ id: { prId?: number | null; crmId?: number | null } }>()
);
