<f-file-select
  [multiple]="multiple()"
  [maxTotalSize]="maxTotalSize()"
  [maxFileSize]="maxFileSize()"
  [allowedExtensions]="allowedExtensions()"
  [invalidFiles]="invalidFiles()"
  [caseInsensitiveValidation]="caseInsensitiveValidation()"
  (filesSelected)="onFilesSelected($event)"
  (filesRemoved)="onFilesRemoved($event)"
>
  <ng-template fExtendedFileSelectTemplate let-file>
    <div class="mb-3">
      <div class="form-label-top mb-1">{{ fileNameLabel() }}<span class="text-danger">*</span></div>
      @let textToDisplayInvalid = isTextToDisplayInvalid(file.name);
      <input
        [ngClass]="{ 'is-invalid': textToDisplayInvalid }"
        class="form-control form-control-outline"
        type="text"
        [value]="file.name"
        (blur)="updateTextToDisplay($event, file.name)"
        fEnterToBlur
      />
      @if(textToDisplayInvalid){
      <div class="invalid-feedback">
        {{ nameIsRequired() }}
      </div>
      }
    </div>
  </ng-template>
</f-file-select>
